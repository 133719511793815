import React from "react";
import { useQuery } from "react-query";
import { Container, Row } from "reactstrap";
import { getServicesList } from "../../../../Api/WebsiteApi";


const Services: React.FC = () => {
  const { data: serviceList }: any = useQuery(
    "service",
    () => getServicesList(),
    { keepPreviousData: true }
  );

  return (
    <>
      {
        <section className="sevices-outer-wrap">
          <div className="reveal">
            <Container>
              <div className="heading-wrap">
                <h4 className="main-heading">Services and Facilities</h4>
              </div>
            </Container>
            {/* cards section */}
            <div className="sevices-list-outer-wrap">
              <Container>
                <Row className="align-items-center justify-content-center">
                  <div className="sevices-list-outer">
                    {serviceList?.map((serviceList: any) => (
                      <>
                        <div className="service-container">
                          <img src={serviceList?.icon} alt="service" />
                          <p>{serviceList?.title}</p>
                        </div>
                      </>
                    ))}
                  </div>
                </Row>
              </Container>
            </div>

            {/* <div className="learn-more-btn">
              <Button outline color="primary" className="sm">
                View All
                <i className="icon icon-next-green"></i>
              </Button>
            </div> */}
          </div>
        </section>
        // )
      }
    </>
  );
};

export default Services;
