import { useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Container,
  Button,
} from "reactstrap";
import ModalContainer from "../../Modal/ModalContainer";
import AlertModal from "../../AlertModal/AlertModal";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();
  const [isOpenLogout, setIsOpenLogout] = useState(false);
  const location = useLocation();
  const currentPathname = location.pathname;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpens, setDropdownOpens] = useState(false);
  const [memberdropdown, setMemberdropdown] = useState(false)
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const toggleDropdowns = () => {
    setDropdownOpens(!dropdownOpens);
  };
  const membertoggle = () => {
    setMemberdropdown(!memberdropdown)
  }

  const AUTH_TOKEN = localStorage.getItem("AUTH_TOKEN") || "";
  const ROLE = localStorage.getItem("ROLE") || "";
  const USER_ID = localStorage.getItem("USER_ID") || "";
  const portalLink = `https://admin.aoaok.co.in/?AUTH_TOKEN=${AUTH_TOKEN}&ROLE=${ROLE}&USER_ID=${USER_ID}`;



  return (
    <>
      {/* Top-Header */}
      <section className="top-header-wrap">
        <Container>
          <div className="top-header-outer">
            <Col md={6} sm={12}>
              <div className="top-header-left">
                <div>
                  <span>
                    <i className="icon icon-call xl"></i>+91 - 9447077133
                  </span>
                </div>
                <div>
                  <span>
                    <i className="icon icon-mail xl"></i>aoaokeralam@gmail.com
                  </span>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="top-header-right">
                <div>
                  <Link to="https://www.facebook.com/aoao.keralam?mibextid=ZbWKwL">
                    <i className="icon icon-facebook xl"></i>
                  </Link>
                </div>

                <div>
                  <Link to="https://youtube.com/@aoaokeralam?si=dDaMgPDdMApFFElB">
                    <i className="icon icon-youtube xl"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </div>
        </Container>
      </section>

      {/* Navbar Desktop*/}
      <header className="nav-bar desktop-navbar">
        <Container>
          <Navbar className="navbar-expand-lg sm-center">
            <NavLink to="/" className={"navbar-brand"}>
              <div className="navbar-logo-wrap">
                <div>
                  <img src="/images/site-logo.png" alt="Logo" />
                </div>
                <div>
                  <h6>
                    Association of Agricultural <br />
                    Officers Kerala
                  </h6>
                </div>
              </div>
            </NavLink>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar className="profile-collapse">
              <Nav className="ms-auto" navbar>
                <NavItem>
                  <Link
                    className={`${currentPathname === "/" ? "active nav-link" : "nav-link"
                      } `}
                    to="/"
                  >
                    Home
                  </Link>
                </NavItem>
                <NavItem className="about-us-container">
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggleDropdown}
                    className="dropdown-collapse"
                  >
                    <DropdownToggle caret>About Us</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => navigate("/about-us")}>
                        About Us
                      </DropdownItem>
                      <DropdownItem onClick={() => navigate("/history")}>
                        History
                      </DropdownItem>
                      <DropdownItem onClick={() => navigate("/bylaw")}>
                        By law
                      </DropdownItem>
                      <DropdownItem onClick={() => navigate("/gallery/1")}>
                        Gallery
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </NavItem>
                {localStorage.getItem("ROLE") === null ? null : (
                  <>
                    <NavItem className="about-us-container">
                      <Dropdown
                        isOpen={memberdropdown}
                        toggle={membertoggle}
                        className="dropdown-collapse"
                      >
                        <DropdownToggle caret>Members</DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem >
                            <Link
                              className={`${currentPathname === "/statemember/1"
                                ? "active nav-link"
                                : "nav-link"
                                } `}
                              to="/statemember/1"
                            >
                              State Office Bearers

                            </Link>
                          </DropdownItem>
                          <DropdownItem >
                            District Office Bearers
                          </DropdownItem>
                          <DropdownItem>
                            <Link
                              className={`${currentPathname === "/members/1"
                                ? "active nav-link"
                                : "nav-link"
                                } `}
                              to="/members/1"
                            >
                              Members
                            </Link>
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </NavItem>
                  </>
                )}
                {/* <NavItem>
                      <Link
                        className={`${
                          currentPathname === "/members/1"
                            ? "active nav-link"
                            : "nav-link"
                        } `}
                        to="/members/1"
                      >
                        Members 
                      </Link>
                    </NavItem> */}

                {localStorage.getItem("ROLE") === null ? null : (
                  <>
                    <NavItem>
                      <Link
                        className={`${currentPathname === "/accomodation/1"
                          ? "active nav-link"
                          : "nav-link"
                          } `}
                        to="/accomodation/1"
                      >
                        Accomodation
                      </Link>
                    </NavItem>
                  </>
                )}

                <NavItem>
                  <Link
                    className={`${currentPathname === "/offices/1"
                      ? "active nav-link"
                      : "nav-link"
                      } `}
                    to="/offices/1"
                  >
                    {localStorage.getItem("ROLE") === null
                      ? "Locate my Krishibhavan"
                      : "Offices"}
                  </Link>
                </NavItem>
                {localStorage.getItem("ROLE") === null ? null : (
                  <>
                <NavItem>
                  <Link
                    className={`${currentPathname === "/resources/1"
                      ? "active nav-link"
                      : "nav-link"
                      } `}
                    to="/resources/1"
                  >
                    Resources
                  </Link>
                </NavItem>
                </>
                )}

                <NavItem className="about-us-container">
                  <Dropdown
                    isOpen={dropdownOpens}
                    toggle={toggleDropdowns}
                    className="dropdown-collapse"
                  >
                    <DropdownToggle caret>Publications</DropdownToggle>
                    <DropdownMenu>
                      {/* <DropdownItem onClick={() => navigate("/downloads/1")}>
                        Downloads
                      </DropdownItem> */}
                      <DropdownItem onClick={() => navigate("/magazine/1")}>
                        Kannimannu
                      </DropdownItem>
                      <DropdownItem onClick={() => navigate("/blogs/1")}>
                        Blogs
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </NavItem>
                {/* {localStorage.getItem("ROLE") === null ? null : (
                  <>
                    <NavItem>
                      <Link
                        className={`${currentPathname === "/downloads"
                          ? "active nav-link"
                          : "nav-link"
                          } `}
                        to="/downloads/1"
                      >
                        Downloads
                      </Link>
                    </NavItem>
                  </>
                )} */}
                {/* <NavItem>
                  <Link
                    className={`${
                      currentPathname === "/magazine/1"
                        ? "active nav-link"
                        : "nav-link"
                    } `}
                    to="/magazine/1"
                  >
                    Magazine
                  </Link>
                </NavItem> */}
                {/* <NavItem>
                  <Link
                    className={`${
                      currentPathname === "/blogs/1"
                        ? "active nav-link"
                        : "nav-link"
                    } `}
                    to="/blogs/1"
                  >
                    Blogs
                  </Link>
                </NavItem> */}
                <NavItem>
                  <Link
                    className={`${currentPathname === "/contact-us"
                      ? "active nav-link"
                      : "nav-link"
                      } `}
                    to="/contact-us"
                  >
                    Contact Us
                  </Link>
                </NavItem>
              </Nav>

              <div className="navbar-btn">
                {localStorage.getItem("ROLE") !== null ? (
                  <Button
                    type="button"
                    color="primary"
                    className="sm"
                    onClick={() => setIsOpenLogout(true)}
                  >
                    Logout
                  </Button>
                ) : (
                  <Button
                    type="button"
                    color="primary"
                    className="sm"
                    onClick={() => navigate("/login")}
                  >
                    Login / Register
                  </Button>
                )}
                {localStorage.getItem("ROLE") !== null ? (
                  <>
                    {" "}
                    <Button outline color="primary" className="sm portal-btn">
                      <Link to={portalLink} type="button" color="primary">
                        My Portal
                      </Link>
                    </Button>
                  </>
                ) : null}
              </div>
            </Collapse>
          </Navbar>
        </Container>
      </header>

      {/* Navbar Mobile*/}
      <header className="nav-bar mobile-navbar">
        <Container>
          <Navbar className="navbar-expand-lg sm-center">
            <NavLink to="/" className={"navbar-brand"}>
              <div className="navbar-logo-wrap">
                <div>
                  <img src="/images/site-logo.png" alt="Logo" />
                </div>
                <div>
                  <h6>
                    Association of Agricultural <br />
                    Officers Kerala
                  </h6>
                </div>
              </div>
            </NavLink>
            <NavbarToggler onClick={toggle} />
            <Collapse
              isOpen={isOpen}
              onClick={toggle}
              navbar
              className="profile-collapse"
            >
              <Nav className="ms-auto" navbar>
                <NavItem>
                  <Link
                    className={`${currentPathname === "/" ? "active nav-link" : "nav-link"
                      } `}
                    to="/"
                  >
                    Home
                  </Link>
                </NavItem>
                {/* <NavItem>
                  <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="dropdown-collapse">
                    <DropdownToggle caret>About Us</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => navigate("/about-us")}>
                        About Us
                      </DropdownItem>
                      <DropdownItem onClick={() => navigate("/histoty")}>
                        History
                      </DropdownItem>
                      <DropdownItem onClick={() => navigate("/bylaw")}>
                        By law
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </NavItem> */}
                <NavItem>
                  <Link
                    className={`${currentPathname === "/about-us"
                      ? "active nav-link"
                      : "nav-link"
                      } `}
                    to="/about-us"
                  >
                    About Us
                  </Link>
                </NavItem>

                <NavItem>
                  <Link
                    className={`${currentPathname === "/history"
                      ? "active nav-link"
                      : "nav-link"
                      } `}
                    to="/history"
                  >
                    History
                  </Link>
                </NavItem>

                <NavItem>
                  <Link
                    className={`${currentPathname === "/bylaw"
                      ? "active nav-link"
                      : "nav-link"
                      } `}
                    to="/bylaw"
                  >
                    By law
                  </Link>
                </NavItem>
                {localStorage.getItem("ROLE") === null ? null : (
                  <>
                    <NavItem>
                      <Link
                        className={`${currentPathname === "/statemember/1"
                          ? "active nav-link"
                          : "nav-link"
                          } `}
                        to="/statemember/1"
                      >
                        State Office Bearers

                      </Link>
                    </NavItem>
                  </>
                )}
                {localStorage.getItem("ROLE") === null ? null : (
                  <>
                    <NavItem>
                      <Link
                        className={`${currentPathname === "/members/1"
                          ? "active nav-link"
                          : "nav-link"
                          } `}
                        to="/members/1"
                      >
                        District Office Members
                      </Link>
                    </NavItem>
                  </>
                )}
                {localStorage.getItem("ROLE") === null ? null : (
                  <>
                    <NavItem>
                      <Link
                        className={`${currentPathname === "/members/1"
                          ? "active nav-link"
                          : "nav-link"
                          } `}
                        to="/members/1"
                      >
                        Members
                      </Link>
                    </NavItem>
                  </>
                )}

                {localStorage.getItem("ROLE") === null ? null : (
                  <>
                    <NavItem>
                      <Link
                        className={`${currentPathname === "/accomodation"
                          ? "active nav-link"
                          : "nav-link"
                          } `}
                        to="/accomodation/1"
                      >
                        Accomodation
                      </Link>
                    </NavItem>
                  </>
                )}
                <NavItem>
                  <Link
                    className={`${currentPathname === "/offices/1"
                      ? "active nav-link"
                      : "nav-link"
                      } `}
                    to="/offices/1"
                  >
                    Offices
                  </Link>
                </NavItem>
                {/* {localStorage.getItem("ROLE") === null ? null : (
                  <>
                    <NavItem>
                      <Link
                        className={`${currentPathname === "/downloads"
                          ? "active nav-link"
                          : "nav-link"
                          } `}
                        to="/downloads/1"
                      >
                        Downloads
                      </Link>
                    </NavItem>
                  </>
                )} */}

                  {/* Resources */}
                  {localStorage.getItem("ROLE") === null ? null : (
                  <NavItem>
                  <Link
                    className={`${currentPathname === "/resources/1"
                      ? "active nav-link"
                      : "nav-link"
                      } `}
                    to="/resources/1"
                  >
                    Resources
                  </Link>
                </NavItem>
                  )}
                <NavItem>
                  <Link
                    className={`${currentPathname === "/magazine/1"
                      ? "active nav-link"
                      : "nav-link"
                      } `}
                    to="/magazine/1"
                  >
                    Magazine
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    className={`${currentPathname === "/blogs/1"
                      ? "active nav-link"
                      : "nav-link"
                      } `}
                    to="/blogs/1"
                  >
                    Blogs
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    className={`${currentPathname === "/contact-us"
                      ? "active nav-link"
                      : "nav-link"
                      } `}
                    to="/contact-us"
                  >
                    Contact Us
                  </Link>
                </NavItem>
              </Nav>

              <div className="navbar-btn">
                {localStorage.getItem("ROLE") !== null ? (
                  <Button
                    type="button"
                    color="primary"
                    className="sm"
                    onClick={() => setIsOpenLogout(true)}
                  >
                    Logout
                  </Button>
                ) : (
                  <Button
                    type="button"
                    color="primary"
                    className="sm"
                    onClick={() => navigate("/login")}
                  >
                    Login / Register
                  </Button>
                )}
                <br />
                {localStorage.getItem("ROLE") !== null ? (
                  <>
                    {" "}
                    <Button outline color="primary" className="sm portal-btn my-2">
                      <Link to={portalLink} type="button" color="primary">
                        My Portal
                      </Link>
                    </Button>
                  </>
                ) : null}
              </div>
            </Collapse>
          </Navbar>
        </Container>
      </header>

      <ModalContainer
        isOpen={isOpenLogout}
        setIsopen={setIsOpenLogout}
        title=""
      >
        <AlertModal setIsOpenLogout={setIsOpenLogout} />
      </ModalContainer>
    </>
  );
};

export default Header;
