import React, { useState } from "react";
import { Row, Col, Badge, Container } from "reactstrap";
import { useQuery } from "react-query";
import { getBannerFullList, getnews } from "../../../../Api/WebsiteApi";
import { CardTypesBanner } from "../../../../Type";
import Slider from "react-slick";
import { Navigate, useNavigate } from "react-router-dom";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import MemberCertificateView from "./MemberCertificate";

const Banner: React.FC = () => {
  const { data: bannerList, isLoading: bannerLoading }: any = useQuery(
    "banner",
    () => getBannerFullList(),
    { keepPreviousData: true }
  );
  const navigate = useNavigate();

  const { data: news, isLoading: newsLoading }: any = useQuery(
    // Fixed the variable name here
    "news",
    () => getnews(),
    { keepPreviousData: true }
  );
  const handleApplyClick = () => {
    setIsOpen(true);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);

  const bannerSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    cssEase: "ease-in-out",
    afterChange: (index: any) => setCurrentBannerIndex(index),
  };

  const newsSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4500,
    speed: 5500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    cssEase: "ease-in-out",
    afterChange: (index: any) => setCurrentNewsIndex(index),
  };

  const setNews = (id: any) => {
    navigate(`/news/${id}`);
  };

  return (
    <section className="home-banner">
      {/* Banner Slider */}
      <Slider {...bannerSettings}>
        {!bannerLoading &&
          bannerList?.map((banner: CardTypesBanner, index: number) => (
            <div key={index}>
              <img src={banner?.image} alt="banner-image" className="img-fluid banner-image" />
              {/* <Row
                style={{
                  background: `url(${banner?.image})`, // Static URL for the image
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: "70vh",
                  display: index === currentBannerIndex ? "block" : "none",
                }}
                className="d-flex align-items-center banner-row img-fluid"
              >
                <Col md={12}>
                  <div className="banner-text">
                    <h1>{banner?.heading}</h1>
                    <h1>{banner?.sub_heading}</h1>
                    <p className="mt-3">{banner?.content}</p>
                    <p>{banner?.sub_content}</p>
                  </div>
                </Col>
              </Row> */}
            </div>
          ))}
      </Slider>

      {/* News Slider */}
      <div className="sliding-new-outer">
        <Container>
          <Row>
            <Col md={2} className="news-column">
              <b>Latest Updates</b>
            </Col>
            <Col md={8}>
              <Slider {...newsSettings}>
                {news?.map((item: any) => (
                  <Col md={12} key={item.id}>
                    <div className="bar">
                      <span className="title" onClick={() => setNews(item?.id)}>
                        {item.title}
                      </span>
                    </div>
                  </Col>
                ))}
              </Slider>
            </Col>
            {/* <Col md={2} className="certificate-column">
              <Badge onClick={handleApplyClick}>Membership Certificate</Badge>
            </Col> */}
          </Row>
        </Container>
      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={"Member Certificate"}
      >
        <MemberCertificateView

        // district={districtList}
        />
      </ModalContainer>
    </section>
  );
};

export default Banner;
