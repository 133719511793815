import * as Yup from "yup";
import { emailSchema } from "../../../Utils/Formik/FormikCommonValidationSchemas";
import { api } from "../../../Api/Api";
import { toast } from "react-toastify";


export const ForgotPasswordEmailValidaionSchema = Yup.object().shape({
    email: emailSchema.required('Email is required')
});

export const handleEmailSubmit = (values: any, actions: any, navigate: Function) => {
    api.post("/reset-password", values, true)
        .then(async function ([success, response]: any) {
            toast.success('Password reset link sent to the email')
            navigate('/')
            actions?.setSubmitting(false)
        })
        .catch((err) => {

            actions?.setSubmitting(false)
            if (err?.response?.data?.message) {
                toast.error(err?.response?.data?.message)
                actions?.setFieldError('email', err?.response?.data?.message)
            }
            else {
                toast.error('Unexpected Error Occurred')
            }
        });
}