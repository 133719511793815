import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Container, Row } from "reactstrap";
import { api } from "../../../../Api/Api";


interface Subcategory {
  id: string;
  document_file: string;
  name: string;
  document_file_url: string;
}

interface Category {
  id: string;
  subcategories: Subcategory[];
  document_file_url: string | null;
}

const LatestResources: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    getLatest_();
  }, []);

  const getLatest_ = () => {
    api
      .get('/download-categories/get-latest', true)
      .then(([success, response]) => {
        if (success && response?.data) {
          const updatedCategories = response.data.map((category: Category) => {
            const updatedSubcategories = category.subcategories.map((subcategory: Subcategory) => {
              return {
                ...subcategory,
                is_check: false // Assuming you want to add an "is_check" property to each subcategory
              };
            });
            return {
              ...category,
              subcategories: updatedSubcategories
            };
          });
          setCategories(updatedCategories);
        }
      })
      .catch((err) => {
        console.error('Error fetching latest categories:', err);
      });
  };
  const downloadPDF = async (document_file_url: string, document_file: string,id:any) => {
    try {
      const response = await fetch(document_file_url);
      const blob = await response.blob();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = document_file;
      link.click();
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };
  const handleDownloadClick = async (subCategory: any) => {
    const { id, name, document_file_url } = subCategory;
    try {
      await api.patch(`/download-categories/updateCount?id=${id}`, { count: 1 }, true);
      downloadPDF(document_file_url, name, id);

    }
    catch (error) {
      console.error("Error handling download:", error);
    }
  };
  return (
    <>
      <section className='latest-resource-categories'>
        <Container>
          <div className="latest-resource-categ-inner">
            <div className="main-heading">
              <h4>Latest Uploads</h4>
            </div>
            <div className="content-list">
              <Row>
                {categories.map((category) => (
                  category.subcategories.map((subcategory) => (
                    <Col key={subcategory.id} xs={12} sm={12} md={6} lg={3}>
                      <Card className="card-wrapper">
                        {/* <Link to={`/resources/sub-categories/${subcategory.id}`}> */}
                          <div>
                            <div className="thumbnail-wrap">
                              <embed
                                src={subcategory.document_file_url || ""}
                                className="thumbnail-md-cover"
                              />
                              <div className="name-wrap d-flex align-items-center justify-content-between">
                                <h6 className="name">{subcategory.name}</h6>
                              <span
                                title="Download"
                                onClick={() => handleDownloadClick(subcategory)}
                                >

                                <i className="icon icon-download xl"></i>
                              </span>
                    
                              </div>
                              
                            </div>
                          </div>
                        {/* </Link> */}
                      </Card>
                    </Col>
                  ))
                ))}
              </Row>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default LatestResources;
