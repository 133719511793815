import React, { useState } from "react";
import { Col, Button } from "reactstrap";
import { CardTypeMagazine } from "../../Type/index";
import {
  ISODateToDateConverter,
  ISODateToDateConverter2,
} from "../../Utils/helpers/helpers";

interface CardProps {
  props: CardTypeMagazine;
  url_go: string;
  handleCardClick: Function;
}

const Magazine: React.FC<CardProps> = ({ props, url_go, handleCardClick }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <Col sm={12} md={6} lg={3}>
      <div className="magazine-sec">
        <div className="thumbnail-wrap">
          {props?.document_file && (
            <embed
              src={props?.document_file}
              className="thumbnail-md-cover img-fluid"
            />
          )}
          <div className="read-button hover-class">
            <Button
              // outline
              color="primary"
              onClick={() => handleCardClick(props)}
            >
              Read
            </Button>
          </div>
          <p className="text-center">
            {ISODateToDateConverter2(props?.released_date)}
          </p>
          <p className="text-center">{props?.title}</p>
        </div>
      </div>
    </Col>
  );
};

export default Magazine;
