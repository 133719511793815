import React, { useEffect } from 'react'

import OfficeBanner from './container/officeBanner'
import OfficeList from './container/officeList'

const OfficeIndex: React.FC = () => {
  useEffect(() => {
		window.scrollTo(0, 0)
	  }, [])
  return (
    <>
      <OfficeBanner />
      <OfficeList />
    </>
  )
}

export default OfficeIndex
