import React, { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import {
  Container,
  Row,
  InputGroup,
  Input,
  ListGroupItemHeading,
  ListGroupItemText,
  Button,
  ListGroupItem,
  Label,
  Table,
} from "reactstrap";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import {
  getDownloadCategoreyList,
  getDownloads,
  getDownloadsSub,
} from "../../../../Api/WebsiteApi";
import DownloadViewModal from "./DownloadViewModal";
import { useNavigate, useParams } from "react-router-dom";
import CustomPagination from "../../../../Components/Pagination/Pagination";
import { Link } from "react-router-dom";
import { api } from "../../../../Api/Api";
import DownloadListBanner from "./DownloadBanner";

const DownloadSubcategoryList: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [Sub_id, setSubId] = useState([]);
  const { currentPage = "1", id } = useParams();
  const currentUrl: any = window.location.href;
  const params = new URLSearchParams(window.location.search);
  const cat = params.get("category");

  const {
    data: downloadData,
    error: downloadError,
    isLoading: downloadLoading,
    refetch: downloadListRefetch,
  }: any = useQuery(
    [`download${currentPage}`, currentPage],
    () => getDownloadCategoreyList(currentPage, id),
    { keepPreviousData: true }
  );
  const [data, setData] = useState<any>(downloadData || []);
  const DownloadDataTable = useMemo(
    () => setData(downloadData),
    [downloadData]
  );
  const check_data = (itemIndex: number, subcategoryIndex: number) => {
    // Create a shallow copy of the items array
    const newItems = downloadData?.data?.items.map(
      (item: any, index: number) => {
        if (index === itemIndex) {
          // Create a shallow copy of the subcategories array for the current item
          const newSubcategories = item?.subcategories?.map(
            (subcategory: any, subIndex: number) => {
              if (subIndex === subcategoryIndex) {
                // Toggle the is_check property for the specific subcategory
                return {
                  ...subcategory,
                  is_check: !subcategory.is_check,
                };
              }
              return subcategory; // Return the original subcategory if not the targeted one
            }
          );

          // Return the updated item with the modified subcategories array
          return {
            ...item,
            subcategories: newSubcategories,
          };
        }
        return item; // Return the original item if not the targeted one
      }
    );

    // Update the state with the new array of items
    setData({
      ...downloadData,
      data: {
        ...downloadData?.data,
        items: newItems,
      },
    });
  };

  const [sub_data, setSubData] = useState([]);
  const getAllSubSubcategory = async (main_id: any, id: string) => {
    const [, datas] = await api.get(
      `/download-categories/${main_id}?parent_id=${id}`,
      true
    );
    setSubData(datas);
  };

  const [view, setView] = useState(false); // For viewing
  const [download, setDownload] = useState(false); // For downloading
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const [url, setUrl] = useState("");
  const myDivRef: any = useRef(null);
  const toggleModal = (content: string, isView: boolean) => {
    setIsOpen(true);
    setModalContent(content);

    if (isView) {
      setView(true);
      setDownload(false);
    } else {
      setView(false);
      setDownload(true);
    }
  };
  const ScrollToContainerTop = (ref: any) => {
    if (ref?.current) {
      ref?.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };
  const navigate = useNavigate();
  const setCurrentPageStaff = (navigate: Function, pageNo: any) => {
    navigate(`/downloads/${pageNo}`);
  };
  const handleDownloadSearch = (
    e: any,
    setSearchTerm: Function,
    navigate: Function
  ) => {
    if (e?.target?.value?.length >= 1) {
      navigate(`/downloads/1`);
      setSearchTerm(e?.target?.value);
    } else {
      setSearchTerm("");
    }
  };
  const downloadPDF = async (document_file_url: string, document_file: string) => {
    try {
      const response = await fetch(document_file_url);
      const blob = await response.blob();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = document_file;
      link.click();
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const handleDownloadClick = (subCategory: any) => {
    const { name, document_file_url } = subCategory;
    downloadPDF(document_file_url, name);
  };
  return (
    <>
      {/* <h4 className="text-center py-4 text-primary mb-0">COMING SOON</h4> */}

      <DownloadListBanner />
      <section className="download-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap mb-5">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search Downloads"
                  onChange={(e) =>
                    handleDownloadSearch(e, setSearchTerm, navigate)
                  }
                />
                <span className="icon-container">
                  <i className="icon xl icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="button-wrap">
              <Button
                color="primary"
                className="sm"
                onClick={() => {
                  setSearchTerm("");
                }}
              >
                Reset
              </Button>
            </div>
          </div>
        </Container>
        {/* <div className="reveal"> */}
        <Container>
          <div className="download-list-wrap">
            <p className="text-center fs-5 fw-bold">Category name: {cat}</p>

            {/* {downloadData?.data?.items &&
              downloadData?.data?.items?.map((category: any, i: any) => (
                <div className="download-list-inner" key={category?.id}>
                  <div className="download-list-item">
                    <ListGroupItemHeading>
                      {category?.category_name}
                    </ListGroupItemHeading>
                    <ListGroupItemText>
                      {category && (
                        <>
                          <ul key={category?.id}>
                            <li>
                              <p>{category?.name}</p>

                              <div className="icon-wrap">
                                <span
                                  onClick={() => {
                                    check_data(i);
                                    setSubId(category?.id);
                                  }}
                                >
                                  <i
                                    title="View More"
                                    className="icon icon-down xl"
                                  ></i>
                                </span>
                                <Link
                                  to={category?.document_file_url}
                                  target="_blank"
                                >
                                  <i
                                    title="View Document"
                                    className="icon icon-eye-open xl"
                                  ></i>
                                </Link>

                                <i
                                  title="Download Document"
                                  onClick={() => {
                                    toggleModal(category?.name, false);
                                    setUrl(category?.document_file_url);
                                  }}
                                  className="icon xl icon-download"
                                ></i>
                              </div>
                              {category?.is_check === true && (
                                <>
                                  {category?.subcategories?.map((ite: any) => {
                                    return (
                                      <>
                                        <div className="list-wrapper">
                                          <ul>
                                            <li>{ite?.name}</li>
                                          </ul>
                                        </div>
                                      </>
                                    );
                                  })}
                                </>
                              )}
                            </li>
                          </ul>
                        </>
                      )}
                    </ListGroupItemText>
                  </div>
                </div>
              ))} */}
            {data?.data?.items?.map((item: any, i: any) => {
              const currentIndex =
                (downloadData?.data?.meta?.currentPage - 1) *
                downloadData?.data?.meta?.itemsPerPage +
                i +
                1;
              return (
                <>
                  <tr key={currentIndex}>
                    <td>{currentIndex}.</td>
                    <td>{item?.name}</td>
                    <td>{item?.parent}</td>
                    <td>
                      {item?.subcategories?.map((items: any, subIndex: any) => {
                        return (
                          <ul key={subIndex}>
                            <li>{items?.name}</li>
                            <div className="icon-wrap">
                              <span
                                onClick={() => {
                                  check_data(i, subIndex);
                                  getAllSubSubcategory(id, items?.id);
                                }}
                              >
                                View File
                                <i title="View More" className="icon icon-down xl"></i>
                              </span>
                            </div>

                            {items?.is_check === true && (
                              <>
                                {/* Move the link outside of the subcategories loop */}

                                <Row className="gy-4">
                                <div className="table-outer-wrap">
                                <div className="table-outer">
                                  <Table
                                    key={subIndex}
                                    className="sub-category-table"
                                  >
                                  {/* <thead>
                                      <tr>
                                        <th>File Name</th>
                                        <th>Preview</th>
                                        <th>View</th>
                                        <th>Download</th>
                                      </tr>
                                    </thead> */}
                                   
                                    <tbody>
                                    {items?.document_file_url && (
                                      <tr>
                                        <td>
                                          <span className="mobile-heading">
                                            File Name
                                            </span>
                                            <span className="table-data">
                                            {items?.name}
                                          </span>
                                        </td>
                                        <td>
                                        <span className="mobile-heading">
                                            Preview
                                          </span>
                                          <span className="table-data">
                                            <embed
                                              src={
                                                items?.document_file_url
                                              }
                                              className="embed-pdf img-fluid"
                                            />
                                          </span>
                                        </td>
                                        <td>
                                          <span className="mobile-heading">
                                            View
                                          </span>
                                          <span className="table-data">
                                            <Link
                                              to={
                                                items?.document_file_url
                                              }
                                              target="_blank"
                                            >
                                              <i
                                                title="View"
                                                className="icon xl icon-eye-open"
                                              ></i>
                                            </Link>
                                          </span>
                                        </td>
                                        <td>
                                          <span className="table-data">
                                            <i
                                              title="Download"
                                              onClick={() => handleDownloadClick(items)}
                                              className="icon xl icon-download"
                                            ></i>

                                          </span>
                                        </td>

                                      </tr>
                                      )}
                                  </tbody>
                                    
                                  </Table>
                                  </div>
                                </div>
                                </Row>
                                {sub_data?.map((sub_: any) => {
                                  return (
                                    <>
                                <Row className="gy-4">
                                <div className="table-outer-wrap">
                                <div className="table-outer">
                                  <Table
                                    key={sub_?.id}
                                    className="sub-category-table"
                                  >
                                      <thead>
                                      <tr>
                                        <th>File Name</th>
                                        <th>Preview</th>
                                        <th>View</th>
                                        <th>Download</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                        <span className="mobile-heading">
                                            File Name
                                        </span>

                                        <span className="table-data">
                                        {sub_?.name}
                                          </span>
                                        </td>
                                        <td>
                                        <span className="mobile-heading">
                                           Preview
                                        </span>
                                        <span className="table-data">
                                        <embed
                                              src={sub_?.document_file_url} 
                                              className="embed-pdf img-fluid"
                                            />
                                          </span>
                                        </td>
                                        <td>
                                          <span className="mobile-heading">
                                            View
                                          </span>
                                          <span className="table-data">
                                            <Link
                                              to={
                                                sub_?.document_file_url
                                              }
                                              target="_blank"
                                            >
                                              <i
                                                title="View"
                                                className="icon xl icon-eye-open"
                                              ></i>
                                            </Link>
                                          </span>
                                        </td>
                                        <td>
                                          <span className="table-data">
                                            <i
                                              title="Download"
                                              onClick={() => handleDownloadClick(sub_)}
                                              className="icon xl icon-download"
                                            ></i>

                                          </span>
                                        </td>

                                      </tr>
                                    </tbody>
                                    </Table>
                                    </div>
                                    </div>
                                    </Row>
                                    </>
                                   
                                   );
                                })}
                              </>
                            )}
                          </ul>
                        );
                      })}
                    </td>
                  </tr>
                </>
              );
            })}
          </div>
        </Container>
        {/* </div> */}
        {data?.data?.meta?.totalItems !== 0 && (
          <Container>
            <div className="pagination">
              <CustomPagination
                totalItems={data?.data?.meta?.totalItems}
                itemsPerPage={data?.data?.meta?.itemsPerPage}
                activePage={data?.data?.meta?.currentPage}
                disableScrollAfterPageChange
                setCurrentPage={(pageNo: string) => {
                  ScrollToContainerTop(myDivRef);
                  setCurrentPageStaff(navigate, pageNo);
                }}
              />
            </div>
          </Container>
        )}
      </section>
      <ModalContainer isOpen={isOpen} setIsopen={setIsOpen} title="Download">
        <DownloadViewModal
          setIsOpen={setIsOpen}
          data={url}
          all_data={data?.data?.data}
          view={view}
          download={download}
        />
      </ModalContainer>
    </>
  );
};

export default DownloadSubcategoryList;
