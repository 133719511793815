import React from 'react'
import MemberList from './Containers/MemberList'
import MemberListBanner from './Containers/MemberListBanner'


const TeamsIndex:React.FC = () => {
  return (
    <>
    <MemberListBanner/>
    <MemberList/>
    </>
  )
}

export default TeamsIndex
