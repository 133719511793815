import { processJsonResponse } from "../Utils/helpers/helpers";
import { api } from "./Api";

// Banner listing
export async function getBannerFullList() {
  const [, data] = await api.get(`/open/banner`, true);
  return data;
}

// News listing
export async function getnews(id?: string) {
  let url;
  if (id) {
    url = `/open/news/${id}`;
  } else {
    url = `/open/news`;
  }
  const [, data] = await api.get(url, true);
  return data;
}

// About
export async function getAboutList() {
  const [, data] = await api.get(`/open/about`, true);
  return data?.data;
}

// Full Member
export async function getMembers(
  currentPage?: any,
  search?: string,
  district?: string,
  block?: string
) {
  var url = `/open/all_members?`;
  
  if (district && block) {
    url += `district_id=${district}&block_id=${block}&`;
  } 
  else if (district) {
    url += `district_id=${district}&page=${currentPage}&`;
  } else {
    url += `page=${currentPage}&`;
  }

  if (search) {
    url += `search=${search}&`;
  }

  const [, data] = await api.get(url, true);
  return data;
}


//Member search
export async function getMembersearch(search: any, filter: any) {
  const path = filter
    ? `/open/all_members?&&filter.district.id=${filter}&&search=${search}`
    : `/open/all_members?&&search=${search}`;
  const [, data] = await api.get(path, true);
  return data?.data?.data;
}

// Member id search
export async function getMemberid(id: any) {
  try {
    const [, data] = await api.get(`/open/member/${id}`, true);
    if (data) {
      return data;
    } else {
      throw `No data found for member with id: ${id}`;
    }
  } catch (error) {
    console.error("Error fetching member data:");
    throw error;
  }
}

export async function getEducation(id: any) {
  try {
    const [, response] = await api.get(`/open/memberEducation/${id}`, true);
    if (response) {
      return response;
    } else {
      throw `No data found for member with id: ${id}`;
    }
  } catch (error) {
    console.error("Error fetching member data:");
    throw error;
  }
}

//Accomodation search
export async function getAccomodation(searchQuery: any, filter: any) {
  const path = filter
    ? `/open/accomodation_listing?&&filter.OfficeAccomodation.district.id=${filter}&&search=${searchQuery}`
    : `/open/accomodation_listing?&&search=${searchQuery}`;
  const [, data] = await api.get(path, true);
  return data?.data;
}

// Statemember

export async function getStatemembers(
  currentPage?: any,
  search?: string,
  district?: string
) {
  var url = `/open/statemember_list?page=${currentPage}&search=${search}&`;

  if (district) url += `filter.district.id=${district}&`;
  const [, data] = await api.get(url, true);
  return data?.data;
}

// Services
export async function getServicesList() {
  const [, data] = await api.get(`/open/service`, true);
  return data?.data;
}

// Testimonials listing
export async function getTestimonialsList() {
  const [, data] = await api.get(`/open/testimonial`, true);
  return data;
}

// Gallery
export async function getGallery(currentPage?: any) {
  const [, data] = await api.get(`/open/gallery?page=${currentPage}`, true);
  return data?.data;
}

//Gallery without pagination
export async function getGallerywithoutpagination() {
  const [, data] = await api.get(`/open/gallery`, true);
  return data?.data;
}

// Gallery By id
export async function getGallerybyid(id: any) {
  const [, data] = await api.get(`/open/gallery/${id}`, true);
  return data?.data;
}

//Blog
export async function getBlogs(page: any) {
  const [, data] = await api.get(`/open/blog?page=${page}&limit=10`, true);
  return data;
}

//Blog view
export async function getBlogsView(id: any) {
  const [, data] = await api.get(`/open/blog/${id}`, true);
  return data;
}

// departments listing
export async function getDepartmentFullList() {
  const [, data] = await api.get(`/unauthDepartment`, true);
  return data?.data;
}

// departments listing
export async function getDepartmentFullListSelect() {
  const [, data] = await api.get(`/unauthDepartment`, true);
  return processJsonResponse(data?.data, ["id", "name"]);
}

// Magazine
export async function getMagazine(page: any, searchQuery: any, date: any) {
  var url = `/open/magazine?page=${page}&search=${searchQuery}&`;
  if (date) url += `filter.released_date=${date}&`;
  const [, data] = await api.get(url, true);
  return data;
}

// Magazine
export async function getMagazineById(id: any) {
  const [, data] = await api.get(`/open/magazine/${id}`, true);
  return data;
}

//Blog
export async function getDownloads(search: any, page: any) {
  const [, data] = await api.get(
    `/downloads?page=${page}&perPage=10&search=${search}`,
    true
  );
  data?.data?.data?.map((item: any) => {
    item.is_check = false;
  });
  return data;

}

export async function getDownloadsSub(id: any) {
  const [, data] = await api.get(`/download-categories/get-all/${id}`, true);
  return data;
}
export async function getDownloadCategoreyList(
  searchQuery: string ,
  id: any
) {
  const [, data] = await api.get(`/download-categories/get-all-website/${id}?search=${searchQuery}`, true);
  data?.data?.items?.map((item: any) => {
    item?.subcategories?.map((items: any) => {
      items.is_check = false;
    });
  });
  const tableData = data?.items?.map((item: any) => {
    return {
      id: item?.id,
      name: item?.name,
      parent: item?.parent,
      subcategories: item?.subcategories?.map((item: any) => {
        item.is_check = false;
      }),
      document_file_url: item?.document_file_url,
    };
  });

  return { tableData, data: data?.data };
}
export const getAllSubcategory = async (id: string) => {
  const [, data] = await api.get(`/download-categories/${id}`, true);
  const tableData = data?.map((item: any) => {
    return {
      value: item?.id,
      label: item?.name,
    };

  });
  return tableData;
  
};


// Office
export async function getOffice(
  district?: string,
  block?: string,
  panchayat?: string,
  currentPage?: any,
  searchQuery?: any
) {
  var url = `/open/office?page=${currentPage}&search=${searchQuery}&`;
  if (district) url += `filter.district.id=${district}&`;
  if (block) url += `filter.block.id=${block}&`;
  if (panchayat) url += `filter.panchayath.id=${panchayat}`;

  const [, data] = await api.get(url, true);
  return data;
}

// district
export async function getDistrict() {
  const [, data] = await api.get(`/open/district`, true);
  const new_array: any = [];
  data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}
export async function getCategories(){
  const [,data] = await api.get(`/downloads/get-categories`,true)
  const new_array: any = [];
  data?.data?.data.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}
// block
export async function getBlock(district: string) {
  const [, data] = await api.get(`/open/block?district=${district}`, true);
  const new_array: any = [];
  data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}

// panchayat
export async function getPanchayat(district: string, block: string) {
  const [, data] = await api.get(`/open/panchayath?block=${block}`, true);
  const new_array: any = [];
  data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}

// state
export async function getStateMember() {
  const [, data] = await api.get(`/open/statemember_list`, true);
  return data;
}

// District
export async function getOpenDistrict() {
  const [, data] = await api.get(`/open/district`, true);
  return processJsonResponse(data, ["id", "name"]);
}

export async function getOpenCader(office_id?: any, block_id?: any) {
  const [, data] = await api.get(
    `/open/cadre?office_id=${office_id}&block_id=${block_id}`,
    true
  );
  return processJsonResponse(data?.data, ["id", "name"]);
}

// Designation
export async function getOpenDesignationFullList(
  cadre_id: any,
  district_id: any,
  office_cat_id: any
) {
  const [, data] = await api.get(
    `/open/all_designation?cadre_id=${cadre_id}&district_id=${district_id}&office_cat_id=${office_cat_id}`,
    true
  );
  const new_array: any = [];
  data?.data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}

// Office Full
export async function getOpenOfficeFullList(office_cat: any, block_id: any) {
  const [, data] = await api.get(
    `/open/office-list?office_cat_id=${office_cat}&block_id=${block_id}`,
    true
  );
  const new_array: any = [];
  data?.data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });
  return new_array;
}

/// office listing without Pagination
export async function getOpenBlock(id: any) {
  const [, data] = await api.get(`/open/block?district=${id}`, true);

  return processJsonResponse(data, ["id", "name"]);
}

// Seat
export async function getOpenSeatFullList(id: any) {
  const [, data] = await api.get(`/open/office/get-all/${id}`, true);
  const new_array: any = [];
  data?.data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}

// Vision and Mission
export async function getvisionandmission() {
  const [, data] = await api.get(`/open/our_mission`, true);
  return data?.data;
}

//Vision photo
export async function getvisionandmissionphoto() {
  const [, data] = await api.get(`/open/aboutImage`, true);
  return data;
}

export async function getOpenOfficeCategory(block_id: any) {
  const [, data] = await api.get(
    `/open/office-category?block_id=${block_id}`,
    true
  );

  const new_array: any = [];
  data?.data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}

export async function getSIngleMeta(active: any) {
  const [, data] = await api.get(`/open/seo`, true);
  return data;
}
export async function getOpenCadreFullList() {
  const [, data] = await api.get(`/open/office-category`, true);

  const new_array: any = [];
  data?.data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}

export async function getPaymentUser(token: string) {
  const [, data] = await api.get(`/payment/order-details/${token}`, true);
  return data?.data;
}

export async function getOtherPaymentUser(token: string) {
  const [, data] = await api.get(
    `/payment/other-member-order-details/${token}`,
    true
  );
  return data?.data;
}
