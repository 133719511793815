import { Link } from "react-router-dom";
import { Container, Row, Col, List } from "reactstrap";

const BlogListBanner = (props: any) => {
  return (
    <>
      <section className="page-banner">
        <div className="page-banner-wrap">
          <Container>
            <Row>
              <Col lg={12} xs={12}>
                <div className="heading-wrap">
                  <h1>Blogs</h1>
                </div>
                <nav aria-label="breadcrumb">
                  <List className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    {props?.from === "blog_view" ? (
                      <>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          <Link to="/blogs/1">Blog</Link>
                        </li>{" "}
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Blog View
                        </li>
                      </>
                    ) : (
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Blogs
                      </li>
                    )}
                  </List>
                </nav>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default BlogListBanner;
