import React from "react";
import { useQuery } from "react-query";
import { Button, Container, Row, Card, Col } from "reactstrap";
import { getBlogs } from "../../../../Api/WebsiteApi";
import {
  CardTypeBlogs,
} from "../../../../Type/index";
import { useNavigate } from "react-router-dom";
import BlogsCard from "../../../../Components/Cards/Blogs";

const Blogs: React.FC = () => {
  const { data: blogsList }: any = useQuery("blogs", () => getBlogs(1), {});
  const first4blogs = blogsList?.items?.slice(0, 4) || blogsList?.items || [];
  const navigate = useNavigate();

  return (
    <>
      {first4blogs?.length !== 0 && (
        <section className="blog-main-wrap bottom-image-right">
          <div className="reveal">
            <Container>
              <div className="heading-wrap">
                <h4 className="heading">Blogs</h4>
              </div>
            </Container>

            {/* cards section */}
            <div className="blog-outer-wrap">
              <Container>
                <Row className="gy-4 justify-content-center">
                  {first4blogs.map((gallery: CardTypeBlogs) => (
                    <BlogsCard
                      props={gallery}
                      key={gallery?.id}
                      url_go="/blogs/i"
                    />
                  ))}

                </Row>
                {blogsList && blogsList?.items?.length > 4 && (
                  <div className="learn-more-btn mt-5">
                    <Button
                      outline
                      color="primary"
                      className="sm"
                      onClick={() => navigate("/blogs/1")}
                    >
                      View All
                      <i className="icon icon-next-green"></i>
                    </Button>
                  </div>
                )}
              </Container>
            </div>
          </div>
          <div className="bottom-image-right"></div>
        </section>
      )}
    </>
  );
};

export default Blogs;
