import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { getBlogsView } from "../../../../Api/WebsiteApi";
import { useParams } from "react-router-dom";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import AddBlogComment from "./BlogCommentModal";
import { LikeSubmitHandler, checkLikeStatus, } from "./BlogAddMethods";
import BlogListBanner from "./BlogListBanner";
import { formatDateTimeForInput } from "../../../../Utils/helpers/helpers";

const BlogsView: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLiked, setIsLiked] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const { id } = useParams();
  const { data: blogsList, refetch: blogReftech }: any = useQuery(
    ["blogview", id],
    () => getBlogsView(id),
    {}
  );

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({

          url: window.location.href,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      console.log("Web Share API not supported");

    }
  };

  useEffect(() => {
    checkLikeStatus(id, setIsLiked);
  }, [id]);

  return (
    <>
      <section className="blog-view-wrap">
        {/* <div className="reveal"> */}
          <BlogListBanner from={"blog_view"} />
          {/* cards section */}
          <div className="blog-view-outer">
            <Container>
              <Row className="justify-content-center">
                <h4 className="text-center">{blogsList?.title}</h4>
                <Col md={6}>
                  <img src={blogsList?.image} alt="blogimage" />
                  <div className="info-action-outer">
                    <div className="info-wrap">
                      <span>{blogsList?.user?.first_name},</span>
                      <span>{formatDateTimeForInput(blogsList?.published_date)}</span>
                    </div>
                    <div className="action-wrap">
                      <span>{blogsList?.BlogLikes?.length}</span>
                      <Link to={`/blog-view/${id}`}>
                        <i
                          title="Like"
                          className={`icon xl  ${isLiked ? 'icon-liked' : 'icon-like'}`}
                          style={{ color: isLiked ? 'blue' : 'inherit' }}
                          onClick={() => LikeSubmitHandler(blogReftech, id, setIsLiked)}
                        ></i>

                      </Link>
                      <span>{blogsList?.BlogComments?.length}</span>
                      <Link to={`/blog-view/${id}`}>
                        <i title="Comment"
                          className="icon icon-comment xl"
                          onClick={() => setIsOpen(true)}
                        ></i>
                      </Link>
                      <Link to="" onClick={handleShare}>
                        <i title="Share" className="icon icon-import xl"></i>
                      </Link>
                    </div>
                  </div>
                  <p dangerouslySetInnerHTML={{
                    __html: blogsList?.body,
                  }}></p>

                  <div className="info-action-outer justify-content-end">
                    <div className="action-wrap">
                      <span>{blogsList?.BlogLikes?.length}</span>
                      <Link to={`/blog-view/${id}`}>
                        <i
                          title="Like"
                          className={`icon xl  ${isLiked ? 'icon-liked' : 'icon-like'}`}
                          onClick={() => LikeSubmitHandler(blogReftech, id, setIsLiked)}
                        ></i>
                      </Link>

                      <span>{blogsList?.BlogComments?.length}</span>
                      <Link to={`/blog-view/${id}`}>
                        <i title="Comment"
                          className="icon icon-comment xl"
                          onClick={() => setIsOpen(true)}
                        ></i>
                      </Link>
                      <Link to="">
                        <i title="Share" className="icon icon-import xl" onClick={handleShare}></i>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        {/* </div > */}
      </section >

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title="Add Comment"
      >
        <AddBlogComment setIsOpen={setIsOpen} refetch={blogReftech} id={id} />
      </ModalContainer>
    </>
  );
};

export default BlogsView;