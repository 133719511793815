import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { modalPropType } from "../../Type";

const ModalContainer = ({
  isOpen,
  setIsopen,
  title = "",
  children,
}: modalPropType) => {
  return (
    <Modal
      isOpen={isOpen}
      scrollable={true}
      centered={true}
      toggle={() => setIsopen(false)}
    >
      <ModalHeader toggle={() => setIsopen(false)}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export default ModalContainer;
