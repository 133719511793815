import React from 'react'
import AccomodationList from './Containers/AccomodationList'
import AccomodationListBanner from './Containers/AccomodationListBanner'


const AccomodationIndex:React.FC = () => {
  return (
    <>
    <AccomodationListBanner/>
    <AccomodationList/>
    </>
  )
}

export default AccomodationIndex
