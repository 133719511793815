import React from "react";
import { Container, Row } from "reactstrap";
import { getTestimonialsList } from "../../../../Api/WebsiteApi";
import { useQuery } from "react-query";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";

const Testimonial: React.FC = () => {
  const { data: testimonialsList }: any = useQuery("testimonials", () => getTestimonialsList())

  return (
    <>
      {
        <div className="top-corner-image">
          <section className="testimonial-outer-wrap bottom-corner-image ">
            <div className="top-corner-image"></div>
            <div className="reveal">
              <Container>
                <Row className="align-items-center justify-content-center">
                  <div className="heading-wrap">
                    <div className="heading">
                      <h4 className="main-heading">What Our People Are Saying</h4>
                    </div>
                  </div>
                  <AwesomeSlider>
                    {
                      testimonialsList?.map((testimonial: any) => {
                        return (
                          <div className="testimonial-content-wrap">
                            <p className="testimonial-content">
                              {testimonial?.testimonial}
                            </p>
                            <div className="testimonial-content-bottom-wrap">
                              <div className="testimonial-profile-content">
                                <h6>{testimonial?.name}</h6>
                                <p>{testimonial?.designation}</p>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </AwesomeSlider>
                </Row>
              </Container>
            </div>
            <div className="bottom-corner-image"></div>
          </section>
        </div>
      }
    </>
  );
};

export default Testimonial;
