import React, { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Button } from "reactstrap";
import { getMembers } from "../../../../Api/WebsiteApi";
import DocCards from "../../../../Components/Cards/DocCard";
import { CardTypeMem } from "../../../../Type/index";
import statepresident from "../Containers/Images/Members/George Abraham State President.jpg";
import statesecretary from "../Containers/Images/Members/shaji r state gen secretary.jpeg";
import Statetressurer from "../Containers/Images/Members/anjana state Treasurer.jpg";
import StateWebadmin from "../Containers/Images/Members/BAIJU state web admin.jpg";

const Member: React.FC = () => {
  const [value, setValue] = useState({
    district_id: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const { currentPage = "1" } = useParams();
  const {
    data: memberList,
    isLoading: memberListLoading,
    isError,
  }: any = useQuery(
    ["Members", currentPage, searchTerm, value?.district_id],
    () => getMembers(currentPage, searchTerm, value?.district_id),
    {
      keepPreviousData: true,
    }
  );

  const first4Doctor = memberList?.data?.items?.slice(0, 4) || [];
  const navigate = useNavigate();

  const cardArray: CardTypeMem[] = [
    {
      id: 1,
      user: {
        first_name: "Sri. GEORGE T ABRAHAM",
        last_name: "",
      },
      post: "Lorem ipsum dolor sit amet",
      profile_img: statepresident,
      designationMeta: {
        id: "1",
        name: "State President",
      },
    },
    {
      id: 2,
      user: {
        first_name: "Sri. SHAJI R",
        last_name: "",
      },
      post: "Consectetur adipiscing elit",
      profile_img: statesecretary,
      designationMeta: {
        id: "2",
        name: "State General Secretary",
      },
    },
    {
      id: 3,
      user: {
        first_name: "Smt. ANJANA S",
        last_name: "",
      },
      post: "Consectetur adipiscing elit",
      profile_img: Statetressurer,
      designationMeta: {
        id: "2",
        name: "State Treasurer",
      },
    },
    {
      id: 4,
      user: {
        first_name: "Sri. BAIJU G ",
        last_name: "",
      },
      post: "Consectetur adipiscing elit",
      profile_img: StateWebadmin,
      designationMeta: {
        id: "2",
        name: "State Web Admin",
      },
    },
  ];

  return (
    <>
      {memberList?.data?.items?.length !== 0 && (
        <section className="member-outer-wrap">
          <div className="reveal">
            <Container>
              <div className="heading-wrap">
                <h4 className="main-heading">STATE OFFICE BEARERS</h4>
              </div>
            </Container>

            {/* cards section */}
            <div className="member-list-outer-wrap">
              <Container>
                <Row className="align-items-center justify-content-center">
                  {cardArray?.map((data: CardTypeMem) => (
                    <DocCards
                      props={data}
                      key={data?.id}
                      to={`/members-profile`}
                    />
                  ))}
                </Row>
              </Container>
            </div>
          </div>
          {/* {localStorage.getItem("ROLE") !== null && (
            <> */}
          <div>
            <div className="learn-more-btn">
              <Button
                outline
                color="primary"
                className="sm"
                onClick={() => navigate("/statemember/1")}
              >
                View All
                <i className="icon icon-next-green"></i>
              </Button>
            </div>
          </div>
          {/* </>
          )} */}
        </section>
      )}
      {/* <HorizonatlScroll /> */}
    </>
  );
};

export default Member;
