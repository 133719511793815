import React, { useEffect } from 'react';
import TermsAndConditionsBanner from './Containers/TermsAndConditionsBanner';
import TermsAndConditionsList from './Containers/TermsAndConditionsList';

const TermsAndConditions:React.FC = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	  }, [])
	return (
		<>
			<TermsAndConditionsBanner />
			<TermsAndConditionsList />
		</>
	);
};

export default TermsAndConditions;