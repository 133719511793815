import React from "react";
import {
  Container,
  Col,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import { getEducation, getMemberid } from "../../../../Api/WebsiteApi";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

const MemberInfoCard: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: memberList,
    error: memberError,
    isLoading: memberLoading,
    refetch: memberListRefetch,
  }: any = useQuery(["memberProfile", id], () => getMemberid(id), {});

  const {
    data: educationList,
    error: educationError,
    isLoading: educationLoading,
    refetch: educationListRefetch,
  }: any = useQuery(["education", memberList?.user?.id], () => getEducation(memberList?.user?.id), {});

  return (
    <section>
      <div>
        <Container>
          <div className="member-detail-card-wrap">
            {educationList && educationList.length > 0 && (
              <Col md={6} className="colored-card-col">

                <Card>
                  <CardBody>
                    <CardTitle tag="h4">Education</CardTitle>
                    {educationList.map((education: any, index: any) => (
                      <div key={index} className="edu-wrap">
                        <span>
                          <i className="icon icon-tick xl"></i>
                        </span> 
                          <h6>{education.degree_name}</h6>
                          <p>{education?.educat_cat_name}</p>
                      </div>
                    ))}
                  </CardBody>
                </Card>
              </Col>
            )}
          </div>
        </Container>
      </div>
    </section>
  );
};

export default MemberInfoCard;
