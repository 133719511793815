import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import { Container, Row } from "reactstrap";
import { CardTypeGallery} from "../../../../Type/index";
import GalleryCard from "../../../../Components/Cards/gallery";
import { getGallery } from "../../../../Api/WebsiteApi";
import CustomPagination from "../../../../Components/Pagination/Pagination";
import { useNavigate, useParams } from "react-router-dom";
import { ScrollToContainerTop } from "../../../../Utils/helpers/helpers";

const GalleryListContainer: React.FC = () => {
  const myDivRef: any = useRef(null);
  
  const { currentPage = "1" } = useParams();
  const { data: galleryList }: any = useQuery(
    ["gallery", currentPage],
    () => getGallery(currentPage),
    {}
  );
  const navigate = useNavigate();
  const setCurrentPageStaff = (navigate: Function, pageNo: any) => {
    navigate(`/gallery/${pageNo}`);
  };

  return (
    <>
      {
        galleryList?.length !== 0 && (
          <section className="gallery-outer-wrap">
              <Container>
                <div className="heading-wrap">
                  <div>
                    <h4 className="main-heading">Gallery</h4>
                  </div>
                </div>
              </Container>

              {/* cards section */}
              <div className="gallery-list-outer-wrap">
                <Container>
                  <Row className="gy-4">
                    {galleryList?.data?.map((gallery: CardTypeGallery) => (
                      <GalleryCard props={gallery} key={gallery?.id} url_go="/gallery/1" />
                    ))}
                  </Row>

                </Container>
              </div>

            {galleryList?.meta?.totalItems !== 0 && (
              <Container>
                <div className="pagination">
                  <CustomPagination
                    totalItems={galleryList?.meta?.totalItems}
                    itemsPerPage={galleryList?.meta?.itemsPerPage}
                    activePage={galleryList?.meta?.currentPage}
                    disableScrollAfterPageChange
                    setCurrentPage={(pageNo: string) => {
                      ScrollToContainerTop(myDivRef);
                      setCurrentPageStaff(navigate, pageNo);
                    }}
                  />
                </div>
              </Container>
            )}
          </section>
        )
      }
    </>
  );
};

export default GalleryListContainer;
