import React from "react";
import { Col, Card } from "reactstrap";
import { CardTypeGallery } from "../../Type/index";
import { useNavigate } from "react-router-dom";

interface CardProps {
  props: CardTypeGallery;
  url_go: string;
}

const GalleryCard: React.FC<CardProps> = ({ props, url_go }) => {
  const navigate = useNavigate();

  return (
    <Col xs={12} sm={12} md={6} lg={3} reveal>
      <Card className="card-wrapper">

        <div >
          {props?.galleryImage && props?.galleryImage.length > 0 && (
            <div className="thumbnail-wrap" onClick={() => navigate(`/gallerydetail/${props.id}`)}>

              <img
                src={props.galleryImage[0].images}
                alt="gallery image"
                className="thumbnail-md-cover"
              />
              <div className="name-wrap">
                <h6 className="name">{props.title}</h6>
              </div>
            </div>
          )}
        </div>


      </Card>
    </Col>
  );
};

export default GalleryCard;
