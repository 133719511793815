import React from "react";
import { Col, Card } from "reactstrap";

interface CardProps {
  props: any;
  url_go: string;
}

const GallerySingleCard: React.FC<CardProps> = ({ props }) => {

  return (
    <Col xs={12} sm={6} md={6} lg={3} reveal>
      <Card className="card-wrapper">
        <div>
        
            <div className="thumbnail-wrap" >
                <img
                  src={props?.images}
                  alt="gallery image"
                  className="thumbnail-md-cover"
                />
       
            </div>
        </div>
      </Card>
    </Col>
  );
};

export default GallerySingleCard;
