import React, { useState, useRef } from "react";
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  Row,
  Table,
} from "reactstrap";
import {
  getBlock,
  getDistrict,
  getOffice,
} from "../../../../Api/WebsiteApi";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import CustomPagination from "../../../../Components/Pagination/Pagination";
import { ScrollToContainerTop } from "../../../../Utils/helpers/helpers";
import Select from "react-select";

interface Office {
  id: number;
  name: string;
  district: { name: string };
  address: string;
  email: string;
  phone_no: string;
}

const OfficeList: React.FC = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [value, setValue] = useState({
    district_id: "",
    block_id: "",
    panachayth_id: "",
  });

  const handleChange = (e: any, field_name: any) => {
    setValue((prevValue) => ({
      ...prevValue,
      [`${field_name}_id`]: e,
    }));
  };

  const { currentPage = "1" } = useParams();

  const myDivRef: any = useRef(null);
  const {
    data: officeData,
    error: officeError,
    isLoading: officeLoading,
    refetch: officeListRefetch,
  }: any = useQuery(
    [
      "Office",
      value?.district_id,
      value?.panachayth_id,
      value?.block_id,
      currentPage,
      searchQuery,
    ],
    () =>
      getOffice(
        value?.district_id,
        value?.block_id,
        value?.panachayth_id,
        currentPage,
        searchQuery
      ),
    { keepPreviousData: true }
  );

  const { data: districtData }: any = useQuery(
    ["District"],
    () => getDistrict(),
    {
      keepPreviousData: true,
    }
  );

  const { data: blockData }: any = useQuery(
    ["Block", value?.district_id],
    () => getBlock(value?.district_id),
    { keepPreviousData: true }
  );

  const setCurrentPageStaff = (pageNo: any) => {
    navigate(`/offices/${pageNo}`);
  };


  const handleDoctorSearch = (
    e: any,
    setSearchQuery: Function,
    navigate: Function,
  ) => {
    if (e?.target?.value?.length >= 1) {
      navigate(`/offices/1`);
      setSearchQuery(e?.target?.value);
    } else {
      setSearchQuery("");
    }
  };



  return (
    <div className="office-outer-wrap">
      <Col lg={12}>
        <Container>
          <div className="search-bar-full-wrap mt-5">
            <div className="search-bar">
              <InputGroup>
                <Input
                  type="text"
                  placeholder="Search Offices"
                  onChange={(e) => handleDoctorSearch(e, setSearchQuery, navigate)}
                />
                <span className="icon-container">
                  <i className="icon xl icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap">
                <div className="select-wrap">
                  <Select
                    placeholder="Select District"
                    options={districtData}
                    value={
                      districtData?.filter(
                        (itm: any) => itm?.value === value?.district_id
                      ) || ""
                    }
                    onChange={(e: any) => handleChange(e?.value, "district")}
                    name="district_id"
                    isLoading={false}
                    loadingMessage={() => "Fetching District"}
                    noOptionsMessage={() => "District appears here"}
                    isSearchable={true}
                    isClearable
                  />
                </div>
                <div className="select-wrap">
                  <Select
                    placeholder="Select Block"
                    options={blockData}
                    value={
                      blockData?.filter(
                        (itm: any) => itm?.value === value?.block_id
                      ) || ""
                    }
                    onChange={(e: any) => handleChange(e?.value, "block")}
                    name="block_id"
                    isLoading={false}
                    loadingMessage={() => "Fetching Block"}
                    noOptionsMessage={() => "Block appears here"}
                    isSearchable={true}
                    isClearable
                  />
                </div>

                <div className="btn-wrap">
                  <Button
                    color="primary" className="sm"
                    onClick={() => {
                      setValue((prevValue) => ({
                        ...prevValue,
                        district_id: "",
                        panachayth_id: "",
                        block_id: "",
                      }));
                      setSearchQuery("");
                    }}
                  >
                    Reset
                  </Button>
                </div>

            </div>
          </div>
        </Container>

        <Container className="office-container">
          <div className="my-2">
            <h4 className="main-heading">Offices</h4>
          </div>

          {/* Table */}
          <Row className="gy-4">
            <div className="table-outer-wrap">
              <div className="table-outer">
                <Table>
                  <thead>
                    <tr>
                      <th>Office Name</th>
                      {localStorage.getItem("ROLE") !== null && (
                        <>
                          <th>District</th>
                          <th>Address</th>
                          <th>Email</th>
                        </>
                      )}
                      <th>Mobile</th>
                    </tr>
                  </thead>
                  <tbody>
                    {officeData?.data?.map((office: Office) => (
                      <tr key={office.id}>
                        <td>
                          <span className="mobile-heading">Office Name</span>
                          <span className="table-data">{office?.name}</span>
                        </td>
                        {localStorage.getItem("ROLE") !== null && (
                          <>
                            <td>
                              <span className="mobile-heading">District</span>
                              <span className="table-data">{office?.district?.name}</span>
                            </td>
                            <td>
                              <span className="mobile-heading">Address</span>
                              <span className="table-data">{office.address}</span>
                            </td>
                            <td>
                              <span className="mobile-heading">Email</span>
                              <span className="table-data">{office.email}</span>
                            </td>
                          </>
                        )}
                        <td>
                          <span className="mobile-heading">Mobile</span>
                          <span className="table-data">{office.phone_no}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </Row>
        </Container>
      </Col>

      {officeData?.meta?.totalItems !== 0 && (
        <Container>
          <div className="pagination">
            <CustomPagination
              totalItems={officeData?.meta?.totalItems}
              itemsPerPage={officeData?.meta?.itemsPerPage}
              activePage={officeData?.meta?.currentPage}
              disableScrollAfterPageChange
              setCurrentPage={(pageNo: string) => {
                ScrollToContainerTop(myDivRef);
                setCurrentPageStaff(pageNo);
              }}
            />
          </div>
        </Container>

      )}
    </div>
  );
};

export default OfficeList;
