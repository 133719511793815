import React from 'react';
import { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Label, Input } from 'reactstrap';
import CustomButton from '../../../../Components/Button/CustomButton';
import { ErrorMessage, Formik } from 'formik';
import TextError from '../../../../Components/Errors/TextErrors';
import { contactUsValidationSchema, handlecontactSubmit } from './contactmethods';



const ContactUsForm: React.FC = () => {

  const [scrollPosition, setScrollPosition] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 400) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);

    window.scrollTo(0, scrollPosition);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const AboutUsValues = {
    first_name: '',
    last_name: '',
    email: '',
    message: '',
    phone_no: '',
  };

  const [aboutUsInitialValue, setAboutUsInitialValue] = useState(AboutUsValues);

  return (
    <>
      <section className="contact-us-main-wrap">
        {/* <div className="reveal"> */}
          <Container>
            <Row className='align-items-center justify-content-center'>
              <Col md={5}>
                <div className="image-container"></div>
              </Col>

              <Col md={6}>
                <div className="contact-us-form-inner-wrap p-md-5">
                  <div className="heading-wrap">
                    <div className="heading">
                      <h4 className="main-heading text-start">Get In Touch</h4>
                    </div>
                  </div>

                  <Formik
                    validationSchema={contactUsValidationSchema}
                    initialValues={aboutUsInitialValue}
                    enableReinitialize
                    onSubmit={(values, actions) => {
                      handlecontactSubmit(values, actions);

                    }}
                  >
                    {({ handleSubmit, handleChange, isSubmitting, values }) => (
                      <>
                        <div className="Add-web-settings-wrap">
                          <div className="form-wrap">
                            <Form onSubmit={handleSubmit}>
                              <Row className="gy-4">
                                <Col md={6}>
                                  <Input
                                    type="text"
                                    name="first_name"
                                    value={values?.first_name}
                                    onChange={handleChange}
                                    placeholder="First Name"
                                  />
                                  <ErrorMessage name="first_name" component={TextError} />
                                </Col>

                                <Col md={6}>
                                  <Input
                                    type="text"
                                    name="last_name"
                                    value={values?.last_name}
                                    onChange={handleChange}
                                    placeholder="Last Name"
                                  />
                                  <ErrorMessage name="last_name" component={TextError} />
                                </Col>

                                <Col md={6}>
                                  <Input
                                    type="text"
                                    name="email"
                                    value={values?.email}
                                    onChange={handleChange}
                                    placeholder="Email"
                                  />

                                  <ErrorMessage name="email" component={TextError} />
                                </Col>

                                <Col md={6}>
                                  <Input
                                    type="number"
                                    name="phone_no"
                                    value={values?.phone_no}
                                    onChange={handleChange}
                                    onFocus={(e) => (e.target.type = 'text')}
                                    onBlur={(e) => (e.target.type = 'number')}
                                    placeholder="Phone Number"
                                   
                                  />
                                  <ErrorMessage name="phone_no" component={TextError} />
                                </Col>

                                <Col md={12}>
                                  <Input
                                    type="textarea"
                                    onChange={handleChange}
                                    value={values?.message}
                                    name="message"
                                    placeholder="Message"
                                  />
                                  <ErrorMessage name="message" component={TextError} />
                                </Col>

                                <Col md={12} >
                                  <CustomButton
                                    isLoading={isSubmitting}
                                    label="Submit"
                                    color="primary"
                                    className="sm w-100 submit-btn"
                                  />
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </div>
                      </>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
            {/* <div className="contact-us-outer">
                        <div className="contact-us-form-wrap">
                        </div>
                    </div> */}
          </Container>

        {/* </div> */}

        {isVisible && (
          <>
            <div className="icon-wrap">
              <i
                className="icon icon-yellow-circle-lg xxl"
                onClick={() => scrollToTop()}
              ></i>
            </div>
          </>
        )}
      </section>

    </>
  );
};

export default ContactUsForm;
