import React, { useEffect } from 'react'
import GalleryListBanner from './Containers/GalleryListBanner'
import GalleryListContainer from './Containers/GaleryList'



const GalleryIndex: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <GalleryListBanner />
      <GalleryListContainer />
    </>
  )
}

export default GalleryIndex
