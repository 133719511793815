import React, { useEffect, useState } from "react";
import { Col, Card } from "reactstrap";
import { CardTypeMem } from "../../Type/index";
import { Link } from "react-router-dom";
import { FirstLetterUpper } from "../../Utils/helpers/helpers";

interface CardProps {
  props: CardTypeMem;
  to: string;
}

const DocCards: React.FC<CardProps> = ({ props, to }) => {
  const [active_top, setActiveTop] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [active_top]);

  return (
    <Col sm={12} md={6} lg={3}>
      <Card className="card-wrapper">
        {/* Use the 'Link' component with the specified route path */}

        <Link to={`${to}/${props?.id}`} onClick={() => setActiveTop(true)}>
          <div className="thumbnail-wrap">
            <img
              src={
                props?.profile_img
                  ? props?.profile_img
                  : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
              }
              alt="profile-image img-fluid"
            />

            <div className="name-wrap">
              <h6 className="name">
                {FirstLetterUpper(props?.user?.first_name)}{" "}
                {/* {FirstLetterUpper(props?.user?.last_name)} */}
              </h6>
              <p style={{ fontWeight: 800 }}>{props?.designationMeta?.name}</p>
              <p style={{ fontWeight: 800 }}>AOAOK</p>
            </div>
          </div>
        </Link>
      </Card>
    </Col>
  );
};

export default DocCards;
