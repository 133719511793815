import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";

const OtherPaymentSuccess = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="errorpage">
        <div className="error-text-wrap text-center">
          <h2 className="error-text">Congratulations 🎉</h2>
          <h2 className="error-text">Thank you for your payment!  Your transaction was successfull</h2>
          {/* <Button
            outline
            color="white"
            className="sm my-3"
            onClick={() => navigate("/")}
          >
            Back to home
          </Button> */}
        </div>
      </div>
    </>
  );
};

export default OtherPaymentSuccess;
