import { Button } from "reactstrap";
import CustomSpinner from "./CustomSpinner";
import { CustomButtonProps } from "../../Type";

const CustomButton = ({
  label,
  isLoading,
  className,
  color,
  handleSubmit,
}: CustomButtonProps) => {
  return (
    <Button
      type="submit"
      className={className}
      color={color}
      disabled={isLoading}
      onClick={handleSubmit}
    >
      {isLoading ? <CustomSpinner /> : label}
    </Button>
  );
};

export default CustomButton;
