import React, { useMemo, useRef } from 'react';
import { useEffect, useState } from 'react'
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Pagination from 'react-js-pagination';



import image1 from '../../images/categ-thumb1.png';
import image2 from '../../images/categ-img2.png';
import image3 from '../../images/categ-img3.png';
import image4 from '../../images/catge-img4.png';
import { useQuery } from 'react-query';
import { getDownloadCategoreyList } from '../../../../Api/WebsiteApi';
import { api } from '../../../../Api/Api';
import { classNames } from '@react-pdf-viewer/core';
import CustomSpinner from '../../../../Components/Button/CustomSpinner';




const ResourcesSubCategeories: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [Sub_id, setSubId] = useState([]);
  const { currentPage = "1", id } = useParams();
  const currentUrl: any = window.location.href;
  const params = new URLSearchParams(window.location.search);
  const cat = params.get("category");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [displayedItems, setDisplayedItems] = useState<number>(3); // Number of items to display
  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  const {
    data: downloadData,
    error: downloadError,
    isLoading: downloadLoading,
    refetch: downloadListRefetch,
  }: any = useQuery(
    [`download${searchQuery}`, searchQuery],
    () => getDownloadCategoreyList(searchQuery, id),
    { keepPreviousData: true }
  );
  const [data, setData] = useState<any>(downloadData || []);
  
  useMemo(() => {
    if (downloadData && downloadData.data) {
      setData(downloadData.data.items.slice(0, displayedItems)); // Limit data initially
    }
  }, [downloadData, displayedItems]);


  const loadMoreItems = () => {
    setLoadingMore(true);
    setTimeout(() => { // Simulating a delay, replace with actual data loading logic
      setDisplayedItems(displayedItems + 3); // Increase the number of displayed items
      setLoadingMore(false);
    }, 1000);
  };
  
  const check_data = (itemIndex: number, subcategoryIndex: number) => {
    // Create a shallow copy of the items array
    const newItems = downloadData?.data?.items.map(
      (item: any, index: number) => {
        if (index === itemIndex) {
          // Create a shallow copy of the subcategories array for the current item
          const newSubcategories = item?.subcategories?.map(
            (subcategory: any, subIndex: number) => {
              if (subIndex === subcategoryIndex) {
                // Toggle the is_check property for the specific subcategory
                return {
                  ...subcategory,
                  is_check: !subcategory.is_check,
                };
              }
              return subcategory; // Return the original subcategory if not the targeted one
            }
          );

          // Return the updated item with the modified subcategories array
          return {
            ...item,
            subcategories: newSubcategories,
          };
        }
        return item; // Return the original item if not the targeted one
      }
    );

    // Update the state with the new array of items
    setData({
      ...downloadData,
      data: {
        ...downloadData?.data,
        items: newItems,
      },
    });
  };

  const [sub_data, setSubData] = useState([]);
  const getAllSubSubcategory = async (main_id: any, id: string) => {
    const [, datas] = await api.get(
      `/download-categories/${main_id}?parent_id=${id}`,
      true
    );
    setSubData(datas);
  };

  const [view, setView] = useState(false); // For viewing
  const [download, setDownload] = useState(false); // For downloading
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const [url, setUrl] = useState("");
  const myDivRef: any = useRef(null);
  const toggleModal = (content: string, isView: boolean) => {
    setIsOpen(true);
    setModalContent(content);

    if (isView) {
      setView(true);
      setDownload(false);
    } else {
      setView(false);
      setDownload(true);
    }
  };
  const ScrollToContainerTop = (ref: any) => {
    if (ref?.current) {
      ref?.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };
  const navigate = useNavigate();
  const setCurrentPageStaff = (navigate: Function, pageNo: any) => {
    navigate(`/downloads/${pageNo}`);
  };
  
  const downloadPDF = async (document_file_url: string, document_file: string, id: any) => {
    try {
      const response = await fetch(document_file_url);
      const blob = await response.blob();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = document_file;
      link.click();
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const handleDownloadClick = async (subCategory: any) => {

    const { id, name, document_file_url } = subCategory;
    try {
      await api.patch(`/download-categories/updateCount?id=${id}`, { count: 1 }, true);
      downloadPDF(document_file_url, name, id);

    }
    catch (error) {
      console.error("Error handling download:", error);
    }

  };
  // Pagination static
  const [activePage, setActivePage] = useState(15);

  const handlePageChange = () => {
    console.log(`active page is 1`);
    setActivePage(1);
  };
  const handleDoctorSearch = (
    e: any,
    setSearchQuery: Function,
    navigate: Function,
  ) => {
    if (e?.target?.value?.length >= 1) {
      // navigate(`/resources/subcategories/:id`);
      setSearchQuery(e?.target?.value);
    } else {
      setSearchQuery("");
    }
  };
  return (
    <>
      {/* Sub categ section*/}
      <section className="resources-sub-categories">
        {/* <div className="reveal"> */}
        <Container>

          <div className="search-bar-full-wrap mb-2">
            <div className="search-bar">
              <div className="input-group">
                <input placeholder="Search Resource" type="text" className="form-control" onChange={(e) =>
                  handleDoctorSearch(e, setSearchQuery, navigate)
                } /><span className="icon-container"> <i className="icon xl icon-search"></i></span>
              </div>
            </div>
          </div>
          <div className="resources-cards-out-container text-center">
            <h2>{cat}</h2>

            <Row className='gy-4'>
              {downloadLoading ? (
                <CustomSpinner />

              ) : (
                <>
                  {data?.length > 0 &&
                    data?.map((item: any, i: any) => (
                      // {/* Sub Category 1 */}
                      <Col md={12}>
                        <div className="category-wrap sub-category-wrap">
                          <div className="category-wrap-inner">
                            <div className="category-heading">
                              <h4>{item?.name}</h4>
                            </div>
                            <div className="category-card-list-wrap">
                              <Row className='gy-2'>
                                {item?.subcategories?.map((subItem: any, subIndex: any) => (
                                  <Col xs={12} md={4} lg={3}>
                                    <Card className="resources-categ-card">
                                      <CardBody>
                                        <div className="categ-body-inner row">
                                          <div className="row align-items-center">
                                            <div className="content col">
                                              <embed src={subItem?.document_file_url} className='thumbnail-md-cover' type="" />
                                              <h6>{subItem?.name}</h6>

                                              {/* <small>{subItem?.type}</small> */}
                                            </div>
                                            <div className="col-auto">
                                              <div className="action-btn">
                                                <Link to={subItem?.document_file_url}><i className='icon icon-eye-open xl'></i></Link>
                                                <span title="Download"
                                                  onClick={() => handleDownloadClick(subItem)}
                                                ><i className='icon icon-download xl'></i></span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                ))}
                              </Row>
                            </div>
                          </div>
                        </div>
                      </Col>
                      
                    ))}
                     <Col md={12}>
                    <div className="load-more-wrap center">
                      {!loadingMore && downloadData?.data?.items?.length > displayedItems && (
                        <Button color="primary" className="sm" outline onClick={loadMoreItems}>Load More</Button>
                      )}
                      {loadingMore && <CustomSpinner />} {/* Display spinner while loading more */}
                    </div>
                  </Col>

                </>
              )}
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ResourcesSubCategeories;
