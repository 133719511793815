import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import { StaffSubmitHandler } from "./AddMemberMethods/AddMemberModalMethods";
import moment from "moment";
import {
  AddMemberinitialValues,
  MemberValidationSchema,
} from "./AddMemberMethods/FormikValidation";
import {
  EMPLOYEE_STATUS,
  MEMBERSHIP_TYPE,
  MEMBER_TYPE,
  PRESENT_EMPLOYMENT_STATUS,
} from "../../../Utils/Constants/selection_options";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getOpenBlock,
  getOpenCader,
  getOpenDesignationFullList,
  getOpenDistrict,
  getOpenOfficeCategory,
  getOpenOfficeFullList,
} from "../../../Api/WebsiteApi";
import TextError from "../../../Components/Errors/TextErrors";
import CustomButton from "../../../Components/Button/CustomButton";

const Register = () => {
  const [office_id, setOffice_id] = useState();
  const [office_cat, setOfficeCat] = useState();
  const [value, setValue] = useState({
    district_id: "",
    block_id: "",
    seat_id: "",
  });

  const { data: districtList }: any = useQuery(
    [`districtopen`],
    () => getOpenDistrict(),
    {
      keepPreviousData: true,
    }
  );
  const { data: blockList }: any = useQuery(
    [`block${value?.district_id}`, value?.district_id],
    () => (value?.district_id ? getOpenBlock(value?.district_id) : ""),
    {
      keepPreviousData: true,
    }
  );
  const { data: office_category }: any = useQuery(
    [`office_cate`, value?.block_id],
    () => (value?.block_id ? getOpenOfficeCategory(value?.block_id) : ""),
    {
      keepPreviousData: true,
    }
  );

  const { data: office }: any = useQuery(
    [`office${office_cat}`, office_cat, value?.block_id],
    () =>
      office_cat ? getOpenOfficeFullList(office_cat, value?.block_id) : "",
    {
      keepPreviousData: true,
    }
  );

  const { data: cader }: any = useQuery(
    [`opensea`, office_id, value?.block_id],
    () => (office_id ? getOpenCader(office_id, value?.block_id) : ""),
    {
      keepPreviousData: true,
    }
  );
  const { data: designation }: any = useQuery(
    [`designation`, value?.district_id, value?.seat_id, office_cat],
    () =>
      value?.seat_id
        ? getOpenDesignationFullList(
            value?.seat_id,
            value?.district_id,
            office_cat
          )
        : "",
    {
      keepPreviousData: true,
    }
  );

  const getCurrentDate = moment().format("YYYY-MM-DD");

  const navigate = useNavigate();

  return (
    <section className="login-wrapper">
      <Container>
        <Row className="align-items-center align-items-center p-md-5">
          <Col md={12} className="p-sm-0">
            <div className="login-form-wrap">
              <div className="heading">
                <h3>Register</h3>
              </div>

              <Formik
                validationSchema={MemberValidationSchema}
                initialValues={AddMemberinitialValues}
                enableReinitialize
                onSubmit={(values, actions) => {
                  StaffSubmitHandler(values, actions, navigate);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  isSubmitting,
                  values,
                  setFieldValue,
                  setFieldError,
                  errors,
                }) => {
                  
                  return (
                    <div className="form-wrap reg-form-wrap">
                      <Form onSubmit={handleSubmit}>
                        <Row className="gy-4">
                          <Col md={4}>
                            <Label>Membership Type</Label>
                            <Select
                              placeholder="Select Membership Type"
                              name="member_type"
                              options={MEMBERSHIP_TYPE}
                              value={
                                MEMBERSHIP_TYPE?.filter(
                                  (itm: any) =>
                                    itm?.value === values?.member_type
                                ) || ""
                              }
                              onChange={(e: any) => {
                                setFieldValue("member_type", e?.value);
                                setFieldValue("working_status", "");
                              }}
                              isLoading={false}
                              loadingMessage={() => "Fetching Membership type"}
                              noOptionsMessage={() =>
                                "Membership type appears here"
                              }
                              isSearchable={true}
                              isClearable
                            />
                            <ErrorMessage
                              name="member_type"
                              component={TextError}
                            />
                          </Col>
                          <Col md={4}>
                            <Label>Working Status</Label>
                            <Select
                              placeholder="Select Working Status"
                              name="working_status"
                              options={
                                values?.member_type === "2"
                                  ? MEMBER_TYPE?.filter(
                                      (item) =>
                                        item.value !== "1" && item.value !== "3"
                                    )
                                  : values?.member_type === "1"
                                  ? MEMBER_TYPE?.filter(
                                      (item) => item.value !== "2"
                                    )
                                  : values?.member_type === "3"
                                  ? MEMBER_TYPE?.filter(
                                      (item) =>
                                        item.value == "1" ||
                                        item.value == "2" ||
                                        item.value == "3"
                                    )
                                  : MEMBER_TYPE
                              }
                              value={
                                MEMBER_TYPE?.filter(
                                  (itm: any) =>
                                    itm?.value === values?.working_status
                                ) || ""
                              }
                              onChange={(e: any) => {
                                setFieldValue("working_status", e?.value);
                              }}
                              isLoading={false}
                              loadingMessage={() => "Fetching Working status"}
                              noOptionsMessage={() =>
                                "Working status appears here"
                              }
                              isSearchable={true}
                            />
                            <ErrorMessage
                              name="working_status"
                              component={TextError}
                            />
                          </Col>
                          {values?.member_type === "1" && (
                            <>
                              {" "}
                              <Col md={4}>
                                <Label>Employement Status</Label>
                                <Select
                                  placeholder="Select Employement Status"
                                  name="employement_status"
                                  options={PRESENT_EMPLOYMENT_STATUS}
                                  value={
                                    PRESENT_EMPLOYMENT_STATUS?.filter(
                                      (itm: any) =>
                                        itm?.value ===
                                        values?.employement_status
                                    ) || ""
                                  }
                                  onChange={(e: any) => {
                                    setFieldValue(
                                      "employement_status",
                                      e?.value
                                    );
                                  }}
                                  isLoading={false}
                                  loadingMessage={() =>
                                    "Fetching Employement status"
                                  }
                                  noOptionsMessage={() =>
                                    "Employement status appears here"
                                  }
                                  isSearchable={true}
                                  isClearable
                                />
                                <ErrorMessage
                                  name="employement_status"
                                  component={TextError}
                                />
                              </Col>
                            </>
                          )}
                          {values?.member_type === "3" &&
                            values?.working_status === "1" && (
                              <>
                                {" "}
                                <Col md={4}>
                                  <Label>Employement Status</Label>
                                  <Select
                                    placeholder="Select Employement Status"
                                    name="employement_status"
                                    options={PRESENT_EMPLOYMENT_STATUS}
                                    value={
                                      PRESENT_EMPLOYMENT_STATUS?.filter(
                                        (itm: any) =>
                                          itm?.value ===
                                          values?.employement_status
                                      ) || ""
                                    }
                                    onChange={(e: any) => {
                                      setFieldValue(
                                        "employement_status",
                                        e?.value
                                      );
                                    }}
                                    isLoading={false}
                                    loadingMessage={() =>
                                      "Fetching Employement status"
                                    }
                                    noOptionsMessage={() =>
                                      "Employement status appears here"
                                    }
                                    isSearchable={true}
                                    isClearable
                                  />
                                  <ErrorMessage
                                    name="employement_status"
                                    component={TextError}
                                  />
                                </Col>
                              </>
                            )}

                          <Col md={4}>
                            <Label>Pension Scheme</Label>
                            <Select
                              placeholder="Select Pension Scheme"
                              name="present_status"
                              options={EMPLOYEE_STATUS}
                              value={
                                EMPLOYEE_STATUS?.filter(
                                  (itm: any) =>
                                    itm?.value === values?.present_status
                                ) || ""
                              }
                              onChange={(e: any) => {
                                setFieldValue("present_status", e?.value);
                              }}
                              isLoading={false}
                              loadingMessage={() => "Fetching Pension scheme"}
                              noOptionsMessage={() =>
                                "Pension scheme appears here"
                              }
                              isSearchable={true}
                              isClearable
                            />
                            <ErrorMessage
                              name="present_status"
                              component={TextError}
                            />
                          </Col>

                          <Col md={4}>
                            <Label>District</Label>
                            <Select
                              placeholder="Select District"
                              name="district_id"
                              options={districtList}
                              value={
                                districtList?.filter(
                                  (itm: any) =>
                                    itm?.value === values?.district_id
                                ) || ""
                              }
                              onChange={(e: any) => {
                                setFieldValue("district_id", e?.value);
                                setValue({
                                  ...value,
                                  district_id: e?.value,
                                });
                              }}
                              isLoading={false}
                              loadingMessage={() => "Fetching District"}
                              noOptionsMessage={() => "District appears here"}
                              isSearchable={true}
                              isClearable
                            />
                            <ErrorMessage
                              name="district_id"
                              component={TextError}
                            />
                          </Col>
                          {values?.member_type === "1" && (
                            <>
                              {values?.working_status === "3" ||
                              values?.employement_status !== "1" ? null : (
                                <>
                                  <Col md={4}>
                                    <Label>Block</Label>
                                    <Select
                                      placeholder="Select Block"
                                      name="block_id"
                                      options={blockList ? blockList : []}
                                      value={
                                        blockList
                                          ? blockList?.filter(
                                              (itm: any) =>
                                                itm?.value === values?.block_id
                                            )
                                          : []
                                      }
                                      onChange={(e: any) => {
                                        setFieldValue("block_id", e?.value);
                                        setValue({
                                          ...value,
                                          block_id: e?.value,
                                        });
                                      }}
                                      isLoading={false}
                                      loadingMessage={() => "Fetching Block"}
                                      noOptionsMessage={() =>
                                        "Block appears here"
                                      }
                                      isSearchable={true}
                                      isClearable
                                    />
                                    <ErrorMessage
                                      name="block_id"
                                      component={TextError}
                                    />
                                  </Col>
                                </>
                              )}
                            </>
                          )}
                          {values?.member_type === "1" && (
                            <>
                              {values?.working_status === "3" ||
                              values?.employement_status !== "1" ? null : (
                                <>
                                  <Col md={4}>
                                    <Label>Office Category</Label>
                                    <Select
                                      placeholder="Select Office"
                                      name="office_category"
                                      options={
                                        office_category ? office_category : []
                                      }
                                      value={
                                        office_category?.length !== 0
                                          ? office_category?.filter(
                                              (itm: any) =>
                                                itm?.value ===
                                                values?.office_category
                                            )
                                          : []
                                      }
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          "office_category",
                                          e?.value
                                        );
                                        setOfficeCat(e?.value);
                                      }}
                                      isLoading={false}
                                      loadingMessage={() =>
                                        "Fetching office Category"
                                      }
                                      noOptionsMessage={() =>
                                        "office Category appears here"
                                      }
                                      isSearchable={true}
                                      isClearable
                                    />
                                    <ErrorMessage
                                      name="office_category"
                                      component={TextError}
                                    />
                                  </Col>
                                  <Col md={4}>
                                    <Label>Name of Office</Label>
                                    <Select
                                      placeholder="Select Office"
                                      name="office"
                                      options={office ? office : []}
                                      value={
                                        office?.length !== 0
                                          ? office?.filter(
                                              (itm: any) =>
                                                itm?.value === values?.office
                                            )
                                          : []
                                      }
                                      onChange={(e: any) => {
                                        setFieldValue("office", e?.value);
                                        setOffice_id(e?.value);
                                      }}
                                      isLoading={false}
                                      loadingMessage={() => "Fetching office"}
                                      noOptionsMessage={() =>
                                        "office appears here"
                                      }
                                      isSearchable={true}
                                      isClearable
                                    />
                                    <ErrorMessage
                                      name="office"
                                      component={TextError}
                                    />
                                  </Col>

                                  <Col md={4}>
                                    <Label>Cadre</Label>
                                    <Select
                                      placeholder="Select cadre"
                                      name="seat_id"
                                      options={cader ? cader : []}
                                      value={
                                        cader?.length !== 0
                                          ? cader?.filter(
                                              (itm: any) =>
                                                itm?.value === values?.seat_id
                                            )
                                          : []
                                      }
                                      onChange={(e: any) => {
                                        setFieldValue("seat_id", e.value);
                                        setValue({
                                          ...value,
                                          seat_id: e?.value,
                                        });
                                      }}
                                      isLoading={false}
                                      loadingMessage={() => "Fetching cadre"}
                                      noOptionsMessage={() =>
                                        "No options available"
                                      }
                                      isSearchable={true}
                                      isClearable
                                    />
                                    <ErrorMessage
                                      name="seat_id"
                                      component={TextError}
                                    />
                                  </Col>
                                  <Col md={4}>
                                    <Label>Seat/Designation</Label>
                                    <Select
                                      placeholder="Select Seat/Designation"
                                      name="designation"
                                      options={designation ? designation : []}
                                      value={
                                        designation?.length !== 0
                                          ? designation?.filter(
                                              (itm: any) =>
                                                itm?.value ===
                                                values?.designation
                                            )
                                          : []
                                      }
                                      onChange={(e: any) => {
                                        setFieldValue("designation", e.value);
                                      }}
                                      isLoading={false}
                                      loadingMessage={() =>
                                        "Fetching Seat/Designation"
                                      }
                                      noOptionsMessage={() =>
                                        "No options available"
                                      }
                                      isSearchable={true}
                                      isClearable
                                    />
                                    <ErrorMessage
                                      name="seat_id"
                                      component={TextError}
                                    />
                                  </Col>
                                </>
                              )}
                            </>
                          )}

                          {values?.member_type === "3" &&
                            values?.working_status === "1" && (
                              <>
                                {values?.employement_status !== "1" ? null : (
                                  <>
                                    {" "}
                                    <Col md={4}>
                                      <Label>Block</Label>
                                      <Select
                                        placeholder="Select Block"
                                        name="block_id"
                                        options={blockList ? blockList : []}
                                        value={
                                          blockList
                                            ? blockList?.filter(
                                                (itm: any) =>
                                                  itm?.value ===
                                                  values?.block_id
                                              )
                                            : []
                                        }
                                        onChange={(e: any) => {
                                          setFieldValue("block_id", e?.value);
                                          setValue({
                                            ...value,
                                            block_id: e?.value,
                                          });
                                        }}
                                        isLoading={false}
                                        loadingMessage={() => "Fetching Block"}
                                        noOptionsMessage={() =>
                                          "Block appears here"
                                        }
                                        isSearchable={true}
                                        isClearable
                                      />
                                      <ErrorMessage
                                        name="block_id"
                                        component={TextError}
                                      />
                                    </Col>{" "}
                                    <Col md={4}>
                                      <Label>Office Category</Label>
                                      <Select
                                        placeholder="Select Office"
                                        name="office_category"
                                        options={
                                          office_category ? office_category : []
                                        }
                                        value={
                                          office_category?.length !== 0
                                            ? office_category?.filter(
                                                (itm: any) =>
                                                  itm?.value ===
                                                  values?.office_category
                                              )
                                            : []
                                        }
                                        onChange={(e: any) => {
                                          setFieldValue(
                                            "office_category",
                                            e?.value
                                          );
                                          setOfficeCat(e?.value);
                                        }}
                                        isLoading={false}
                                        loadingMessage={() =>
                                          "Fetching office Category"
                                        }
                                        noOptionsMessage={() =>
                                          "office Category appears here"
                                        }
                                        isSearchable={true}
                                        isClearable
                                      />
                                      <ErrorMessage
                                        name="office_category"
                                        component={TextError}
                                      />
                                    </Col>
                                    <Col md={4}>
                                      <Label>Name of Office</Label>
                                      <Select
                                        placeholder="Select Office"
                                        name="office"
                                        options={office ? office : []}
                                        value={
                                          office?.length !== 0
                                            ? office?.filter(
                                                (itm: any) =>
                                                  itm?.value === values?.office
                                              )
                                            : []
                                        }
                                        onChange={(e: any) => {
                                          setFieldValue("office", e?.value);
                                          setOffice_id(e?.value);
                                        }}
                                        isLoading={false}
                                        loadingMessage={() => "Fetching office"}
                                        noOptionsMessage={() =>
                                          "office appears here"
                                        }
                                        isSearchable={true}
                                        isClearable
                                      />
                                      <ErrorMessage
                                        name="office"
                                        component={TextError}
                                      />
                                    </Col>
                                    <Col md={4}>
                                      <Label>Cadre</Label>
                                      <Select
                                        placeholder="Select cadre"
                                        name="seat_id"
                                        options={cader ? cader : []}
                                        value={
                                          cader?.length !== 0
                                            ? cader?.filter(
                                                (itm: any) =>
                                                  itm?.value === values?.seat_id
                                              )
                                            : []
                                        }
                                        onChange={(e: any) => {
                                          setFieldValue("seat_id", e.value);
                                          setValue({
                                            ...value,
                                            seat_id: e?.value,
                                          });
                                        }}
                                        isLoading={false}
                                        loadingMessage={() => "Fetching cadre"}
                                        noOptionsMessage={() =>
                                          "No options available"
                                        }
                                        isSearchable={true}
                                        isClearable
                                      />
                                      <ErrorMessage
                                        name="seat_id"
                                        component={TextError}
                                      />
                                    </Col>
                                    <Col md={4}>
                                      <Label>Seat/Designation</Label>
                                      <Select
                                        placeholder="Select Seat/Designation"
                                        name="designation"
                                        options={designation ? designation : []}
                                        value={
                                          designation?.length !== 0
                                            ? designation?.filter(
                                                (itm: any) =>
                                                  itm?.value ===
                                                  values?.designation
                                              )
                                            : []
                                        }
                                        onChange={(e: any) => {
                                          setFieldValue("designation", e.value);
                                        }}
                                        isLoading={false}
                                        loadingMessage={() =>
                                          "Fetching Seat/Designation"
                                        }
                                        noOptionsMessage={() =>
                                          "No options available"
                                        }
                                        isSearchable={true}
                                        isClearable
                                      />
                                      <ErrorMessage
                                        name="seat_id"
                                        component={TextError}
                                      />
                                    </Col>
                                  </>
                                )}
                              </>
                            )}

                          <Col md={4}>
                            <Label>First Name</Label>
                            <Input
                              type="text"
                              name="member_f_name"
                              value={values?.member_f_name}
                              onChange={handleChange}
                              placeholder="First Name"
                            />
                            <ErrorMessage
                              name="member_f_name"
                              component={TextError}
                            />
                          </Col>
                          <Col md={4}>
                            <Label>Last Name</Label>
                            <Input
                              type="text"
                              name="member_l_name"
                              value={values?.member_l_name}
                              onChange={handleChange}
                              placeholder="Last Name"
                            />
                            <ErrorMessage
                              name="member_l_name"
                              component={TextError}
                            />
                          </Col>

                          <Col md={4}>
                            <Label>Personal Mobile Number</Label>
                            <Input
                              type="tel"
                              name="phone_no"
                              value={values?.phone_no}
                              onChange={handleChange}
                              placeholder="Mobile Number"
                            />
                            <ErrorMessage
                              name="phone_no"
                              component={TextError}
                            />
                          </Col>
                          <Col md={4}>
                            <Label>Personal Whatsapp Number</Label>
                            <Input
                              type="tel"
                              name="member_whatsapp_number"
                              value={values?.member_whatsapp_number}
                              onChange={handleChange}
                              placeholder="Whatsapp Number"
                            />
                            <ErrorMessage
                              name="member_whatsapp_number"
                              component={TextError}
                            />
                          </Col>
                          <Col md={4}>
                            <Label>Personal Email</Label>
                            <Input
                              type="email"
                              name="email"
                              value={values?.email}
                              onChange={handleChange}
                              placeholder="Email"
                            />
                            <ErrorMessage name="email" component={TextError} />
                          </Col>
                          <Col md={4}>
                            <Label>Date of Birth</Label>
                            <Input
                              type="date"
                              name="member_date_of_birth"
                              value={values?.member_date_of_birth}
                              onChange={handleChange}
                              placeholder=""
                              max={getCurrentDate}
                            />
                            <ErrorMessage
                              name="member_date_of_birth"
                              component={TextError}
                            />
                          </Col>

                          <Col md={4}>
                            <Label>PEN Number</Label>
                            <Input
                              type="number"
                              name="pen_no"
                              value={values?.pen_no}
                              onChange={handleChange}
                              onWheel={(e) => e.currentTarget.blur()}
                              placeholder="PEN Number"
                            />
                            <ErrorMessage name="pen_no" component={TextError} />
                          </Col>

                          <Col md={4}>
                            <Label>Date of Joining</Label>
                            <Input
                              type="date"
                              name="date_of_joining"
                              value={values?.date_of_joining}
                              onChange={handleChange}
                              placeholder=""
                              max={getCurrentDate}
                            />
                            <ErrorMessage
                              name="date_of_joining"
                              component={TextError}
                            />
                          </Col>

                          {values?.member_type !== "1" && (
                            <>
                              <Col md={4}>
                                <Label>Date of Retirement</Label>
                                <Input
                                  type="date"
                                  name="date_of_retirement"
                                  value={values?.date_of_retirement}
                                  onChange={handleChange}
                                  placeholder=""
                                  min={values?.date_of_joining}
                                />
                                <ErrorMessage
                                  name="date_of_retirement"
                                  component={TextError}
                                />
                              </Col>
                            </>
                          )}
                          {values?.member_type === "3" && (
                            <>
                              <Col md={4}>
                                <Label>Membership Certificate</Label>
                                <Input
                                  type="file"
                                  onChange={(e: any) => {
                                    setFieldValue(
                                      "membership_document",
                                      e.target.files[0]
                                    );
                                  }}
                                  name="membership_document"
                                  placeholder="Membership Certificate"
                                />
                                <ErrorMessage
                                  name="membership_document"
                                  component={TextError}
                                />
                              </Col>
                            </>
                          )}
                          <Col md={12}>
                            <div className="checkbox-wrap">
                              <div className="d-flex">
                                <Input
                                  type="checkbox"
                                  onChange={(e: any) => {
                                    setFieldValue("check", e.target.checked);
                                  }}
                                  name="check"
                                  checked={values?.check}
                                />
                              </div>
                              <Label>
                                {" "}
                                I do hereby agree to abide by the By-laws of the
                                Association of Agricultural Officers Kerala, and
                                to act accordingly to the decisions of the
                                Association.
                              </Label>
                              <ErrorMessage
                                name="check"
                                component={TextError}
                              />
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div className="submit-btn-wrap">
                              <CustomButton
                                isLoading={isSubmitting}
                                label="Submit"
                                color="primary"
                                className="sm submit-btn"
                              />
                            </div>
                          </Col>

                          <Col md={12}>
                            <div className="reg-login">
                              <Link to="/login">Login to your account</Link>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Register;
