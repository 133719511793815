import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { api } from "../../../../Api/Api";

interface DownloadViewModalProps {
  data: any;
  setIsOpen: any;
  download: boolean;
  view: boolean;
  all_data: any;
}

const DownloadViewModal: React.FC<DownloadViewModalProps> = ({
  data,
  setIsOpen,
  download,
  view,
  all_data,
}) => {
  const [pdfUrl, setPdfUrl] = useState<string | undefined>(data);
  useEffect(() => {
    if (pdfUrl && view) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = data?.name || "document";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsOpen(false);
    }
  }, [pdfUrl, setIsOpen, view]);

  const downloadPdf = async () => {
    if (pdfUrl && download) {
      const userId = localStorage.getItem("USER_ID");

      try {
        if (userId) {
          // Logging download
          await api.post(
            "/open/download-log",
            {
              user_id: userId,
              downloadcategory_id: data?.id,
            },
            true
          );
          console.log("Download logged successfully");

          // Download PDF
          const response = await fetch(pdfUrl);
          const blob = await response.blob();
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "document.pdf";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setIsOpen(false);
        } else {
          console.error("User ID not found in local storage");
        }
      } catch (error) {
        console.error("Error downloading PDF:", error);
      }
    }
  };

  return (
    <div className="pdf-download-modal">
      {pdfUrl ? (
        <>
          {view ? (
            <p>Are You Ready To View?</p>
          ) : (
            <p>Do You Want to Download?</p>
          )}
          <div className="button-wrap">
            {download && (
              <Button
                onClick={downloadPdf}
                color="primary"
                className="sm btn btn-primary"
              >
                Download
              </Button>
            )}
            <Button
              onClick={() => setIsOpen(false)}
              color="danger"
              outline
              className="sm btn btn-danger"
            >
              Cancel
            </Button>
          </div>
        </>
      ) : (
        <p>No PDF available for download or view.</p>
      )}
    </div>
  );
};

export default DownloadViewModal;
