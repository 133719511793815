import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";

const RegisterSuccess = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="errorpage">
        <div className="error-text-wrap text-center">
          <h2 className="error-text">Congratulations 🎉</h2>
          <h2 className="error-text">
            Registration Completed 
          </h2>
          <p>Your Profile is Under Verification. On successfull Verification the link for membership payment will be sent to the registered email id.</p>
          <Button
            outline
            color="white"
            className="sm my-3"
            onClick={() => navigate("/")}
          >
            Back to home
          </Button>
        </div>
      </div>
    </>
  );
};

export default RegisterSuccess;
