import { ErrorMessage, Formik } from "formik";
import { Container, Row, Col, Form, Input } from "reactstrap";
import {
  ForgotPasswordEmailValidaionSchema,
  handleEmailSubmit,
} from "./ForgotPasswordMethods";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../Components/Button/CustomButton";
import TextError from "../../../Components/Errors/TextErrors";

const ForgotPasswordIndex = () => {
  const navigate = useNavigate();

  return (
    <section className="login-wrapper">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col md={6}>
            <div className="login-form-wrap">
              <div className="heading">
                <h3>Reset Your Password</h3>
              </div>
              <Formik
                validationSchema={ForgotPasswordEmailValidaionSchema}
                initialValues={{ email: "" }}
                onSubmit={(values: any, actions: any) => {
                  handleEmailSubmit(values, actions, navigate);
                }}
              >
                {({
                  values,
                  isSubmitting,
                  handleChange,
                  handleSubmit,
                }: any) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <div className="form-inner-wrap">
                        <Row className="g-3">
                          <Col lg={12}>
                            <Input
                              type="text"
                              name="email"
                              value={values?.email}
                              onChange={handleChange}
                              placeholder="Email address"
                            />
                            <ErrorMessage name="email" component={TextError} />
                          </Col>
                          <Col lg={12}>
                            <CustomButton
                              isLoading={isSubmitting}
                              label="Continue"
                              color="primary"
                              className="sm submit-btn w-100"
                            />
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ForgotPasswordIndex;
