import React from "react";
import { Container, Row, Col, List } from "reactstrap";

const DownloadListBanner = (props: any) => {
  return (
    <>
      <section className="page-banner">
        <div className="page-banner-wrap">
          <Container>
            <Row>
              <Col lg={12} xs={12}>
                <div className="heading-wrap">
                  <h1>Downloads</h1>
                </div>
                <nav aria-label="breadcrumb">
                  <List className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Downloads
                    </li>
                    {/* <li className="breadcrumb-item active" aria-current="page">
                      {props?.doctor_name}
                    </li> */}
                  </List>
                </nav>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default DownloadListBanner;
