import { useState } from "react";
import { Container, Col, Row } from "reactstrap";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import HistoryModal from "./History/HistoryModal";
import {
  getvisionandmission,
  getvisionandmissionphoto,
} from "../../../../Api/WebsiteApi";
import { useQuery } from "react-query";

const History = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { data: visionandmissionList }: any = useQuery(
    ["visionandmission"],
    () => getvisionandmission(),
    { keepPreviousData: true }
  );

  const { data: visionandmissionphoto }: any = useQuery(
    ["visionandmissionphoto"],
    () => getvisionandmissionphoto(),
    { keepPreviousData: true }
  );

  return (
    <>
      <ModalContainer
        title="History of AOAOK"
        isOpen={isOpen}
        setIsopen={setIsOpen}
      >
        <HistoryModal />
      </ModalContainer>

      <section className="objective-wrap">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={6}>
              <div className="image-wrap">
                <img src={visionandmissionphoto?.[0]?.image} alt="img" />
              </div>
            </Col>
            <Col md={6}>
              <div className="objective-inner-wrap">
                {visionandmissionList?.map((item: any, index: number) => (
                  <div
                    key={index}
                    className={`objective-inner bg-${
                      index % 2 === 0 ? "secondary" : "tertiary"
                    } reveal`}
                  >
                    <h6>{item?.title}</h6>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item?.body,
                      }}
                    ></div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default History;
