import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody,
  CardText,
  CardTitle,
  List,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { getnews } from "../../../Api/WebsiteApi";
import { useQuery } from "react-query";


const News: React.FC = () => {

  const { id } = useParams<{ id: string }>();
  const { data: news, newsisLoading: newsLoading }: any = useQuery(["news", id], () => getnews(id),
    { keepPreviousData: true }
  );

  return (
    <>
      <section className="page-banner">
        <div className="page-banner-wrap">
          <Container>
            <Row>
              <Col lg={12} xs={12}>
                <div className="heading-wrap">
                  <h1>News</h1>
                </div>
                <nav aria-label="breadcrumb">
                  <List className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      <Link to={`/news/${id}`}> News</Link>
                    </li>
                  </List>
                </nav>
              </Col>
            </Row>
          </Container>
        </div>
      </section >

      <section className="news-outer-wrap">
        <div className="news-content-wrap reveal">
          <Container>
            <div className="heading-wrap text-start">
              <h4 className='heading'>News</h4>
            </div>
            <div className="news-list-wrap">
              <Col lg={12} md={12} sm={12}>
                <h4>{news?.data[0]?.title}</h4>
                <p>{news?.data[0]?.content}</p>
              </Col>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default News;

