import { clearData } from "../../lib/keystorage";
import { USER_ROLES } from "../Constants/AuthenticationConstants";

function processJsonResponse(data: any, fields = ["id", "name"]) {
  let dataProcessedResponse = data?.map(function (obj: any) {
    obj["value"] = obj[fields[0]];
    obj["label"] = obj[fields[1]];
    delete obj[fields[0]];
    delete obj[fields[1]];
    return obj;
  });
  return dataProcessedResponse;
}

function shift_time(week_day: any) {
  return week_day === 7
    ? "Sunday"
    : week_day === 1
    ? "Monday"
    : week_day === 2
    ? "Tuesday"
    : week_day === 3
    ? "Wednesday"
    : week_day === 4
    ? "Thursday"
    : week_day === 5
    ? "Friday"
    : week_day === 6
    ? "Saturday"
    : "";
}
const AuthChecker = (navigate: any, path: string) => {
  if (localStorage.getItem("AUTH_TOKEN") !== null) {
    if (localStorage.getItem("ROLE") === USER_ROLES?.ADMIN) {
      navigate("/", { replace: true });
    } else if (localStorage.getItem("ROLE") === USER_ROLES?.MEMBER) {
      navigate("/", { replace: true });
    } else if (localStorage.getItem("ROLE") === USER_ROLES?.ASSOCIATIVE) {
      navigate("/", { replace: true });
    }
  } else {
    navigate(path, { replace: true });
  }
};
export const capitalizeFirstLetterOfEachWord = (input: string): string => {
  return input
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
function FirstLetterUpper(text: any) {
  return text?.charAt(0).toUpperCase() + text?.slice(1);
}

const setValidationErrors = (errors: any, actions: any) => {
  Object?.keys(errors)?.map((fieldName) =>
    errors?.[fieldName]?.map((errorMessage: string, index: number) =>
      actions?.setFieldError(fieldName, errorMessage)
    )
  );
};
const Logout = async (navigate: Function) => {
  await clearData();
  navigate("/");
};
const ScrollToContainerTop = (ref: any) => {
  if (ref?.current) {
    ref?.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
  }
};

const setValidationErrorsAll = (errors: any, actions: any) => {
  errors?.forEach((error: any) => {
    const fieldName = Object.keys(error)[0];
    const errorMessage = Object.values(error)[0];
    actions?.setFieldError(fieldName, errorMessage);
  });
};

// Sun Oct 01 2023 00:00:00 GMT+0530 (India Standard Time) to yyyy-mm-dd format
const ISODateToDateConverter = (date: string) => {
  const inputDate = new Date(date);
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month (months are zero-based) and pad with 0 if necessary
  const day = String(inputDate.getDate()).padStart(2, "0"); // Pad with 0 if necessary
  // Create the "yyyy-mm-dd" formatted string
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

const ISODateToDateConverter2 = (date: any) => {
  const inputDate = new Date(date);
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month (months are zero-based) and pad with 0 if necessary
  const day = String(inputDate.getDate()).padStart(2, "0"); // Pad with 0 if necessary
  // Create the "yyyy-mm-dd" formatted string
  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
};

function formatDateTimeForInput(dateTimeString: any) {
  const dateObject = new Date(dateTimeString);

  // Check if the dateObject is a valid date
  if (isNaN(dateObject.getTime())) {
    return "Invalid Date";
  }

  // Use toISOString to get UTC representation
  const formattedDateTime = dateObject.toISOString().slice(0, 10);

  return formattedDateTime;
}

export {
  processJsonResponse,
  shift_time,
  AuthChecker,
  setValidationErrors,
  Logout,
  ScrollToContainerTop,
  setValidationErrorsAll,
  ISODateToDateConverter,
  ISODateToDateConverter2,
  formatDateTimeForInput,
  FirstLetterUpper,
};
