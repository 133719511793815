import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getMemberid } from "../../../../Api/WebsiteApi";
import MemberInfoCard from "./MemberInfoCards";
import MemberListBanner from "./MemberListBanner";
import MemberProfile from "./MemberProfile";
import Member from "../../Home/Containers/Member";
import CustomSpinner from "../../../../Components/Button/CustomSpinner";
import { Row } from "reactstrap";

const MemberContainer: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>();

  const {
    data: memberList,
    error: memberError,
    isLoading: memberLoading,
  } = useQuery(["memberProfile", id], () => getMemberid(id));

  return (
    <>
      {localStorage.getItem("ROLE") === null ? null : (
        <>
          <MemberListBanner member_name={memberList?.user?.first_name} />
          {memberLoading ? (
            <div className="align-items-center justify-content-center">
              <CustomSpinner />
            </div>
          ) : memberList ? (
            <>
              <MemberProfile />
              <MemberInfoCard />
            </>
          ) : (
            <div className="member-list-outer-wrap">
              <Row className="align-items-center justify-content-center mt-5">
                Member Not Updated The Profile
              </Row>
            </div>
          )}
          <Member />
        </>
      )}
    </>
  );
};
export default MemberContainer;
