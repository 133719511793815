import React, { useEffect } from 'react';
import PrivacyPolicyBanner from './Containers/PrivacyPolicyBanner';
import PrivacyPolicyList from './Containers/PrivacyPolicyList';

const PrivacyPolicy:React.FC = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	  }, [])
	return (
		<>
			<PrivacyPolicyBanner />
			<PrivacyPolicyList />
		</>
	);
};

export default PrivacyPolicy;