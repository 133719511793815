import HistoryBanner from "./HistoryBanner";
import HistoryModal from "./HistoryModal";

const HistoryIndex = () => {
  return (
    <>
      <HistoryBanner />
      <HistoryModal />
    </>
  );
};
export default HistoryIndex;
