import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Row,
  InputGroup,
  Input,
  Table,
  Button,
} from "reactstrap";
import { getBlock, getDistrict, getMembers } from "../../../../Api/WebsiteApi";
import CustomPagination from "../../../../Components/Pagination/Pagination";
import {
  FirstLetterUpper,
  ScrollToContainerTop,
} from "../../../../Utils/helpers/helpers";
import Select from "react-select";

interface Office {
  id: number;
  user: { first_name: string; email: string };
  district: { name: string };
  designationMeta: { name: string };
  email: string;
  phone_no: string;
  linkedin_profile: string;
  twitter_profile: string;
  instagram_profile: string;
}

const MemberList: React.FC = () => {
  const myDivRef: any = useRef(null);
  const [value, setValue] = useState({
    district_id: "",
    block_id: "",
  });
  const navigate = useNavigate();
  const { currentPage = "1" } = useParams();

  const setCurrentPageStaff = (navigate: Function, pageNo: any) => {
    navigate(`/members/${pageNo}`);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const {
    data: memberList,
    isLoading: memberListLoading,
    isError,
  }: any = useQuery(
    ["Members", currentPage, searchTerm, value?.district_id, value?.block_id],
    () =>
      getMembers(currentPage, searchTerm, value?.district_id, value?.block_id),
    {
      keepPreviousData: true,
    }
  );


  const [click, setClick] = useState(true);

  const handleClick = () => {
    setClick((prevClick) => !prevClick);
  };

  const { data: districtData }: any = useQuery(
    ["District"],
    () => getDistrict(),
    {
      keepPreviousData: true,
    }
  );

  const { data: blockData }: any = useQuery(
    ["Block", value?.district_id],
    () => getBlock(value?.district_id),
    {
      keepPreviousData: true,
    }
  );
  const handleChange = (e: any, field_name: any) => {
    setValue((prevValue) => ({
      ...prevValue,
      [`${field_name}_id`]: e,
    }));
  };

  const handleOfficeSearch = (
    e: any,
    setSearchTerm: Function,
    navigate: Function
  ) => {
    if (e?.target?.value?.length >= 1) {
      navigate(`/members/1`);
      setSearchTerm(e?.target?.value);
    } else {
      setSearchTerm("");
    }
  };

  useEffect(() => {
    // Scroll to the top when the component mounts
    if (myDivRef.current) {
      myDivRef.current.scrollTop = 0;
    }
  }, []);

  return (
    <>
      <section className="member-outer-wrap">
        {/* <div className="reveal"> */}
        <Container ref={myDivRef}>
          <div className="search-bar-full-wrap mb-5">
            <div className="search-bar">
              <InputGroup>
                <Input
                  type="text"
                  placeholder="Search Members"
                  onChange={(e) =>
                    handleOfficeSearch(e, setSearchTerm, navigate)
                  }
                />
                <span className="icon-container">
                  <i className="icon xl icon-search"></i>
                </span>
              </InputGroup>
            </div>

            <div className="right-side-wrap">
              <div className="filter-wrap">
                <div>
                  <Select
                    placeholder="Select District"
                    options={districtData}
                    value={
                      districtData?.filter(
                        (itm: any) => itm?.value === value?.district_id
                      ) || ""
                    }
                    onChange={(e: any) => handleChange(e?.value, "district")}
                    name="district_id"
                    isLoading={false}
                    loadingMessage={() => "Fetching District"}
                    noOptionsMessage={() => "District appears here"}
                    isSearchable={true}
                    isClearable
                  />
                </div>

                <div>
                  <Select
                    placeholder="Select Block"
                    options={blockData}
                    value={blockData?.filter((itm: any) => itm?.value === value?.block_id) || ""}
                    onChange={(e: any) => handleChange(e?.value, "block")}
                    name="block_id"
                    isLoading={false}
                    loadingMessage={() => "Fetching Block"}
                    noOptionsMessage={() => "Bloks appears here"}
                    isSearchable={true}
                    isClearable
                  />
                </div>

                <div className="button-wrap">
                  <Button
                    color="primary"
                    className="sm"
                    onClick={() => {
                      setValue({
                        district_id: "",
                        block_id: "",
                      });
                      setSearchTerm("");
                    }}
                  >
                    Reset
                  </Button>
                </div>
                <div className="icon-wrap">
                  <i
                    className={`icon xl ${click ? "icon-grid" : "icon-list"}`}
                    onClick={handleClick}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <Container>
          {click ? (
            !memberListLoading && (
              <>
                <div className="member-list-outer-wrap">
                  <Row className="align-items-center justify-content-center">
                    {memberList?.data?.items?.map((item: any) => (
                      <Col sm={12} md={6} lg={2} key={item.id}>
                        <Card className="card-wrapper">
                          <Link to={`/members-profile/${item?.member_id}`}>
                            <div className="thumbnail-wrap">
                              <img
                                src={item?.member_profile_img ? item?.member_profile_img : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"}
                                alt="profile"
                              />
                              <div className="name-wrap">
                                <h6 className="name">
                                  {FirstLetterUpper(item?.user_first_name)}{" "}
                                  {FirstLetterUpper(
                                    item?.user_last_name
                                      ? item?.user_last_name
                                      : ""
                                  )}
                                </h6>
                                <p>{item?.designationMeta_name}</p>
                              </div>

                              <div className="icon-container hover-class">
                                <div className="icon-container-inner">
                                  <Link to={item?.member_linkedin_profile}>
                                    <i className="icon xl icon-linkedin"></i>
                                  </Link>
                                  <Link to={item?.member_instagram_profile}>
                                    <i className="icon xl icon-facebook"></i>
                                  </Link>
                                  <Link to={item?.member_twitter_profile}>
                                    <i className="icon xl icon-x"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              </>
            )
          ) : (
            <div className="table-outer-wrap">
              <div className="table-outer">
                <Table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>District</th>
                      <th>Designation</th>
                      <th>Mobile</th>
                      <th>Email</th>
                      <th>Social Media</th>
                    </tr>
                  </thead>
                  <tbody>
                    {memberList?.data?.items?.map((office: any) => (
                      <tr key={office.id}>
                        <>
                          <td>
                            <span className="mobile-heading">Name</span>
                            <Link
                              to={`/members-profile/${office?.id}`}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              <span className="table-data">
                                {office?.user_first_name}
                              </span>
                            </Link>
                          </td>
                          <td>
                            <span className="mobile-heading">District</span>
                            <span className="table-data">
                              {office?.district_name}
                            </span>
                          </td>
                          <td>
                            <span className="mobile-heading">
                              Designation
                            </span>
                            <span className="table-data">
                              {office?.designationMeta_name}
                            </span>
                          </td>
                          <td>
                            <span className="mobile-heading">Mobile</span>
                            <span className="table-data">
                              {office?.member_phone_no}
                            </span>
                          </td>
                          <td>
                            <span className="mobile-heading">Email</span>
                            <span className="table-data">
                              {office?.user_email}
                            </span>
                          </td>
                          <td>
                            <span className="mobile-heading">
                              Social Media
                            </span>
                            <span className="table-data">
                              <Link to={office?.member_linkedin_profile}>
                                <i className="icon xl icon-linkedin-2"></i>
                              </Link>
                              <Link to={office?.member_instagram_profile}>
                                <i className="icon xl icon-x-2"></i>
                              </Link>
                              <Link to={office?.member_twitter_profile}>
                                <i className="icon xl icon-fb"></i>
                              </Link>
                            </span>
                          </td>
                        </>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          )}

          {memberList?.meta?.totalItems !== 0 && (
            <div className="pagination">
              <CustomPagination
                totalItems={memberList?.data?.meta?.totalItems}
                itemsPerPage={memberList?.data?.meta?.itemsPerPage}
                activePage={memberList?.data?.meta?.currentPage}
                disableScrollAfterPageChange
                setCurrentPage={(pageNo: string) => {
                  ScrollToContainerTop(myDivRef);
                  setCurrentPageStaff(navigate, pageNo);
                }}
              />
            </div>
          )}
        </Container>
        {/* </div> */}
      </section>
    </>
  );
};

export default MemberList;
