import React, { useEffect } from 'react'
import BlogListBanner from './Containers/BlogListBanner'
import BlogList from './Containers/BlogList'


const BlogIndex:React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <BlogListBanner/>
    <BlogList/>
    </>
  )
}

export default BlogIndex
