import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Container, InputGroup, Input, Table, Button } from "reactstrap";
import { getAccomodation, getDistrict } from "../../../../Api/WebsiteApi";
import CustomPagination from "../../../../Components/Pagination/Pagination";
import { ScrollToContainerTop } from "../../../../Utils/helpers/helpers";
import Select from "react-select";

interface Office {
  id: number;
  OfficeAccomodation: { name: string, district: { name: string }; };
  alternative_phone_no: string,
  phone_no: string;
  room_occupied: string;
  no_of_rooms: number;
}

const MemberList: React.FC = () => {
  const myDivRef = useRef(null);
  const navigate = useNavigate();

  const setCurrentPageStaff = (navigate: Function, pageNo: any) => {
    navigate(`/accomodation/${pageNo}`);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const [value, setValue] = useState({
    district_id: "",

  });

  const {
    data: accomodation,
    isLoading: accomodationListLoading,
    isError,
  }: any = useQuery(["accomodation", searchTerm, value?.district_id], () => getAccomodation(searchTerm, value?.district_id), {
    keepPreviousData: true,
  });



  const handleChange = (e: any, field_name: string) => {
    if (field_name === "district") {
      setValue({
        ...value,
        district_id: e,
      });
    }
  };

  const { data: districtData }: any = useQuery(
    ["District"],
    () => getDistrict(),
    {
      keepPreviousData: true,
    }
  );

  const handleDoctorSearch = (
    e: any,
    setSearchTerm: Function,
    navigate: Function,
  ) => {
    if (e?.target?.value?.length >= 1) {
      navigate(`/accomodation/1`);
      setSearchTerm(e?.target?.value);
    } else {
      setSearchTerm("");
    }
  };

  return (
    <>
      <section className="accommodation-outer-wrap">
        {/* <div className="reveal"> */}
          <Container>
            <div className="search-bar-full-wrap">
              <div className="search-bar">
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="Search Accomodations"
                    value={searchTerm}
                    onChange={(e) => handleDoctorSearch(e,setSearchTerm,navigate)}
                  />
                  <span className="icon-container">
                    <i className="icon xl icon-search"></i>
                  </span>
                </InputGroup>
              </div>

              <div className="right-side-wrap">
                <div className="filter-wrap">
                  <div>
                    <Select
                      placeholder="Select District"
                      options={districtData}
                      value={
                        districtData?.find(
                          (itm: any) => itm.value === value.district_id
                        ) || null
                      }
                      onChange={(e: any) => handleChange(e?.value, "district")}
                      name="district_id"
                      isLoading={false}
                      loadingMessage={() => "Fetching District"}
                      noOptionsMessage={() => "District appears here"}
                      isSearchable={true}
                      isClearable
                    />
                  </div>

                  <div className="button-wrap">
                    <Button
                      color="primary" className="sm"
                      onClick={() => {
                        setValue({
                          district_id: "",
                        });
                        setSearchTerm("");
                      }}
                    >
                      Reset
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Container>

          <Container>
            <div className="table-outer-wrap">
              <div className="table-outer">
                <Table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>District</th>
                      <th>Mobile</th>
                      <th>Alternate Mobile</th>
                      <th>Total Rooms</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accomodation?.data?.map((office: Office) => (
                      <tr key={office.id}>
                        <>
                          <td>
                            <span className="mobile-heading">Name</span>

                            <span className="table-data">
                              {office?.OfficeAccomodation?.name}
                            </span>

                          </td>
                          <td>
                            <span className="mobile-heading">District</span>
                            <span className="table-data">
                              {office?.OfficeAccomodation?.district.name}
                            </span>
                          </td>

                          <td>
                            <span className="mobile-heading">Mobile</span>
                            <span className="table-data">{office.phone_no}</span>
                          </td>
                          <td>
                            <span className="mobile-heading">Alternate Mobile</span>
                            <span className="table-data">{office.alternative_phone_no}</span>
                          </td>

                          <td>
                            <span className="mobile-heading">Total Rooms</span>
                            <span className="table-data">{office.no_of_rooms}</span>
                          </td>
                        </>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>

            {accomodation?.meta?.totalItems !== 0 && (
              <div className="pagination">
                <CustomPagination
                  totalItems={accomodation?.meta?.totalItems}
                  itemsPerPage={accomodation?.meta?.itemsPerPage}
                  activePage={accomodation?.meta?.currentPage}
                  disableScrollAfterPageChange
                  setCurrentPage={(pageNo: string) => {
                    ScrollToContainerTop(myDivRef);
                    setCurrentPageStaff(navigate, pageNo);
                  }}
                />

              </div>
            )}
          </Container>
        {/* </div> */}
      </section>
    </>
  );
};

export default MemberList;
