import React, { useEffect, useState } from "react";
import { Col, Card } from "reactstrap";
import { CardTypeState } from "../../Type/index";
import { Link } from "react-router-dom";

interface CardProps {
  props: CardTypeState[];
  to?: string;
  state?: boolean;
}

const StateCards: React.FC<CardProps> = ({ props, to, state }) => {
  const [active_top, setActiveTop] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [active_top]);

  return (
    <>
      {props
        ? props.map((prop) => (
          <Col key={prop.id} xs={12} sm={12} md={6} lg={3} className="reveal">
            <Card className="card-wrapper"> 
                <Link
                  to={`${to}/${prop.member?.id}`}
                  onClick={() => setActiveTop(true)}
                >
                  <div className="thumbnail-wrap">
                    <img
                      src={
                        prop?.member?.profile_img
                          ? prop?.member?.profile_img
                          : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                      }
                      className="thumbnail-md-cover"
                      alt="imageforstate"
                    />
                    <div className="name-wrap">
                      <h6 className="name">{prop?.first_name}</h6>
                      <h6 className="name">{prop?.roles?.[0]?.name}</h6>
                    </div>
                  </div>
                </Link>
             
            </Card>
          </Col>
        ))
        : ""}
    </>
  );
};

export default StateCards;
