import React, { useEffect } from 'react'
import MagazineList from './Containers/MagazineList'
import MagazineListBanner from './Containers/MagazineListBanner'



const MagazineIndex:React.FC = () => {
  useEffect(() => {
		window.scrollTo(0, 0)
	  }, [])
  return (
    <>
    <MagazineListBanner/>
    <MagazineList/>
    </>
  )
}

export default MagazineIndex
