import React from "react";
import { Col, Label } from "reactstrap";
import Slider from "react-slick";
import image1 from "../Containers/Images/certificates/certificate1.jpg";
import image2 from "../Containers/Images/certificates/certificate2.jpg";
import image3 from "../Containers/Images/certificates/certificate3.jpg";

const MemberCertificateView = () => {
  const images = [image1, image2, image3];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Col lg={12}>
        <Slider {...sliderSettings}>
          {images.map((imageUrl, index) => (
            <div key={index}>
              <img
                src={imageUrl}
                alt={`Image ${index + 1}`}
                // style={{ maxWidth: "100%", height: "500px" }}
                className="img-fluid"
              />
            </div>
          ))}
        </Slider>
      </Col>
    </>
  );
};

export default MemberCertificateView;
