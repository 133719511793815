import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { api } from "../../../../Api/Api";

type AddInitialValues = {
  blog_comment: string;
};

export const StaffSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  id: any
) => {
  const numberId = Number(id);
  if (localStorage.getItem("ROLE") !== null) {
    api
      .post("/blog-comment", { ...values, blogId: numberId }, true)
      .then(async function ([success, response]: any) {
        toast.success("Comment Added ");
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {

        } else {
          toast.error("Unexpected Error Occurred");
        }
      });
  }
  else {
    toast.warning("Please log in to Comment the blog");
  }
}

export const LikeSubmitHandler = (refetch: Function, id: any, setIsLiked?: any) => {

  const numericId = Number(id);

  if (localStorage.getItem("ROLE") !== null) {
    api
      .get(`/bloglike/${id}`, true)
      .then(async function ([success, response]: any) {
        if (response !== null && response.length === 0) {
          await api.post("/bloglike", { like: 1, blogId: numericId }, true);
          toast.success("Liked the blog",{toastId: "bloglike"});
          setIsLiked(true)
          refetch();
        } else {
          const id = parseInt(response[0]?.BlogLikes_id, 10);
          await api.post("/bloglike", { bloglikeId: id }, true);
          toast.error("Disliked the Blog",{toastId: "blogdislike"});
          setIsLiked(false)
          refetch();
        }
      })
      .catch((err) => {
        if (err?.response?.data?.errors) {
          // Handle validation errors if needed
        } else {
          toast.error("Unexpected Error Occurred",{toastId: "bloglikeerr"});
        }
      });
  } else {
    toast.warning("Please log in to like the blog",{toastId: "likeblog"});
  }
}

export const checkLikeStatus = async (id: any, setIsLiked: any) => {
  const userRole = localStorage.getItem("ROLE");

  if (userRole) {
    try {
      const [success, response] = await api.get(`/bloglike/${id}`, true);

      if (success && response !== null && response.length === 1) {
        setIsLiked(true);
      } else {
        setIsLiked(false);
      }
    } catch (error) {
      console.error("Error checking like status:", error);
      setIsLiked(false);
    }
  }
};

