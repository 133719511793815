import React, { useEffect } from "react";
import DownloadListBanner from "./Containers/DownloadBanner";
import Download from "./Containers/DownloadList";

const DownloadIndex: React.FC = () => {
  useEffect(() => {
		window.scrollTo(0, 0)
	  }, [])
  return (
    <>
      <DownloadListBanner />
      <Download />
    </>
  );
};

export default DownloadIndex;
