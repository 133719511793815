import BiloLIst from "./Bilo";
import BiloBanner from "./BiloBanner";

const BiloIndex = () => {
  return (
    <>
      <BiloBanner />
      <BiloLIst />
    </>
  );
};
export default BiloIndex;
