import React, { useRef } from 'react';
import { useEffect, useState } from 'react'
import { Container,DropdownItem, Row, } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import { api } from '../../../../Api/Api';
import { getAllSubcategory, getCategories } from '../../../../Api/WebsiteApi';
import { useQuery } from 'react-query';
import CustomPagination from '../../../../Components/Pagination/Pagination';
import LatestResources from './LatestResources';
import CategoryCard from './CategoryCard';
import Select from "react-select";




const ResourcesInsights: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [Sub_id, setSubId] = useState([]);
  const { currentPage = "1" } = useParams();
  const [data, setData] = useState<any>([]);
  const [subCategoryList, setSubCategoryList] = useState<any[]>([]);
  const [activePage, setActivePage] = useState(15);
  const [searchQuery, setSearchQuery] = useState<string>("");


  // dropdown
  const handleDropdownToggle = async (categoryId: string) => {
    try {
      const [success, response] = await api.get(`/download-categories?download_id=${categoryId}`, true);
      setSubCategoryList(response);
    } catch (error) {
      console.log(error);
    }
  };
  // useEffect(() => {
  //   getDownloads_(currentPage, searchTerm);
  // }, [searchTerm, currentPage]);
  // const getDownloads_ = (page: any, searchTerm: any) => {
  //   api
  //     .get(`/downloads?page=${page}&perPage=10&search=${searchTerm}`, true)
  //     .then(async function ([success, response]) {
  //       response?.data?.data?.map((item: any) => {
  //         item.is_check = false;
  //       });
  //       setData(response?.data?.data);
  //       return response;
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  useEffect(()=>{
    getDownloads_();
  }, [searchQuery,currentPage])

  const getDownloads_ = () => {
    api
    .get(`/download-categories/get-all-topCountNew?page=${currentPage}&search=${searchQuery}`,true)
    .then(async function ([success,response]){ 
      response?.data?.categoryResults.map((item: any) => {
        item.is_check = false;
      });
      setData(response);
      return response;
    })
      .catch((err) => {
        console.log(err);
      });
  }
  
  // const { data: subdownloadList }: any = useQuery(
  //   ["downloadList", Sub_id],
  //   () => (Sub_id ? getDownloadsSub(Sub_id) : ""),
  //   {
  //     onSuccess: () => console.log("Sub_id", { data: subdownloadList }),
  //   }
  // );

  const [view, setView] = useState(false); // For viewing
  const [download, setDownload] = useState(false); // For downloading
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const [url, setUrl] = useState("");
  const myDivRef: any = useRef(null);
  const toggleModal = (content: string, isView: boolean) => {
    setIsOpen(true);
    setModalContent(content);

    if (isView) {
      setView(true);
      setDownload(false);
    } else {
      setView(false);
      setDownload(true);
    }
  };
  const ScrollToContainerTop = (ref: any) => {
    if (ref?.current) {
      ref?.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };
  const navigate = useNavigate();
  const setCurrentPageStaff = (navigate: Function, pageNo: any) => {
    navigate(`/resources/${pageNo}`);
  };
  const handleDoctorSearch = (
    e: any,
    setSearchQuery: Function,
    navigate: Function,
  ) => {
    if (e?.target?.value?.length >= 1) {
      setSearchQuery(e?.target?.value);
      navigate(`/resources/1`);
    } else {
      setSearchQuery("");
    }
  };

  const check_data = (itemIndex: any) => {
    const neData: any = [...data];
    neData[itemIndex].is_check = !neData[itemIndex].is_check;
    setData(neData);
  };

  const [sub_data, setSubData] = useState([]);
  const getAllSubSubcategory = async (main_id: any, id: string) => {
    const [, datas] = await api.get(
      `/download-categories/${main_id}?parent_id=${id}`,
      true
    );
    setSubData(datas);
  };
  const downloadPDF = async (document_file_url: string, document_file: string,id:any) => {
    try {
      const response = await fetch(document_file_url);
      const blob = await response.blob();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = document_file;
      link.click();
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };
  const handleDownloadClick = async (subCategory: any) => {
    const { id, name, document_file_url } = subCategory;
    try {
      await api.patch(`/download-categories/updateCount?id=${id}`, { count: 1 }, true);
      downloadPDF(document_file_url, name, id);

    }
    catch (error) {
      console.error("Error handling download:", error);
    }
  };
  useEffect(() => {
    // Scroll to the top when the component mounts
    if (myDivRef.current) {
      myDivRef.current.scrollTop = 0;
    }
  }, []);
  const { data: categories }: any = useQuery(
    ["categories"],
    () => getCategories(),
    {
      keepPreviousData: true,
    }
  );
  
  // const {
  //   data: subCategoryList
  // }: any = useQuery(
  //   [`AllSubcategoryListdropdown`, id],
  //   () => getAllSubcategory(id),
  //   { 
  //     keepPreviousData: true,
  //   }

  return (
    <>
      {/* most accessed categ */}
      <section className="resources-page-cards-wrap">
        {/* <div className="reveal"> */}
          <Container ref={myDivRef}>
          <div className='d-flex align-items-center justify-content-between'>

            <div className="search-bar-full-wrap mb-2">
              <div className="search-bar">
                <div className="input-group">
                  <input placeholder="Search category" type="text" className="form-control" onChange={(e) =>
                    handleDoctorSearch(e, setSearchQuery, navigate)
                  } /><span className="icon-container"> <i className="icon xl icon-search"></i></span>
                </div>
              </div>
            </div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                <div>
                  <Select
                    placeholder="View Categories"
                    options={categories}
                    name="categories"
                    isLoading={false}
                    loadingMessage={() => "Fetching categories"}
                    noOptionsMessage={() => "Categories appears here"}
                    isSearchable={true}
                    isClearable
                  />
                </div>

              </div>
            </div>
          </div>

          <div className="resources-cards-out-container">
            <Row className='gy-4'>
            {data &&
              data?.data?.categoryResults.map((category: any, i: any) => (
              // {/* Category column 1 */}
              <CategoryCard
              key={i}
              category={category}
              subCategoryList={subCategoryList}
              handleDropdownToggle={handleDropdownToggle}
              handleDownloadClick={handleDownloadClick}
              navigate={navigate}
              setSubId={setSubId}
            />
            ))}
            </Row>
            
          </div>
          {data?.data?.meta?.totalItems !== 0 && (
              <div className="pagination">
                <CustomPagination
                  totalItems={data?.data?.meta?.totalItems}
                  itemsPerPage={data?.data?.meta?.itemsPerPage}
                  activePage={data?.data?.meta?.currentPage}
                  disableScrollAfterPageChange
                  setCurrentPage={(pageNo: string) => {
                    ScrollToContainerTop(myDivRef);
                    setCurrentPageStaff(navigate, pageNo);
                  }}
                />
              </div>
            )} 
          </Container>  
      </section>

      {/* latest categ */} 
      <LatestResources/>
    
    </>
  );
};

export default ResourcesInsights;
