import { Container,Row, Col, ListGroup, ListGroupItem } from "reactstrap";

const DoRemember = () => {
  return (
    <>
      <section className="about-us">
        <div className="member-info-outer-wrap">
          <Container>
            <Row className="justify-content-center">
              <Col lg={8} xs={12}>
                <div className="content-layout">
                  <h4 className="mb-4">Become A Member</h4>
                  <h6>A member in need is an active member indeed</h6>
                  <p>Do Remember</p>

                  <ListGroup numbered>
                    <ListGroupItem>
                      Enroll yourself as a member of the Association by
                      remitting Rs.3000/-
                    </ListGroupItem>
                    <ListGroupItem>
                      Renew your membership before 31st March of every year by remitting Rs.3000/-.
                    </ListGroupItem>
                    <ListGroupItem>
                      Participate actively in all the activities of the Association.
                    </ListGroupItem>
                    <ListGroupItem>
                      Don’t miss State Committee, State Council and General Body meetings.
                    </ListGroupItem>
                    <ListGroupItem>
                      Enlist as many subscribers as possible to the organ of the Association, Kannimannu.
                    </ListGroupItem>
                  </ListGroup>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default DoRemember;
