import React from "react";
import Pagination from "react-js-pagination";

interface Props {
  totalItems: number;
  itemsPerPage: number;
  activePage: number;
  setCurrentPage: any;
  disableScrollAfterPageChange?: boolean;
}

const CustomPagination: React.FC<Props> = ({
  totalItems,
  itemsPerPage,
  activePage,
  setCurrentPage,
  disableScrollAfterPageChange = false,
}) => {
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    !disableScrollAfterPageChange && window.scrollTo(0, 0);
  };

  return (
    <Pagination
      activePage={activePage}
      itemsCountPerPage={itemsPerPage}
      totalItemsCount={totalItems}
      pageRangeDisplayed={5}
      onChange={handlePageChange}
      nextPageText=""
      prevPageText=""
      firstPageText=""
      lastPageText=""
      linkClassPrev="bi bi-chevron-left prev"
      linkClassNext="bi bi-chevron-right next"
      linkClassLast="bi bi-chevron-double-right last"
      linkClassFirst="bi bi-chevron-double-left first"
      itemClass="pages"
      itemClassFirst="first-page"
      itemClassLast="last-page"
      itemClassNext="next-page"
      itemClassPrev="prev-page"
    />
  );
};

export default CustomPagination;
