import React, { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Container, Row, Button } from "reactstrap";
import { getStatemembers } from "../../../../Api/WebsiteApi";
import StateCards from "../../../../Components/Cards/Statecard";

const StateMember: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<string>("1");
  const [searchTerm, setSearchTerm] = useState("");
  const {
    data: memberList,
    isLoading: memberListLoading,
    isError,
  }: any = useQuery(["StateMembers"], () => getStatemembers(currentPage, searchTerm), {
    keepPreviousData: true,
  });
  const navigate = useNavigate()

  return (
    <>
      <section className="member-outer-wrap">
        {memberList?.data?.length !== 0 && <>
          {/* <div className="reveal"> */}
            <Container>
              <div className="heading-wrap">
                <h6>Our State Office Members</h6>
                {memberList?.data?.length > 4 && (
                  <div className="right-side-wrap"></div>
                )}
              </div>
            </Container>
            {/* cards section */}
            <div className="member-list-outer-wrap">
              <Container>
                <Row className="gy-4 align-items-center justify-content-center">
                  <StateCards to={`/statemember-profile`} state={true} props={memberList?.data} />
                </Row>
              </Container>
              <div className="learn-more-btn mt-5">
                <Button
                  outline
                  color="primary"
                  className="sm"
                  onClick={() => navigate("/statemember/1")}
                >
                  View All
                  <i className="icon icon-next-green"></i>
                </Button>
              </div>
            </div>
          {/* </div> */}
          </>}

      </section>
    </>
  );
};

export default StateMember;
