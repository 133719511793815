import React, { useState } from "react";
import { Col, Card } from "reactstrap";
import { CardTypeBlogs} from "../../Type/index";
import { Link } from "react-router-dom";

interface CardProps {
  props: CardTypeBlogs;
  url_go: string;
}

const BlogsCard: React.FC<CardProps> = ({ props, url_go }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };
 
  return (
    <Col sm={12} md={6} lg={3}>
      <Card className="card-wrapper">
        <Link to={`/blog-view/${props?.blog_id}`}>
          <div className="thumbnail-wrap">
            <img src={props?.image_url} alt="" />

            <div className="text-wrap">
              <p>{props?.blog_title} </p>
              {/* <p 
                 – 
                {props?.blog_body?.length >= 20 ? "..." : null}
              </p> */}
            
            </div>
          </div>
        </Link>
      </Card>
    </Col>
  );
};

export default BlogsCard;
