import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Input, InputGroup } from "reactstrap";

const Footer: React.FC = () => {
  //reveal starts
  useEffect(() => {
    function reveal() {
      var reveals = document.querySelectorAll(".reveal");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    }

    function revealRight() {
      var reveals = document.querySelectorAll(".revealRight");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 100;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    }

    function revealRotate() {
      var reveals = document.querySelectorAll(".revealRotate");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 100;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    }

    function moveDown() {
      var reveals = document.querySelectorAll(".moveDown");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 50;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    }

    window.addEventListener("scroll", reveal);
    window.addEventListener("scroll", revealRight);
    window.addEventListener("scroll", revealRotate);
    window.addEventListener("scroll", moveDown);

    // Clean up event listeners when the component unmounts
    return () => {
      window.removeEventListener("scroll", reveal);
      window.removeEventListener("scroll", revealRight);
      window.removeEventListener("scroll", revealRotate);
      window.removeEventListener("scroll", moveDown);
    };
  }, []);

  //   reveal ends
  return (
    <>
      <footer className="footer reveal">
        <div className="footer-outer-wrap">
          <Container>
            <div className="footer-list-wrap">
              <Row className="gy-4">
                <Col sm={12} md={4}>
                  <div className="footer-logo-wrap">
                    <div className="site-logo">
                      <img src="/images/site-logo.png" alt="Site Logo" />
                    </div>
                    <div>
                      <h6>Association of Agricultutal <br /> Officers Kerala</h6>
                    </div>
                  </div>
                  <div className="logo-content-wrap">
                    <p>Empowering Agricultural Officers in Kerala since 1958 – Advocating for the Rights and Welfare of Members in Agriculture and Horticulture</p>
                  </div>
                  <div className="footer-icon-container">
                      <Link to="https://www.facebook.com/aoao.keralam?mibextid=ZbWKwL">
                        <i className="icon icon-facebook xl"></i></Link>
                      <Link to="https://youtube.com/@aoaokeralam?si=dDaMgPDdMApFFElB">
                        <i className="icon icon-youtube xl"></i></Link>
                    </div>

                </Col>

                <Col sm={12} md={4}>
                  <div className="foot-wrap">
                    <div className="lists">
                      <h6>Explore</h6>
                      <div className="lists-inner">
                        <div>
                          <Link to="/" className="header"> Home</Link>
                          <Link to="/about-us" className="header">
                            About Us
                          </Link>
                          {localStorage.getItem("ROLE") === null ? null : (
                            <Link to="/members/1" className="header">
                              Members
                            </Link>)}
                          < Link to="/offices/1" className="header">
                            Offices
                          </Link>
                          <Link to="/resources/1" className="header">
                           Resources
                          </Link>
                          <Link to="/magazine/1" className="header">
                            Magazine
                          </Link>
                        </div>
                        <div>
                          <Link to="/gallery/1" className="header">
                            Gallery
                          </Link>
                          <Link to="/blogs/1" className="header">
                            Blogs
                          </Link>
                          <Link to="/contact-us" className="header">
                            Contact Us
                          </Link>
                          <Link to="/terms-and-conditions" className="header">
                            Terms and Conditions
                          </Link>
                          <Link to="/privacy-policy" className="header">
                            Privacy Policy
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col sm={12} md={4}>
                  <div className="foot-wrap">
                      <div className="lists">
                        <h6>Contact Us</h6>
                        <Link to="/">
                          Address
                          <div>Haritham T.C 28/2822, Kunnumpuram, <br />
                            Thiruvananthapuram-1</div>
                        </Link>
                        <Link to="/">
                          Phone
                          <div>Ph: +91 - 9447077133</div>
                        </Link>
                        <Link to="/">
                          Email
                          <div>aoaokeralam@gmail.com</div>
                        </Link>
                      </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div >

        <div className="copyright-text-wrap">
          <Container>
            <Row>
              <div className="copyright-inner-wrap">
                <div>
                  <small>
                    &copy; 2024 Association of Agricultural Officers Kerala
                  </small>
                </div>
                <div className="pt-sm-2">
                  <small>
                    Developed and Designed by{" "}
                    <Link to="https://orisys.in/">OrisysIndia</Link>
                  </small>
                </div>
              </div>
            </Row>
          </Container>
        </div>
      </footer >
    </>
  );
};

export default Footer;
