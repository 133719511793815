import React, { useState } from "react";
import { Container, Col, Card, CardBody, CardTitle } from "reactstrap";
import { getEducation, getMemberid } from "../../../../Api/WebsiteApi";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

const MemberInfoCard: React.FC = () => {

  const { id } = useParams<{ id: string }>();

  const {
    data: memberList,
    error: memberError,
    isLoading: memberLoading,
    refetch: memberListRefetch,
  }: any = useQuery(["stateProfile", id], () => getMemberid(id), {});

  const {
    data: educationList,
    error: educationError,
    isLoading: educationLoading,
    refetch: educationListRefetch,
  }: any = useQuery(["educationforstate", memberList?.user?.id], () => getEducation(memberList?.user?.id), {});

  return (
    <>
      {educationList && (
        <section>
          <div>
            <Container>
              <div className="member-detail-card-wrap">
                <Col md={6} className="colored-card-col">
                  <Card>
                    <CardBody>
                      <CardTitle tag="h4">Education</CardTitle>
                      {Array.isArray(educationList) && educationList.length > 0 ? (
                        educationList.map((education: any, index: any) => (
                          <div key={index} className="edu-wrap">
                            <div><i className="icon icon-tick xl"></i></div>
                            <div><h6>{education.degree_name}</h6></div>
                            <div className="para"><p>{education?.educat_cat_name}</p></div>
                          </div>
                        ))
                      ) : (
                        educationList && educationList[0] ? (
                          <div className="edu-wrap">
                            <div><i className="icon icon-tick xl"></i></div>
                            <div><h6>{educationList[0]?.degree_name}</h6></div>
                            <div className="para"><p>{educationList[0]?.educat_cat_name}</p></div>
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </div>
            </Container>
          </div>
        </section>
      )}
    </>
  );
};

export default MemberInfoCard;
