import React, { useState, ChangeEvent } from "react";
// import * as pdfjsLib from "pdfjs-dist";

const PdfToImageConverter: React.FC = () => {
  const [images, setImages] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  // const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   setSelectedFile(file || null);
  // };

  // const convertPdfToImages = async () => {
  //   if (!selectedFile) {
  //     alert("Please select a PDF file.");
  //     return;
  //   }

  //   const fileURL = URL.createObjectURL(selectedFile);
  //   const pdf:any = await pdfjsLib.getDocument({ url: fileURL }).promise;

  //   const imageList: string[] = [];

  //   for (let i = 1; i <= pdf.numPages; i++) {
  //     const page = await pdf.getPage(i);
  //     const viewport = page.getViewport({ scale: 1.5 });
  //     const canvas = document.createElement("canvas");
  //     const context = canvas.getContext("2d");

  //     if (!context) {
  //       console.error("Canvas context is null.");
  //       return;
  //     }

  //     canvas.height = viewport.height;
  //     canvas.width = viewport.width;

  //     await page.render({ canvasContext: context, viewport }).promise;

  //     const imgDataUrl = canvas.toDataURL("image/png");
  //     imageList.push(imgDataUrl);
  //   }

  //   setImages(imageList);
  // };

  return (
    // <div>
    //   <input type="file" onChange={handleFileChange} accept="application/pdf" />
    //   <button onClick={convertPdfToImages}>Convert to Images</button>

    //   {images.length > 0 && (
    //     <div>
    //       <h2>Converted Images</h2>
    //       {images.map((img, index) => (
    //         <img key={index} src={img} alt={`Page ${index + 1}`} />
    //       ))}
    //     </div>
    //   )}
    // </div>
    <></>
  );
};

export default PdfToImageConverter;
