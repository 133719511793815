import React from 'react'
import {ContactUsBanner,ContactUsForm} from '../../Routes/Imports'
import ResourcesBanner from './Container/ResourcesBanner'
import ResourcesInsights from './Container/ResourcesInsights'

const Resources:React.FC = () => {
  return (
    <>
    <ResourcesBanner/>
    <ResourcesInsights />
    </>
  )
}

export default Resources
