import React, { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Button } from "reactstrap";
import { getMembers } from "../../../../Api/WebsiteApi";
import CustomPagination from "../../../../Components/Pagination/Pagination";
import DocCards from "../../../../Components/Cards/DocCard";
import { CardTypeMem } from "../../../../Type/index";
import statepresident from "../Containers/Images/Members/George Abraham State President.jpg";
import statesecretary from "../Containers/Images/Members/shaji r state gen secretary.jpeg";
import Statetressurer from "../Containers/Images/Members/anjana state Treasurer.jpg";
import StateWebadmin from "../Containers/Images/Members/BAIJU state web admin.jpg";

const Member: React.FC = () => {
  const [value, setValue] = useState({
    district_id: "",
  });
  const [currentPages, setCurrentPages] = useState(1);
  const itemsPerPage = 12;
  const [searchTerm, setSearchTerm] = useState("");
  const { currentPage = "1" } = useParams();
  const {
    data: memberList,
    isLoading: memberListLoading,
    isError,
  }: any = useQuery(
    ["Members", currentPage, searchTerm, value?.district_id],
    () => getMembers(currentPage, searchTerm, value?.district_id),
    {
      keepPreviousData: true,
    }
  );
  const setCurrentPageStaff = (navigate: Function, pageNo: any) => {
    navigate(`/statemember/${pageNo}`);
  };
  const first4Doctor = memberList?.data?.items?.slice(0, 4) || [];
  const navigate = useNavigate();
  
  const cardArray: CardTypeMem[] = [
    {
      id: 1,
      user: {
        first_name: "Sri. GEORGE T ABRAHAM",
        last_name: "",
      },
      post: "Lorem ipsum dolor sit amet",
      profile_img: statepresident,
      designationMeta: {
        id: "1",
        name: "State President",
      },
    },
    {
      id: 2,
      user: {
        first_name: "Sri. SHAJI R",
        last_name: "",
      },
      post: "Consectetur adipiscing elit",
      profile_img: statesecretary,
      designationMeta: {
        id: "2",
        name: "State General Secretary",
      },
    },
    {
      id: 3,
      user: {
        first_name: "Smt. ANJANA S",
        last_name: "",
      },
      post: "Consectetur adipiscing elit",
      profile_img: Statetressurer,
      designationMeta: {
        id: "2",
        name: "State Treasurer",
      },
    },
    {
      id: 4,
      user: {
        first_name: "Sri. BAIJU G ",
        last_name: "",
      },
      post: "Consectetur adipiscing elit",
      profile_img: StateWebadmin,
      designationMeta: {
        id: "2",
        name: "State Web Admin",
      },
    },
    {
      id: 5,
      user: {
        first_name: "Suresh K.P",
        last_name: "",
      },
      post: "Consectetur adipiscing elit",
      profile_img: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
      designationMeta: {
        id: "2",
        name: "Vice President",
      },
    },
    {
      id: 6,
      user: {
        first_name: "SUJA EAPEN",
        last_name: "",
      },
      post: "Consectetur adipiscing elit",
      profile_img: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
      designationMeta: {
        id: "2",
        name: "Vice President",
      },
    },
    {
      id: 7,
      user: {
        first_name: "VYAS N.G",
        last_name: "",
      },
      post: "Consectetur adipiscing elit",
      profile_img: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
      designationMeta: {
        id: "2",
        name: "Joint Secretary",
      },
    },
    {
      id: 8,
      user: {
        first_name: "SHIJO K.A.",
        last_name: "",
      },
      post: "Consectetur adipiscing elit",
      profile_img: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
      designationMeta: {
        id: "2",
        name: "Joint Secretary",
      },
    },
    {
      id: 9,
      user: {
        first_name: "ATHUL B.M.",
        last_name: "",
      },
      post: "Consectetur adipiscing elit",
      profile_img: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
      designationMeta: {
        id: "2",
        name: "Joint Secretary",
      },
    },
    {
      id: 10,
      user: {
        first_name: "DHANYA C",
        last_name: "",
      },
      post: "Consectetur adipiscing elit",
      profile_img: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
      designationMeta: {
        id: "2",
        name: "Associate Treasurer",
      },
    },
    {
      id: 11,
      user: {
        first_name: "Asha Raj",
        last_name: "",
      },
      post: "Consectetur adipiscing elit",
      profile_img: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
      designationMeta: {
        id: "2",
        name: "Women Cell Convenor",
      },
    },
    {
      id: 12,
      user: {
        first_name: "Deepa J",
        last_name: "",
      },
      post: "Consectetur adipiscing elit",
      profile_img: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
      designationMeta: {
        id: "2",
        name: "Women Cell Convenor",
      },
    },
    {
      id: 13,
      user: {
        first_name: "Asha Raj",
        last_name: "",
      },
      post: "Consectetur adipiscing elit",
      profile_img:"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
      designationMeta: {
        id: "2",
        name: "Women Cell Convenor",
      },
    },
    {
      id: 14,
      user: {
        first_name: "Pradeesh V.S",
        last_name: "",
      },
      post: "Consectetur adipiscing elit",
      profile_img: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
      designationMeta: {
        id: "2",
        name: "Web manager",
      },
    },
    {
      id: 15,
      user: {
        first_name: "Rajesh Krishnan",
        last_name: "",
      },
      post: "Consectetur adipiscing elit",
      profile_img: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
      designationMeta: {
        id: "2",
        name: "Web manager",
      },
    },
    {
      id: 16,
      user: {
        first_name: "Pramod M.S",
        last_name: "",
      },
      post: "Consectetur adipiscing elit",
      profile_img: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
      designationMeta: {
        id: "2",
        name: "Editor Kannimannu",
      },
    },
    {
      id: 17,
      user: {
        first_name: "Joseph John Therattil",
        last_name: "",
      },
      post: "Consectetur adipiscing elit",
      profile_img: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
      designationMeta: {
        id: "2",
        name: "Sub Editor Kannimannu",
      },
    },
    
  ];
  const totalItems = cardArray.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const visibleItems = cardArray.slice(
    (currentPages - 1) * itemsPerPage,
    currentPages * itemsPerPage
  );

  const handlePageChange = (pageNo: number) => {
    setCurrentPages(pageNo);
  };
  return (
    <>
      {memberList?.data?.items?.length !== 0 && (
        <section className="member-outer-wrap">
          <div className="reveal">
            {/* <Container>
              <div className="heading-wrap">
                <h4 className="main-heading">STATE OFFICE BEARERS</h4>
              </div>
            </Container> */}

            {/* cards section */}
            <div className="member-list-outer-wrap">
              <Container>
                <Row className="align-items-center justify-content-center">
                  {visibleItems?.map((data: CardTypeMem) => (
                    <DocCards
                      props={data}
                      key={data?.id}
                      to={`/members-profile`}
                    />
                  ))}
                </Row>
              </Container>
            </div>
          </div>
          {/* {localStorage.getItem("ROLE") !== null && (
            <> */}
          <div>
            {/* <div className="learn-more-btn">
              <Button
                outline
                color="primary"
                className="sm"
                onClick={() => navigate("/members/1")}
              >
                View All
                <i className="icon icon-next-green"></i>
              </Button>
            </div> */}
          </div>
          {/* </>
          )} */}
           {memberList?.items?.totalItems !== 0 && (
            <div className="pagination">
             <CustomPagination
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            activePage={currentPages}
            disableScrollAfterPageChange
            setCurrentPage={handlePageChange}
          />
            </div>
          )}
        </section>
      )}
      {/* <HorizonatlScroll /> */}
    </>
  );
};

export default Member;
