import { Container, Col } from 'reactstrap';

const TermsAndConditionsList = () => {
  return (
    <>
           <section className="terms-and-conditions">
                <div className="terms-and-conditions-outer-wrap reveal">
                    <Container>
                        <div className="heading-wrap text-start">
                            <h4 className='heading'>Terms & conditions</h4>
                        </div>
                        <div className="terms-and-conditions-inner-wrap">
                            <Col lg={12} md={12} sm={12}>
                                <h5>23/11/2023</h5>
                                <p>These Terms and Conditions ("Terms") govern the use of the appointment booking service provided by Sabitha eye care hospital via our website. By using this service, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use our appointment booking service.</p>
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <h5>Acceptance of Terms</h5>
                                <p>By accessing the Website, you agree to comply with and be bound by these Terms. AOAOK reserves the right to modify these Terms at any time without prior notice. Your continued use of the Website after any such changes constitutes your acceptance of the updated Terms.</p>
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <h5>Use of the Website</h5>
                                <p>Privacy Policy: Your use of our appointment booking service is also governed by our Privacy Policy. By booking an appointment, you consent to the collection, use, and sharing of your information as described in the Privacy Policy.</p>
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <h5>User Accounts</h5>
                                <p>Register accurately and maintain account security. AOAOK is not liable for unauthorized access.</p>
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <h5>Privacy Policy</h5>
                                <p>Refer to our Privacy Policy for information on data collection and usage.</p>
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <h5>Disclaimers</h5>
                                <p>AOAOK strives for accuracy but does not guarantee it. Links to third-party sites are not endorsed.</p>
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <h5>Limitation of Liability</h5>
                                <p>AOAOK is not liable for damages arising from website use.</p>
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <h5>Governing Law</h5>
                                <p>These Terms are governed by the laws of Kerala, India.</p>
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <h5>Contact</h5>
                                <p>Questions? Reach out to [contact@aoaok.org].</p>
                            </Col>
                        </div>
                    </Container>

                </div>
            </section>
    </>
  )
}

export default TermsAndConditionsList
