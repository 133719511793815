import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import axios from "axios";
import { BaseUrl } from "../../../../Api/BaseUrl";
import { setValidationErrorsAll } from "../../../../Utils/helpers/helpers";

type AddInitialValues = {
  employement_status: string;
  present_status: string;
  working_status: any;
  member_f_name: string;
  member_l_name: string;
  pen_no: string;
  office: string;
  email: string;
  phone_no: string;
  member_whatsapp_number: string;
  post_graduation: any;
  post_graduation_year_of_passing: any;
  doctoral_degree: string;
  bachelor_degree_year_of_psssing: string;
  bachelor_degree: string;
  any_other_education: any;
  member_date_of_birth: string;
  designation: string;
  member_type: any;
  role: any;
  date_of_joining: string;
  date_of_retirement: string;
  join_status: string;
  membership_document: string;
  seat_id: any;
  block_id: any;
  district_id: any;
  office_category: any;
  check: any;
};
// eslint-disable-next-line react-hooks/rules-of-hooks

export const StaffSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  navigate: any
) => {
  const formData = new FormData();

  formData.append("is_working", values?.working_status);
  formData.append("first_name", values?.member_f_name);
  formData.append("last_name", values?.member_l_name);
  formData.append("email", values?.email);
  formData.append("phone_no", values?.phone_no);
  formData.append("whatsapp_no", values?.member_whatsapp_number);
  formData.append("date_of_birth", values?.member_date_of_birth);
  formData.append("membership_type", values?.member_type);

  if (values?.date_of_joining) {
    formData.append("date_of_joining_service", values?.date_of_joining);
  }
  if (values?.date_of_retirement) {
    formData.append("date_of_retirement", values?.date_of_retirement);
  }
  if (values?.office) {
    formData.append("office_id", values?.office);
  }
  if (values?.office_category) {
    formData.append("office_category", values?.office_category);
  }
  if (values?.seat_id) {
    formData.append("cadre_id", values?.seat_id);
  }
  if (values?.district_id) {
    formData.append("district_id", values?.district_id);
  }
  if (values?.block_id) {
    formData.append("block_id", values?.block_id);
  }
  if (values?.designation) {
    formData.append("designation_id", values?.designation);
  }
  if (values?.present_status) {
    formData.append("pension_scheme", values?.present_status);
  }
  if (values?.pen_no) {
    formData.append("pen_no", values?.pen_no);
  }
  if (values?.membership_document) {
    formData.append("membership_certificate", values?.membership_document);
  }
  if (values?.employement_status) {
    formData.append("employment_status", values?.employement_status);
  }

  // eslint-disable-next-line no-lone-blocks
  {
    axios({
      method: "post",
      url: `${BaseUrl}/open/member-register`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status === 201) {
          actions?.setSubmitting(false);
          toast.success("Registration Completed");
          navigate("/register-success");
        }
      })
      .catch(function (response) {
        actions?.setSubmitting(false);
        if (response?.response?.data?.message) {
          if (typeof response?.response?.data?.message === "string") {
            actions.setFieldError(
              "membership_document",
              response?.response?.data?.message
            );
          } else if (Array.isArray(response?.response?.data?.message)) {
            setValidationErrorsAll(response?.response?.data?.message, actions);
          }
        } else {
          toast.error("Unexpected Error Occurred");
        }
      });
  }
};
