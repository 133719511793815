import { useQuery } from "react-query";
import { getHistory } from "./HistoryMethods";
import { Container, Row, Table } from "reactstrap";
import HistoryBanner from "./HistoryBanner";

const HistoryModal = () => {
  const { data: historyData }: any = useQuery(
    ["AdminGetHistory"],
    () => getHistory(),
    { keepPreviousData: true }
  );

  return (
    <>
      <section className="history-list-wrap">
          <Container>
            <Row className="align-items-center">
              <div className="table-outer-wrap">
                <div className="table-outer">
                  <Table>
                    <tbody>
                      {historyData?.map((history: any) => {
                        return (
                          <tr key={history?.id}>
                            <td>{history?.title}</td>
                            <td>{history?.body}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Row>
          </Container>
      </section>
    </>
  );
};

export default HistoryModal;
