import React, { useEffect, useState } from "react";
import CustomSpinner from "../../../../Components/Button/CustomSpinner";
import { Container, Row, Col, Card } from "reactstrap";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { getMemberid } from "../../../../Api/WebsiteApi";
import { useParams } from "react-router-dom";
import { FirstLetterUpper } from "../../../../Utils/helpers/helpers";

const MemberProfile: React.FC = () => {
  //slider
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  const [memberId, setMemberId] = useState<string | undefined>();
  const { id } = useParams<{ id: string | undefined }>();

  useEffect(() => {
    if (id) {
      setMemberId(id as string);
    }
  }, [id]);

  const {
    data: memberList,
    error: memberError,
    isLoading: memberLoading,
  }: any = useQuery(
    ["Member", memberId],
    () => (memberId ? getMemberid(memberId) : ""),
    {}
  );

  return (
    <>
      <section className="member-profile">
        <div className="member-profile-outer-wrap">
          <Container>
            <Row>
              <Col lg={8} xs={12}>
                {memberLoading ? (
                  <div>
                    <CustomSpinner />
                  </div>
                ) : (
                  <Row className="gy-4">
                    <Col lg={3} md={3} sm={12}>
                      <Card className="member-profile-thumbnail-wrap">
                        <img
                          src={
                            memberList?.profile_img !== null
                              ? memberList?.profile_img
                              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                          }
                          className="thumbnail-sm-cover"
                          alt={memberList?.user?.first_name}
                        />
                      </Card>
                    </Col>
                    <Col lg={9} md={9} sm={12}>
                      <div className="name-designation-wrap">
                        <h4>{FirstLetterUpper(memberList?.user?.first_name)}</h4>
                        <div className="title-wrap">
                          <span>{FirstLetterUpper(memberList?.designationMeta?.name)}</span>
                          <span>{memberList?.district?.name}</span>
                        </div>
                        <div className="title-wrap">
                          <span>{memberList?.phone_no}</span>
                          <span>{memberList?.user?.email}</span>
                          <span>
                            <Link to={memberList?.linkedin_profile}>
                              <i className="icon xl icon-linkedin-grey"></i>
                            </Link>
                            <Link to={memberList?.twitter_profile}>
                              <i className="icon xl icon-x-grey"></i>
                            </Link>
                            <Link to={memberList?.instagram_profile}>
                              <i className="icon xl icon-fb-grey"></i>
                            </Link>
                          </span>
                        </div>
                        <div className="description">
                          <p>{memberList?.about}</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default MemberProfile;
