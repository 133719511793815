import React, { useEffect, useState } from "react";
import ContactUsForm from "../../ContactUs/Container/ContactUsForm";

const Contact: React.FC = () => {

  return (
    <>
      <ContactUsForm />

    
    </>
  );
};

export default Contact;
