import { ErrorMessage, Formik } from "formik";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Col,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";

import {
  initializeLoginInitialValues,
  LoginValidation,
} from "./LoginPageMethods/FormikMethods";
import { LoginSubmitHandler } from "./LoginPageMethods/LoginHandlers";
import TextError from "../../../Components/Errors/TextErrors";
import CustomButton from "../../../Components/Button/CustomButton";


const Login = () => {
 
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [LoginInitialValues] = useState(() => initializeLoginInitialValues());

  return (
    <section className="login-wrapper">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col md={4} className="p-sm-0">
            <div className="login-form-wrap">
              <div className="heading">
                <h3>Login</h3>
              </div>

              <Formik
                validationSchema={LoginValidation}
                initialValues={LoginInitialValues}
                enableReinitialize
                onSubmit={(values: any, actions: any) =>
                  LoginSubmitHandler(values, actions, navigate)
                }
              >
                {({
                  values,
                  isSubmitting,
                  handleChange,
                  touched,
                  handleSubmit,
                }: any) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <div className="form-wrap">
                        <Row className="g-3">
                          <Col lg={12}>
                            <Input
                              type="text"
                              name="email"
                              value={values?.email}
                              onChange={handleChange}
                              placeholder="PEN or Mobile Number"
                            />
                            <ErrorMessage name="email" component={TextError} />
                          </Col>
                          <Col lg={12}>
                            <InputGroup>
                              <Input
                                name="password"
                                type={passwordVisible ? "text" : "password"}
                                placeholder="Password"
                                value={values?.password}
                                aria-describedby="newpass"
                                onChange={handleChange}
                              />
                              <span className="input-icon" id="newpass">
                                <i
                                  onClick={() =>
                                    setPasswordVisible(!passwordVisible)
                                  }
                                  className={`icon xl ${
                                    passwordVisible
                                      ? "icon-eye-open"
                                      : "icon-eye-close"
                                  }`}
                                />
                              </span>
                            </InputGroup>
                            <ErrorMessage
                              name="password"
                              component={TextError}
                            />
                          </Col>
                          <Col lg={12}>
                            <div className="checkbox-wrap">
                              <div>
                                <Input
                                  type="checkbox"
                                  id="remember-me"
                                  name="remember_me"
                                  onChange={handleChange}
                                  checked={values?.remember_me}
                                />
                              </div>
                              <div>
                                <Label htmlFor="remember-me">
                                  Remember me
                                </Label>
                              </div>
                            </div>
                          </Col>
                          <Col md={12}>
                            <CustomButton
                              isLoading={isSubmitting}
                              label="LOG IN"
                              color="primary"
                              className="sm submit-btn w-100"
                            />
                          </Col>
                          <Col md={12}>
                            <div className="remember-password-wrap">
                              <div>
                                <Link to="/forgot-password">
                                  Lost your password?
                                </Link>
                              </div>
                              <div>
                                <Link to="/register">Register?</Link>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Login;
