import React, { forwardRef, useEffect, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";
import "./styles.css";
import { useQuery } from "react-query";
import { getMagazineById } from "../../../../Api/WebsiteApi";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container, Row, Col } from "reactstrap";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

const MagazineViewModal: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: magazine } = useQuery(
    ["getMagazineByid", id],
    () => getMagazineById(id),
    {}
  );

  const [samplePDF, setSamplePDF] = useState<string | undefined>(
    magazine?.[0]?.document_url || ""
  );
  const [numPages, setNumPages] = useState<number>(0);
  const [zoomLevel, setZoomLevel] = useState(1);

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom * 1.2); // Increase zoom level by 20%
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => prevZoom / 1.2); // Decrease zoom level by 20%
  };
  let pdfDocument: any = null;

  useEffect(() => {
    setSamplePDF(magazine?.[0]?.document_url || "");
  }, [magazine?.[0]?.document_url]);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs?.version}/pdf.worker.js`;

    const loadDocument = async () => {
      if (samplePDF) {
        try {
          pdfDocument = await pdfjs.getDocument(samplePDF).promise;
          setNumPages(pdfDocument.numPages);
        } catch (error) {
          console.error("Error loading PDF document:", error);
        }
      }
    };

    loadDocument();

    return () => {
      if (pdfDocument) {
        pdfDocument.destroy();
      }
    };
  }, [samplePDF]);

  const width: number = 400* zoomLevel;
  const height: number = 624* zoomLevel;
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/magazine/1");
  };

  const Page = forwardRef<HTMLDivElement, { pageNumber: number }>(
    ({ pageNumber }, ref) => {
      return (
        <div ref={ref}>
          <ReactPdfPage pageNumber={pageNumber} width={width} />
        </div>
      );
    }
  );

  const MagazineContent = () => {
    return (
      <div>
        <HTMLFlipBook
          width={width}
          height={height}
          size="fixed"
          minWidth={200}
          className="yourClassName"
          style={{ backgroundColor: "white" }}
          startPage={1}
          maxShadowOpacity={0.5}
          showCover
          mobileScrollSupport
          maxWidth={600}
          minHeight={600}
          maxHeight={600}
          drawShadow
          flippingTime={800}
          usePortrait={false}
          startZIndex={0}
          autoSize
          clickEventForward
          useMouseEvents
          swipeDistance={50}
          showPageCorners
          disableFlipByClick
        >
          {Array.from({ length: numPages }, (_, index) => (
            <Page key={index + 1} pageNumber={index + 1} />
          ))}
        </HTMLFlipBook>
      </div>
    );
  };

  return (
    <>
      <section className="magazine-modal-wrap">
        <Container>
          <Row className="align-items-center justify-content-center reveal w-100 text-align-center overflow-x-auto">
            <Col md={10} sm={12}>
              <p className="text-center">
                Swipe the pdf to go forward and backward between Pages
              </p>
            </Col>
            <Col md={2} sm={12}>
              <div className="zoom-wrap">
                <i title="Zoom in" className="icon icon-zoom-in xl" onClick={handleZoomIn}></i>
                <i title="Zoom out" className="icon icon-zoom-out xl" onClick={handleZoomOut}></i>
              </div>
            </Col>
                        
            <Col md={7} sm={12}>
              {numPages > 0 && (
                <div style={{ overflow: 'auto' }} className="w-100">  
                <Document file={samplePDF}>
                  <MagazineContent />
                </Document>
              </div>
              )}
              <div className="button-wrap">
                <Button
                  outline
                  color="primary"
                  className="sm primary"
                  style={{ zIndex: 999 }}
                  onClick={handleGoBack}
                >
                  Go Back
                </Button>
              </div>
            </Col>
          </Row>
        </Container> 

      </section>
    </>
  );
};

export default MagazineViewModal;
