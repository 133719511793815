import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
interface Props {
  category: {
    id: string;
    name: string;
    downloadCategory: {
      id: string;
      name: string;
      document_file_url: string;
    }[];
  };
  subCategoryList: {
    id: string;
    name: string;
  }[];

  handleDropdownToggle: (categoryId: string) => void;
  handleDownloadClick: (item: {
    name: string;
    document_file_url: string;
  }) => void;
  navigate: (path: string) => void;
  setSubId: (id: any) => void;
}
const CategoryCard: React.FC<Props> = ({
  category,
  subCategoryList,
  handleDropdownToggle,
  handleDownloadClick,
  navigate,
  setSubId,
}) => {
    
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  return (
    <>
      <Col md={4} >
        <div className="category-wrap">
          <div className="category-wrap-inner">
            <div className="category-heading">
              <h4 
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigate(
                  `/resources/subcategories/${category.id}?category=${category.name}`
                );
                setSubId(category.id);
              }}>{category.name}</h4>
              {/* <p><small>subcategories</small></p> */}
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                className="sub-category-dropdown-wrap"
                onClick={() => handleDropdownToggle(category.id)}
              >
                <DropdownToggle className="main-btn">
                  <i className="icon icon-view xl" title="Sub categories"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>Sub Categories</DropdownItem>
                  {subCategoryList.map((subCategory: any) => (
                    <DropdownItem key={subCategory.id}>
                      {subCategory.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="category-card-list-wrap" >
              <Row className="gy-4">
                {category.downloadCategory.slice(0, 3).map((item: any) => (
                  <Col xl={12}>
                    <Card className="resources-categ-card">
                      <CardBody>
                        <div className="categ-body-inner">
                          <div className="content">
                            <p>{item.name}</p>
                          </div>
                          <div className="action-btn">
                            <Link title="View" to={item.document_file_url}>
                              <i className="icon icon-view xl"></i>
                            </Link>
                            <span
                              title="Download"
                              onClick={() => handleDownloadClick(item)}
                            >
                              <i className="icon icon-download xl"></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
            <div className="view-all-btn end">
              <Button
                style={{ 
                  cursor: 'pointer' 
                }}
                className="sm"
                onClick={() => {
                  navigate(
                    `/resources/subcategories/${category.id}?category=${category.name}`
                  );
                  setSubId(category.id);
                }}
              >
                View all
                <i className="icon icon-arrow-green ms-2" title="View All"></i>
              </Button>
            </div>
          </div>
        </div>
        
    </Col>
    </>
  );
};

export default CategoryCard;
