import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { Container, Row } from "reactstrap";
import { CardTypeGallery } from "../../../../Type/index";
import GallerySingleCard from "../../../../Components/Cards/gallerysinglecard";
import { getGallerybyid } from "../../../../Api/WebsiteApi";
import { useParams } from "react-router-dom";
import GalleryListBanner from "./GalleryListBanner";

const GallerycardList: React.FC = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
    }, [])
  const { id } = useParams();
  const { data: galleryList }: any = useQuery(
    "gallery",
    () => getGallerybyid(id),
    {}
  );

  return (
    <>
      <GalleryListBanner />
      {galleryList?.length !== 0 && (
        <section className="gallery-outer-wrap">
            <Container>
              <div className="heading-wrap">
                <div>
                  <h4 className="main-heading">Gallery</h4>
                </div>
              </div>
            </Container>

            {/* cards section */}
            <div className="gallery-list-outer-wrap">
              <Container>
                <Row className="gy-4">
                  {galleryList && galleryList.length > 1 ? (
                    galleryList.map((gallery: CardTypeGallery) => (
                      <GallerySingleCard props={gallery} key={gallery.id} url_go="" />
                    ))
                  ) : (
                    galleryList && galleryList[0] && (
                      <GallerySingleCard props={galleryList[0]} key={galleryList[0].id} url_go="" />
                    )
                  )}
                </Row>
              </Container>
            </div>

        </section>
      )}
    </>
  );
};

export default GallerycardList;
