import React from "react";
import { useQuery } from "react-query";
import { Container, Row, Button } from "reactstrap";
import { CardTypeGallery } from "../../../../Type/index";
import GalleryCard from "../../../../Components/Cards/gallery";
import { getGallerywithoutpagination } from "../../../../Api/WebsiteApi";
import { useNavigate } from "react-router-dom";


const GalleryList: React.FC = () => {
  const { data: galleryList }: any = useQuery(
    "gallery",
    () => getGallerywithoutpagination(),
    {}
  );
  const first4galleryList = galleryList?.data?.slice(0, 4) || galleryList?.data || [];
  const navigate = useNavigate();

  return (
    <>
      {
        first4galleryList?.length !== 0 && (
          <section className="gallery-outer-wrap">
            <div className="reveal">
              <Container>
                <div className="heading-wrap">
                  <div>
                    <h4 className="main-heading">Gallery</h4>
                  </div>

                </div>
              </Container>

              {/* cards section */}
              <div className="gallery-list-outer-wrap">
                <Container>
                  <Row className="gy-4">
                    {first4galleryList?.map((gallery: CardTypeGallery) => (
                      <GalleryCard props={gallery} key={gallery?.id} url_go="/gallery/1" />
                    ))}
                  </Row>
                  {galleryList?.data && galleryList?.data.length > 4 && (
                    <div className="learn-more-btn mt-5">
                      <Button outline color="primary" className="sm" onClick={() => navigate("/gallery/1")}>
                        View All
                        <i className="icon icon-next-green"></i>
                      </Button>
                    </div>
                  )}
                </Container>
              </div>
            </div>
          </section>
        )
      }
    </>
  );
};

export default GalleryList;
