import React, { useRef } from "react";
import { useQuery } from "react-query";
import { Container, Row, Col } from "reactstrap";
import { getBlogs } from "../../../../Api/WebsiteApi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LikeSubmitHandler } from "./BlogAddMethods";
import CustomPagination from "../../../../Components/Pagination/Pagination";
import { ScrollToContainerTop, formatDateTimeForInput } from "../../../../Utils/helpers/helpers";

const Blogs: React.FC = () => {
  const myDivRef: any = useRef(null);
  const { id } = useParams();
  const { data: blogsList, refetch: blogReftech }: any = useQuery(
    ["award", id],
    () => getBlogs(id),
    {}
  );
  const navigate = useNavigate();
  const setCurrentPageStaff = (navigate: Function, pageNo: any) => {
    navigate(`/blogs/${pageNo}`);
  };

  return (
    <>
      <section className="blog-wrap">
        {/* <div className="reveal"> */}
          {/* cards section */}
          <div className="blog-list-outer-wrap">
            <Container>
              {blogsList?.items?.map((item: any) => {

                return (
                  <>
                    <Row className="my-5 align-items-center justify-content-center">
                      <Col md={2}>
                        <img src={item?.image_url} alt="imageblog"/>
                      </Col>
                      <Col md={8}>
                        <div className="content-outer">
                          <h6>{item?.blog_title}</h6>
                          <p dangerouslySetInnerHTML={{
                            __html: `${item?.blog_body?.slice(0, 250)}`,
                          }}></p>
                          <Link to={`/blog-view/${item?.blog_id}`} style={{ textDecoration: 'none' }}>
                            (<b title="Click here to Read more">Read More ...</b> )
                          </Link>
                        </div>
                        <div className="info-action-outer">
                          <div className="info-wrap">
                            <span>{item?.bloguser_first_name} - </span>
                            <span>  ({formatDateTimeForInput(item?.blog_published_date)})</span>
                          </div>
                          <div className="action-wrap">
                            <Link to={`/blogs/${blogsList?.meta?.currentPage}`}>

                              <i
                                title="Like"
                                className="icon icon-like xl"
                                onClick={() => LikeSubmitHandler(blogReftech, item?.blog_id)}
                              ></i>
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <hr />
                  </>
                );
              })}
            </Container>
          </div>
        {/* </div> */}
      </section>
      {blogsList?.meta?.totalItems !== 0 && (
        <Container>
          <div className="pagination">
            <CustomPagination
              totalItems={blogsList?.meta?.totalItems}
              itemsPerPage={blogsList?.meta?.itemsPerPage}
              activePage={blogsList?.meta?.currentPage}
              disableScrollAfterPageChange
              setCurrentPage={(pageNo: string) => {
                ScrollToContainerTop(myDivRef);
                setCurrentPageStaff(navigate, pageNo);
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};

export default Blogs;
