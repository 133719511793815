import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getMemberid } from "../../../../Api/WebsiteApi";
import MemberInfoCard from "./StateInfoCards";
import MemberListBanner from "./StateListBanner";
import MemberProfile from "./StateProfile";
import Member from "../../Home/Containers/Member";
import StateMember from "../../AboutUs/Containers/state";
import CustomSpinner from "../../../../Components/Button/CustomSpinner";
import { Row } from "reactstrap";

const StateContainer: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>();

  const {
    data: memberList,
    error: memberError,
    isLoading: memberLoading,
  }: any = useQuery(["memberProfile", id], () => getMemberid(id), {});

  return (
    <>
      <MemberListBanner member_name={memberList?.user?.first_name} />
      {memberLoading ? (
        <div>
          <CustomSpinner />
        </div>
      ) : memberList ? (
        <>
          <MemberProfile />
          <MemberInfoCard />
        </>
      ) : (
        <div className="member-list-outer-wrap">
          <Row className="align-items-center justify-content-center mt-5">
            Member Not Updated The Profile
          </Row>
        </div>
      )}
      <StateMember />
    </>
  );
};

export default StateContainer;
