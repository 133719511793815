import React from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { getAboutList } from "../../../../Api/WebsiteApi";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

const AboutUs: React.FC = () => {
  const navigate = useNavigate();
  const { data: AboutList }: any = useQuery(
    "aboutas",
    () => getAboutList(),
    {}
  );

  return (
    <>
      <section className="about-us-main-wrap bottom-image">
        <div className="reveal">
          <Container>
            <Row className="align-items-center justify-content-between">
              <Col md={6}>
                <div className="heading-wrap">
                  <div className="heading">
                    <h4 className="main-heading">{AboutList?.[0]?.title}</h4>
                  </div>
                </div>
                <div className="about-content">
                  <p>
                    {" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: AboutList?.[0]?.body?.slice(0, 925),
                      }}
                    ></div>
                  </p>
                </div>
                <div className="learn-more-btn">
                  <Button
                    outline
                    color="primary"
                    className="sm"
                    onClick={() => navigate("/about-us")}
                  >
                    Learn More
                    <i className="icon icon-next-green"></i>
                  </Button>
                </div>
              </Col>

              <Col md={5}>
                <div className="about-us-banner">
                  <img src="/images/about-banner.png" alt="image" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bottom-image"></div>
      </section>
    </>
  );
};

export default AboutUs;
