import React from "react";

import {
  OuterBanner,
  InfoBanner,
  MultipleCards,
  StateMember,
  Team,
} from "../../Routes/Imports";
import Services from "../Home/Containers/services";
import History from "./Containers/History";
import DoRemember from "./Containers/DoRemember";

const About: React.FC = () => {
  return (
    <>
      <OuterBanner />
      <InfoBanner />
      <MultipleCards />
      <History />
      <Services />
      <DoRemember />
      <Team/>
      {/* <StateMember /> */}
    </>
  );
};
export default About;
