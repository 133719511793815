import React from 'react';
import { 
Container, 
Row, 
Card,
CardBody,
CardTitle,
CardText
} from 'reactstrap';

const MultipleCards:React.FC =()=> {
    return (
        <>
        <section className="counts">
            <div className="count-wrap">
                <Container>
                    <Row className="align-items-center justify-content-center mx-auto">
                        <div className="count-outer">
                            <div className='reveal'>
                                <Card className="count-card">
                                    <CardBody>
                                        <CardTitle tag="h6" className='count-title'>
                                            65 +
                                        </CardTitle>
                                        <CardText className='count-desc'>
                                            Years of experience
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='reveal'>
                                <Card className='count-card'>
                                    <CardBody>
                                        <CardTitle tag="h6" className='count-title'>
                                            100 +
                                        </CardTitle>
                                        <CardText className='count-desc'>
                                            Reviews
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </div>
                        
                            <div className='reveal'>
                                <Card className='count-card'>
                                    <CardBody>
                                        <CardTitle tag="h6" className='count-title'>
                                            20+
                                        </CardTitle>
                                        <CardText className='count-desc'>
                                            Achievements
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </section>
        </>

    );
}

export default MultipleCards;