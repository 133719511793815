import React from 'react'
import {ContactUsBanner,ContactUsForm} from '../../Routes/Imports'
import ResourcesBanner from './Container/ResourcesBanner'
import ResourcesSubCategeories from './Container/ResourcesSubCategories'

const SubCategories:React.FC = () => {
  return (
    <>
    <ResourcesBanner/>
    <ResourcesSubCategeories/>
    </>
  )
}

export default SubCategories
