import React from "react";
import { useQuery } from "react-query";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import { getAboutList } from "../../../../Api/WebsiteApi";

const InfoBanner: React.FC = () => {
  // const [modalOpen, setModalOpen] = useState(false);
  // const toggleModal = () => {
  //   setModalOpen(!modalOpen);
  // };
  const { data: AboutList }: any = useQuery(
    "aboutas",
    () => getAboutList(),
    {}
  );
  return (
    <>
      <section className="about-us">
        <div className="about-us-main-wrap">
          <div className="image-about"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg={8} xs={12}>
                <div className="content-layout">
                  <h6>{AboutList?.[0]?.title}</h6>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: AboutList?.[0]?.body,
                    }}
                  ></div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default InfoBanner;
