import * as Yup from "yup";
import {
  emailSchema,
  phoneSchema,
} from "../../../../Utils/Formik/FormikCommonValidationSchemas";

const today = new Date();
export const MemberValidationSchema = Yup.object().shape({
  present_status: Yup.string().required("Field is required").nullable(),
  working_status: Yup.number().required("Field is required").nullable(),
  member_l_name: Yup.string().required("Field is required").nullable(),
  member_f_name: Yup.string().required("Field is required").nullable(),
  pen_no: Yup.string().required("Field is required").nullable(),
  phone_no: phoneSchema.required("Field is required").nullable(),
  member_whatsapp_number: phoneSchema.required("Field is required").nullable(),
  email: emailSchema.required("Field is required").nullable(),
  member_date_of_birth: Yup.date()
    .required("Field is required")
    .max(
      new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()),
      `Must be at least 18 years old`
    ),
  date_of_joining: Yup.date()
    .min(
      Yup.ref("member_date_of_birth"),
      "Date of Joining must be on or after Date of Birth"
    )
    .required("Date of Joining is required"),
  member_type: Yup.string().required("Field is required").nullable(),
  // join_status: Yup.date().required("Field is required"),
  // employement_status: Yup.string().when("member_type", ([member_type], sch) => {
  //   return member_type === "1"
  //     ? sch.required("Field is required").nullable()
  //     : sch.notRequired();
  // }),

  designation: Yup.string().when(
    "employement_status",
    ([employement_status], sch) => {
      return employement_status === "1"
        ? sch.required("Field is required").nullable()
        : sch.notRequired();
    }
  ),

  date_of_retirement: Yup.date().when("member_type", ([member_type], sch) => {
    return member_type !== "1"
      ? sch.required("Field is required").nullable()
      : sch.notRequired();
  }),

  membership_document: Yup.string().when(
    "member_type",
    ([member_type], sch) => {
      return member_type === "3"
        ? sch.required("Field is required").nullable()
        : sch.notRequired();
    }
  ),
  office: Yup.string().when(
    ["employement_status"],
    ([employement_status], sch) => {
      return employement_status === "1"
        ? sch.required("Field is required").nullable()
        : sch.notRequired();
    }
  ),
  office_category: Yup.string().when(
    "employement_status",
    ([employement_status], sch) => {
      return employement_status === "1"
        ? sch.required("Field is required").nullable()
        : sch.notRequired();
    }
  ),
  seat_id: Yup.string().when(
    "employement_status",
    ([employement_status], sch) => {
      return employement_status === "1"
        ? sch.required("Field is required").nullable()
        : sch.notRequired();
    }
  ),
  block_id: Yup.string().when(
    "employement_status",
    ([employement_status], sch) => {
      return employement_status === "1"
        ? sch.required("Field is required").nullable()
        : sch.notRequired();
    }
  ),
  district_id: Yup.string().required("Field is required").nullable(),
  check: Yup.boolean().oneOf([true], "Field is required"),
});

export const AddMemberinitialValues = {
  employement_status: "",
  present_status: "",
  working_status: "",
  member_f_name: "",
  member_l_name: "",
  pen_no: "",
  office: "",
  office_category: "",
  email: "",
  phone_no: "",
  member_whatsapp_number: "",
  post_graduation: "",
  post_graduation_year_of_passing: "",
  doctoral_degree: "",
  bachelor_degree_year_of_psssing: "",
  bachelor_degree: "",
  any_other_education: [
    { degree: "", school_college: "", start_date: "", end_date: "" },
  ],
  member_date_of_birth: "",
  designation: "",
  member_type: "",
  role: [],
  date_of_joining: "",
  date_of_retirement: "",
  join_status: "",
  membership_document: "",
  // seat_id: [""],
  seat_id: "",
  block_id: "",
  district_id: "",
  check: false,
};
