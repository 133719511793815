import { toast } from "react-toastify";
import { api } from "../../../../Api/Api";
import * as Yup from "yup";

export const handlecontactSubmit = (values: any, actions: any) => {

    api
        .post(`/open/about-us`, values, true)
        .then(async function ([success, response]: any) {
            toast.success("Thank you for reaching out to AOAOK. We have received your message and will be in touch.");
        })
        .catch((err) => {
            toast.error("Unexpected Error Occurred");
        })
        .finally(() => {
            actions.resetForm();
            actions.setSubmitting(false);
        });
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const contactUsValidationSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string()
        .matches(emailRegex, 'Invalid email format') 
        .required('Email is required'),
    phone_no: Yup.string()
        .required("Phone number is required")
        .matches(/^[0-9+-]+$/, "Invalid phone number"),
    message: Yup.string()
        .required("Field is required")
        .min(10, "The minimum 10 characters."),
});

