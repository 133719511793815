import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LayoutContainer from '../../../Components/Layouts';
import { childrenType } from '../../../Type';
import { Container,Row,Button} from 'reactstrap';
import { useNavigate } from "react-router-dom";


const ErrorPage:React.FC= ( ) => {
  const navigate = useNavigate();

    return (
       <>
       <div className='errorpage'>
            <div className='error-text-wrap text-center'>
                <h2 className='error-text'>Oops!</h2>
                <h2 className='error-text'>Page Not Found</h2>
                <Button
                    outline
                    color="white"
                    className="sm my-3"
                    onClick={() => navigate("/")}
                  >
                    Back to home
                  </Button>
            </div>
       </div>
            
       </>
    )
}

export default ErrorPage