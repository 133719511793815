import React from 'react'
import StateIndexListBanner from './Containers/StateListBanner'
import StateIndexList from './Containers/StateList'


const StateIndex:React.FC = () => {
  return (
    <>
    <StateIndexListBanner/>
    <StateIndexList/>
    </>
  )
}

export default StateIndex
