import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import { Button, Container, Input, InputGroup, Row } from "reactstrap";
import { CardTypeMagazine } from "../../../../Type/index";
import { useNavigate, useParams } from "react-router-dom";
import Magazine from "../../../../Components/Cards/magazine";
import { getMagazine } from "../../../../Api/WebsiteApi";
import CustomPagination from "../../../../Components/Pagination/Pagination";
import { ScrollToContainerTop } from "../../../../Utils/helpers/helpers";

const MagazineList: React.FC = () => {
  const myDivRef: any = useRef(null);
  const { id } = useParams();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const { data: magazineList, refetch: magazineListReftech }: any = useQuery(
    ["getMagazine", id, searchQuery, date],
    () => getMagazine(id, searchQuery, date),
    {}
  );

  const navigate = useNavigate();
  const setCurrentPageStaff = (navigate: Function, pageNo: any) => {
    navigate(`/magazine/${id}`);
  };

  const handleCardClick = (magazineId: any) => {
    navigate(`/magview/${magazineId}`);
  }
 
  const handleDateSearch = (
    e: any,
    setDate: Function
  ) => {
    if (e?.target?.value?.length > 0) {
      setDate(e?.target?.value);
    } else {
      setDate("");
    }
  };

  const handleMagazineSearch = (
    e: any,
    setSearchQuery: Function,
    navigate: Function,
  ) => {
    if (e?.target?.value?.length >= 1) {
      navigate(`/magazine/1`);
      setSearchQuery(e?.target?.value);
    } else {
      setSearchQuery("");
    }
  };
  return (
    <>
      <section className="magazine-outer-wrap">
        <Container>
          {/* <Row> */}
            <div className="search-bar-full-wrap mb-2">
              <div className="search-bar">
                <InputGroup>
                  <Input
                    placeholder="Search Magazines"
                    onChange={(e) =>  handleMagazineSearch(e, setSearchQuery,navigate)}
                  />
                  <span className="icon-container">
                    <i className="icon xl icon-search"></i>
                  </span>
                </InputGroup>
              </div>

              <div className="right-side-wrap">
                  <InputGroup>
                    <Input
                      type="date"
                      placeholder="Search by Date..."
                      onChange={(e) => handleDateSearch(e, setDate)}

                    />
                  </InputGroup>
                  {/* <div className="button-wrap">
                  <Button
                    color="primary" className="sm"
                    onClick={() => {
                      setDate("");
                      setSearchQuery("");
                    }}
                  >
                    Reset
                  </Button>
                </div> */}
              </div>
            </div>

          {/* </Row> */}
        </Container>

        {/* <div className="reveal"> */}
          <Container>
            {/* cards section */}
            <div className="magazine-list-outer-wrap">
              <Row className="gy-4">
                {magazineList?.data?.map((magazine: CardTypeMagazine) => (
                  <Magazine props={magazine} key={magazine?.id} url_go="" handleCardClick={() => handleCardClick(magazine?.id)} />
                ))}
              </Row >
            </div >
          </Container >
        {/* </div > */}
      </section >

      {magazineList?.meta?.totalItems !== 0 && (
          <Container >
          <div className="pagination">
            <CustomPagination
              totalItems={magazineList?.meta?.totalItems}
              itemsPerPage={magazineList?.meta?.itemsPerPage}
              activePage={magazineList?.meta?.currentPage}
              disableScrollAfterPageChange
              setCurrentPage={(pageNo: string) => {
                ScrollToContainerTop(myDivRef);
                setCurrentPageStaff(navigate, pageNo);
              }}
            />
        </div>
        </Container >
      )}
    </>
  );
};

export default MagazineList;
