import axios from "axios";
import React, { useEffect, useState } from "react";
import { BaseUrl } from "../../../Api/BaseUrl";
import { Button, Col, Container, Label, Row } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getPaymentUser } from "../../../Api/WebsiteApi";
import PaymentSuccess from "./PaymentSuccess";
import CustomSpinner from "../../../Components/Button/CustomSpinner";

interface Item {
  itemId: string;
  amount: string;
  comAmt: string;
}

interface CustomStyle {
  PRIMARY_COLOR_CODE: string;
  SECONDARY_COLOR_CODE: string;
  BUTTON_COLOR_CODE_1: string;
  BUTTON_COLOR_CODE_2: string;
}

interface Features {
  enableAbortResponse: boolean;
  enableExpressPay: boolean;
  enableInstrumentDeRegistration: boolean;
  enableMerTxnDetails: boolean;
  enableNewWindowFlow: boolean;
  payDetailsAtMerchantEnd?: boolean;
  payWithSavedInstrument?: boolean;
  showDownloadReceipt?: boolean;
}

interface ConsumerData {
  deviceId: string;
  token: string;
  returnUrl: string;
  responseHandler: (res: any) => void;
  paymentMode: string;
  merchantLogoUrl: string;
  merchantId: string;
  currency: string;
  consumerId: string;
  consumerMobileNo: string;
  consumerEmailId: string;
  txnId: string;
  items: Item[];
  customStyle: CustomStyle;
}

interface RequestJson {
  features: Features;
  consumerData: ConsumerData;
}

const PyamentCheckoutPage: React.FC = () => {
  const [paymentStatus, setPaymentStatus] = useState(false);
  const { token }: any = useParams();
  const navigate = useNavigate();
  const url = new URL(window.location.href); // Use window.location.href to get the full URL
  const domain = url.origin;

  const {
    data: user,
    error: userError,
    isLoading: userLoading,
    refetch: userListRefetch,
  }: any = useQuery([`website-payment-user-details${token}`, token], () => getPaymentUser(token), {});

  const handleResponse = (res: any) => {
    // console.log("msg", res?.paymentMethod?.paymentTransaction?.statusMessage);
    // console.log("code", res?.paymentMethod?.paymentTransaction?.statusCode);
    console.log(res,"res");

    if (
      res &&
      res.paymentMethod &&
      res.paymentMethod.paymentTransaction &&
      res.paymentMethod.paymentTransaction.statusCode === "0300"
    ) {
      // success block
      Payment_ststus_update(res);
      console.log("success");

    } else if (
      res &&
      res.paymentMethod &&
      res.paymentMethod.paymentTransaction &&
      res.paymentMethod.paymentTransaction.statusCode === "0392"
    ) {
      // retry close
      Payment_ststus_update(res);
      console.log("retry section");

    } else if (
      res &&
      res.paymentMethod &&
      res.paymentMethod.paymentTransaction &&
      res.paymentMethod.paymentTransaction.statusCode === "0398"
    ) {
      Payment_ststus_update(res);
      // initiated block
      console.log("Initiated section");

    } else {
      
      Payment_ststus_update(res);
      setPaymentStatus(false);
      console.log("Error section");
      // error block
    }
  };

  const handleClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    check_cout();
  };

  const check_cout = () => {
    const reqJson: RequestJson = {
      features: {
        enableAbortResponse: true,
        enableExpressPay: true,
        enableInstrumentDeRegistration: true,
        enableMerTxnDetails: true,
        enableNewWindowFlow: true,
      },
      consumerData: {
        deviceId: "WEBSH2",
        token: user?.paymentDetails?.token,
        returnUrl:"",
        responseHandler: handleResponse,
        paymentMode: "all",
        merchantLogoUrl:
          "https://www.paynimo.com/CompanyDocs/company-logo-vertical.png",
        merchantId: user?.paymentDetails?.merchantId,
        currency: "INR",
        consumerId: user?.paymentDetails?.consumerId,
        consumerMobileNo: user?.paymentDetails?.consumerMobileNo,
        consumerEmailId: user?.paymentDetails?.consumerEmailId,
        txnId: user?.paymentDetails?.txnId,
        items: [
          {
            itemId: "first",
            amount: user?.paymentDetails?.totalamount.toString(),
            comAmt: "0",
          },
        ],
        customStyle: {
          PRIMARY_COLOR_CODE: "#45beaa",
          SECONDARY_COLOR_CODE: "#FFFFFF",
          BUTTON_COLOR_CODE_1: "#2d8c8c",
          BUTTON_COLOR_CODE_2: "#FFFFFF",
        },
      },
    };
    (window as any).$.pnCheckout(reqJson);
    if (reqJson.features.enableNewWindowFlow) {
      (window as any).pnCheckoutShared.openNewWindow();
    }
  };
  useEffect(() => {
    if (user?.success === false) {
      toast.error("Payment link expired inform Admin");
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    // Include the external scripts in the head of your HTML file
    const script1: any = document.createElement("script");
    script1.src =
      "https://www.paynimo.com/paynimocheckout/client/lib/jquery.min.js";
    script1.type = "text/javascript";
    document.head.appendChild(script1);

    const script2: any = document.createElement("script");
    script2.src =
      "https://www.paynimo.com/paynimocheckout/server/lib/checkout.js";
    script2.type = "text/javascript";
    document.head.appendChild(script2);

    return () => {
      // Clean up the scripts when the component unmounts
      if (document.head.contains(script1)) {
        document.head.removeChild(script1);
      }

      // Remove script2 from the head during component unmount
      if (document.head.contains(script2)) {
        document.head.removeChild(script2);
      }
    };
  }, []);
  const Payment_ststus_update = async (data: any) => {
    try {
      const response = await axios.post(`${BaseUrl}/payment/order-update/${token}`,data);
      if (response?.data?.success === true) {
        if (data &&
          data.paymentMethod &&
          data.paymentMethod.paymentTransaction &&
          data.paymentMethod.paymentTransaction.statusCode === "0300") {
          // Success 
          setPaymentStatus(true);
          navigate(`/payment-success`);
        }
        // Handle new window flow if needed
      } else {
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  };
  return (
    <>
      {paymentStatus === true ? (
        <PaymentSuccess />
      ) : (
        <>
          <div className="top-img">
            <section className="payment-wrapper bottom-img">
              <div className="top-img"></div>
              <div className="reveal">
                <Container>
                  <Row
                    className="align-items-center justify-content-center"
                    style={{
                      height: "70vh",
                    }}
                  >
                    {userLoading && <div className="text-center">
                      <CustomSpinner />
                    </div>}
                    {user && <Col md={4} className="p-sm-0">
                      <div className="payment-form-wrap">
                        <div className="heading">
                          <h3>Membership Payment</h3>
                        </div>
                        <div>
                          <h6>
                            {user?.membershipOrder?.user?.first_name}{" "}
                            {user?.membershipOrder?.user?.last_name}
                          </h6>
                          <span>{user?.membershipOrder?.user?.member?.phone_no}</span>
                        </div>
                        <div className="button-wrap">
                          <Button
                            id="btnSubmit"
                            onClick={handleClick}
                            disabled={paymentStatus}
                            className="sm primary"
                            color="primary"
                          >
                            Pay ₹{user?.membershipOrder?.total_amount}
                          </Button>
                        </div>
                      </div>
                    </Col>}
                  </Row>
                </Container>
              </div>
              <div className="bottom-img"></div>
            </section>
          </div>
        </>
      )}
    </>
  );
};

export default PyamentCheckoutPage;
