import PDFViewer from "../../../Components/PDFViewer/PDFViewer";
import { routeType } from "../../../Type";
import About from "../../Public/AboutUs/About";
import BiloIndex from "../../Public/AboutUs/Containers/Bilo/BiloIndex";
import HistoryIndex from "../../Public/AboutUs/Containers/History/HistoryIndex";
import AccomodationIndex from "../../Public/Accomodation";
import BlogsView from "../../Public/Blogs/Containers/BlogView";
import ContactUs from "../../Public/ContactUs/ContactUs";
import DownloadIndex from "../../Public/Download";
import DownloadSubcategoryList from "../../Public/Download/Containers/DownloadSubcategorylist";
import ForgotPasswordIndex from "../../Public/ForgotPassword";
import GallerycardList from "../../Public/Gallery/Containers/gallerycardslist";
import Member from "../../Public/Home/Containers/Member";
import Login from "../../Public/Login/Login";
import MagazineIndex from "../../Public/Magazine/Magazine";
import TeamsIndex from "../../Public/Member/Team";
import OfficeIndex from "../../Public/Offices";
import PyamentCheckoutPage from "../../Public/PaymentGateWay";
import PaymentSuccess from "../../Public/PaymentGateWay/PaymentSuccess";
import OtherfundCheckoutPage from "../../Public/PaymentGateWay/otherfund";
import OtherPaymentSuccess from "../../Public/PaymentGateWay/otherfund/PaymentSuccess";
import RegisterSuccess from "../../Public/Register/AddMemberMethods/RegisterSuccess";
import Register from "../../Public/Register/Register";
import Resources from "../../Public/Resources/Resources";
import SubCategories from "../../Public/Resources/SubCategories";
import StateIndex from "../../Public/StateMembers";
import StateContainer from "../../Public/StateMembers/Containers/StateContainer";
import News from "../../Public/news/news";
import {
  MemberContainer,
  HomeIndex,
  PrivacyPolicy,
  TermsAndConditions,
  GalleryIndex,
  BlogIndex,
  MagazineViewModal,
} from "../Imports";

export const publicRoutes: routeType[] = [
  {
    path: "/",
    element: <HomeIndex />,
  },
  {
    path: "/about-us",
    element: <About />,
  },

  {
    path: "/members",
    element: <Member />,
  },

  {
    path: "/offices/:currentPage",
    element: <OfficeIndex />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />,
  },

  {
    path: "/gallery/:currentPage",
    element: <GalleryIndex />,
  },

  {
    path: "/resources/:currentPage",
    element: <Resources />,
  },
  {
    path: "/resources/subcategories/:id",
    element: <SubCategories />,
  },

  {
    path: "/blogs/:id",
    element: <BlogIndex />,
  },
  {
    path: "/magazine/:id",
    element: <MagazineIndex />,
  },
  {
    path: "/memberspage",
    element: <TeamsIndex />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordIndex />,
  },
  {
    path: "/members/:currentPage",
    element: <TeamsIndex />,
  },
  {
    path: "/members-profile/:id",
    element: <MemberContainer />,
  },
  {
    path: "/statemember-profile/:id",
    element: <StateContainer />,
  },
  {
    path: "/downloads/:currentPage",
    element: <DownloadIndex />,
  },
  {
    path: "/blog-view/:id",
    element: <BlogsView />,
  },
  {
    path: "/gallerydetail/:id",
    element: <GallerycardList />,
  },
  {
    path: "/statemember/:currentPage",
    element: <StateIndex />,
  },

  {
    path: "/pdf",
    element: <PDFViewer />,
  },
  {
    path: "/history",
    element: <HistoryIndex />,
  },
  {
    path: "/bylaw",
    element: <BiloIndex />,
  },
  {
    path: "/membership-checkout/:token",
    element: <PyamentCheckoutPage />,
  },

  {
    path: "/otherfund-checkout/:token",
    element: <OtherfundCheckoutPage />,
  },

  {
    path: "/accomodation/:currentPage",
    element: <AccomodationIndex />,
  },

  {
    path: "/magview/:id",
    element: <MagazineViewModal />,
  },

  {
    path: "/payment-success",
    element: <PaymentSuccess />,
  },

  {
    path: "/otherfund-success",
    element: <OtherPaymentSuccess />,
  },
  {
    path: "/register-success",
    element: <RegisterSuccess />,
  },
  {
    path: "/news/:id",
    element: <News />,
  },
  {
    path: "/download/subcategory/:id",
    element: <DownloadSubcategoryList />,
  },
];
