import React, { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import {
  Container,
  InputGroup,
  Input,
  ListGroupItemHeading,
  ListGroupItemText,
  Button,
  ListGroupItem,
  Label,
} from "reactstrap";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import { getDownloads, getDownloadsSub } from "../../../../Api/WebsiteApi";
import DownloadViewModal from "./DownloadViewModal";
import { useNavigate, useParams } from "react-router-dom";
import CustomPagination from "../../../../Components/Pagination/Pagination";
import { Link } from "react-router-dom";
import { api } from "../../../../Api/Api";

const Download: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [Sub_id, setSubId] = useState([]);
  const { currentPage = "1" } = useParams();
  const [data, setData] = useState<any>([]);
  
  // const { data: downloadList }: any = useQuery(
  //   // eslint-disable-next-line @typescript-eslint/no-use-before-define
  //   ["downloadList", searchTerm],
  //   () => getDownloads(searchTerm, currentPage),
  //   {}
  // );
  useEffect(() => {
    getDownloads_(currentPage, searchTerm);
  }, [searchTerm, currentPage]);
  const getDownloads_ = (page: any, searchTerm: any) => {
    api
      .get(`/downloads?page=${page}&perPage=10&search=${searchTerm}`, true)
      .then(async function ([success, response]) {
        response?.data?.data?.map((item: any) => {
          item.is_check = false;
        });
        setData(response?.data?.data);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const { data: subdownloadList }: any = useQuery(
    ["downloadList", Sub_id],
    () => (Sub_id ? getDownloadsSub(Sub_id) : ""),
    {
      onSuccess: () => console.log("Sub_id", { data: subdownloadList }),
    }
  );

  const [view, setView] = useState(false); // For viewing
  const [download, setDownload] = useState(false); // For downloading
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const [url, setUrl] = useState("");
  const myDivRef: any = useRef(null);
  const toggleModal = (content: string, isView: boolean) => {
    setIsOpen(true);
    setModalContent(content);

    if (isView) {
      setView(true);
      setDownload(false);
    } else {
      setView(false);
      setDownload(true);
    }
  };
  const ScrollToContainerTop = (ref: any) => {
    if (ref?.current) {
      ref?.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };
  const navigate = useNavigate();
  const setCurrentPageStaff = (navigate: Function, pageNo: any) => {
    navigate(`/downloads/${pageNo}`);
  };
  const handleDownloadSearch = (
    e: any,
    setSearchTerm: Function,
    navigate: Function
  ) => {
    if (e?.target?.value?.length >= 1) {
      navigate(`/downloads/1`);
      setSearchTerm(e?.target?.value);
    } else {
      setSearchTerm("");
    }
  };

  const check_data = (itemIndex: any) => {
    const neData: any = [...data];
    console.log(itemIndex, "itemIndex");
    neData[itemIndex].is_check = !neData[itemIndex].is_check;
    setData(neData);
  };

  const [sub_data, setSubData] = useState([]);
  const getAllSubSubcategory = async (main_id: any, id: string) => {
    const [, datas] = await api.get(
      `/download-categories/${main_id}?parent_id=${id}`,
      true
    );
    setSubData(datas);
  };
  return (
    <>
      {/* <h4 className="text-center py-4 text-primary mb-0">COMING SOON</h4> */}

      <section className="download-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap mb-5">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search Downloads"
                  onChange={(e) =>
                    handleDownloadSearch(e, setSearchTerm, navigate)
                  }
                />
                <span className="icon-container">
                  <i className="icon xl icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="button-wrap">
              <Button
                color="primary"
                className="sm"
                onClick={() => {
                  setSearchTerm("");
                }}
              >
                Reset
              </Button>
            </div>
          </div>
        </Container>
        {/* <div className="reveal"> */}
        <Container>
          <div className="download-list-wrap">
            {data &&
              data?.map((category: any, i: any) => (
                <div className="download-list-inner" key={category?.id}>
                  <div className="download-list-item">
                    <ListGroupItemHeading>
                      {category?.category_name}
                    </ListGroupItemHeading>
                    <ListGroupItemText>
                      {category && (
                        <>
                          <ul key={category?.id}>
                            <li>
                              <p>{category?.name}</p>

                              <div className="icon-wrap">
                                <span
                                  onClick={() => {
                                    // check_data(i);
                                    navigate(
                                      `/download/subcategory/${category?.id}?category=${category?.name}`
                                    );
                                    setSubId(category?.id);
                                  }}
                                >
                                  <i
                                    title="View More"
                                    className="icon icon-down xl"
                                  ></i>
                                </span>
                                {/* <Link
                                  to={category?.document_file_url}
                                  target="_blank"
                                >
                                  <i
                                    title="View Document"
                                    className="icon icon-eye-open xl"
                                  ></i>
                                </Link>

                                <i
                                  title="Download Document"
                                  onClick={() => {
                                    toggleModal(category?.name, false);
                                    setUrl(category?.document_file_url);
                                  }}
                                  className="icon xl icon-download"
                                ></i> */}
                              </div>
                              {category?.is_check === true && (
                                <>
                                  {subdownloadList?.data?.items?.map(
                                    (ite: any) => {
                                      return (
                                        <>
                                          <div className="list-wrapper">
                                            <ul>
                                              <li>{ite?.name}</li>
                                              {ite?.subcategories?.map(
                                                (ui: any) => {
                                                  return (
                                                    <>
                                                      <li>{ui?.name}</li>
                                                    </>
                                                  );
                                                }
                                              )}
                                              <ul>
                                                <li></li>
                                              </ul>
                                            </ul>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              )}
                            </li>
                          </ul>
                        </>
                      )}
                    </ListGroupItemText>
                  </div>
                </div>
              ))}
          </div>
        </Container>
        {/* </div> */}
        {data?.data?.meta?.totalItems !== 0 && (
          <Container>
            <div className="pagination">
              <CustomPagination
                totalItems={data?.data?.meta?.totalItems}
                itemsPerPage={data?.data?.meta?.itemsPerPage}
                activePage={data?.data?.meta?.currentPage}
                disableScrollAfterPageChange
                setCurrentPage={(pageNo: string) => {
                  ScrollToContainerTop(myDivRef);
                  setCurrentPageStaff(navigate, pageNo);
                }}
              />
            </div>
          </Container>
        )}
      </section>
      <ModalContainer isOpen={isOpen} setIsopen={setIsOpen} title="Download">
        <DownloadViewModal
          setIsOpen={setIsOpen}
          data={url}
          all_data={data?.data?.data}
          view={view}
          download={download}
        />
      </ModalContainer>
    </>
  );
};

export default Download;
