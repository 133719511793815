import React from "react";
import { Banner, Contact, Team, Testimonials } from "../../Routes/Imports";
import Services from "./Containers/services";
import AboutUs from "./Containers/aboutUs";
import Blogs from "./Containers/blogs";
import GalleryList from "./Containers/gallery";

const HomeIndex: React.FC = () => {
  
  return (
    <>
    
      <div className="home-page-full-wrap">
        <Banner />
        <AboutUs />
        <Team />
        <Services />
        <Testimonials />
        <GalleryList />
        <Blogs />
        <Contact />
       
      </div>
     
    </>
  );
};

export default HomeIndex;
