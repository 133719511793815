import React from 'react'
import { Container, Col, ListGroup, ListGroupItem,ListGroupItemHeading, ListGroupItemText } from 'reactstrap';

const BiloLIst = () => {
  return <>
            <section className="bylaw-outer-wrap">
                <div className="title-wrap">
                    <h4>THE BY-LAWS OF</h4>
                    <h5>The Association of Agricultural Officers’ Kerala</h5>
                    <span>(Recognized as per G.O(MS) 168/PD dtd 19.02.1958)</span>
                    <p>Approved by the 42nd AGB</p>
                </div>
                <div className="bylaw-content-wrap">
                    <Container>
                        <div className="bylaw-list-warp">
                            <Col lg={12} md={12} sm={12}>
                                <ListGroup numbered>

                                    {/* Law 1 */}
                                    <ListGroupItem>
                                        <ListGroupItemHeading>
                                            Name :
                                        </ListGroupItemHeading>
                                        <ul>
                                            <li>
                                                <li>The name of the Association shall be “Association of Agricultural Officers’, Kerala”.</li>
                                                <li>The Association shall be affiliated to the “All India Federation of Agricultural Technologists”.</li>
                                                <li>Flag – The flag of the Association shall be bicolored with white bottom
                                                and bottle green top diagonally starting from the left and emblem of
                                                the Association in the middle. The size of the flag shall be 3:2.</li>
                                            </li>
                                        </ul>
                                    </ListGroupItem>

                                    {/* Law 2 */}
                                    <ListGroupItem>
                                        <ListGroupItemHeading>
                                            Objects :
                                        </ListGroupItemHeading>
                                        <ul>
                                            <li>
                                            The objects of the Association shall be to foster a feeling of brotherhood and spirit de corps among the members.
                                            </li>
                                            <li>
                                            To promote interest in service among them                                        
                                            </li>
                                            <li>
                                            To encourage research and free discussion on Agricultural problems
                                            </li>
                                            <li>
                                            To associate with cultivators for solving their problems in Agriculture
                                            and also to solve the problems of members with active assistance of
                                            the farming community.
                                            </li>
                                            <li>
                                            To deal with such other matters as may be considered necessary to the common welfare of the members.
                                            </li>
                                            <li>
                                            A strike or threat of a strike a against the Government shall never be used as a means of achieving any of the purposes of the Association.
                                            </li>
                                        </ul>
                                    </ListGroupItem>

                                    {/* Law 3 */}
                                    <ListGroupItem>
                                        <ListGroupItemHeading>
                                            Head Quarters :
                                        </ListGroupItemHeading>
                                            <ul>
                                                <li>
                                                The Head Quarters of the Association shall be at the State capital.    
                                                </li>
                                            </ul>
                                    </ListGroupItem>

                                    {/* Law 4 */}
                                    <ListGroupItem>
                                        <ListGroupItemHeading>
                                            Jurisdiction :
                                        </ListGroupItemHeading>
                                        <ListGroupItemText>
                                            <ul>
                                                <li>
                                                The sphere of activities of the Association shall extent over the whole State of Kerala.    
                                                </li>
                                                <li>
                                                The Association shall have branches in every Revenue District.
                                                </li>
                                            </ul>
                                        </ListGroupItemText>
                                    </ListGroupItem>

                                    {/* Law 5 */}
                                    <ListGroupItem className="law-container">
                                        <ListGroupItemHeading>
                                            Membership 
                                        </ListGroupItemHeading>
                                            <ol className="lower-alpha">
                                                <ListGroupItemHeading>
                                                    <li className="head">
                                                        Membership :
                                                    </li>
                                                </ListGroupItemHeading>                                                
                                                <ul>
                                                    <li>
                                                    The members of the Association shall be Agricultural Officers of the
                                                    Agricultural Development and Farmers Welfare Department of and above the
                                                    rank of Agricultural Officers possessing a basic degree in Agriculture,
                                                    Horticulture. Officers whose servicers are temporarily lent to other departments
                                                    or institutions are also eligible for membership. Members of the Association are
                                                    not permitted to become members of any other categorical service organization in
                                                    the State.
                                                    </li>
                                                </ul>

                                                <ListGroupItemHeading>
                                                    <li className="head">
                                                        Life  Membership :
                                                    </li>
                                                </ListGroupItemHeading>
                                                <ul>
                                                    <li>
                                                    No life membership will be provided hereafter ,however the existing Life
                                                    Members shall continue with the existing benefits. Publications including
                                                    Association Diary of each year brought out by the Association shall be supplied
                                                    free of cost to the life members.
                                                    </li>
                                                </ul>

                                                <ListGroupItemHeading>
                                                    <li className="head">
                                                        Associate  Membership :
                                                    </li>
                                                </ListGroupItemHeading>
                                                <ul>
                                                    <li>
                                                    Members of the association retiring from service shall be eligible to enroll as
                                                    associate members on payment of Rs.100/- annually. Life members shall continue
                                                    a Associate members even after retirement. However they do not have any voting
                                                    power.
                                                    </li>
                                                </ul>
                                            </ol>
                                    </ListGroupItem>

                                    {/* Law 6 */}
                                    <ListGroupItem>
                                        <ListGroupItemHeading>
                                            Admission :
                                        </ListGroupItemHeading>
                                        <ListGroupItemText>
                                            <ul>
                                                <li>
                                                Application for the admission shall be sent to the Secretary of the concerned
                                                branch in the prescribed form (appendix 1) along with the prescribed fee. In
                                                ordinary course, the applicant shall be enrolled as a member. But the District
                                                Committee reserves the right to reject an application for membership in case it
                                                feels that the particular applicants’ admission will be detrimental to the interests
                                                of the Association. But the applicant shall have the right for appeal to the branch
                                                general body. In case the branch general body rejects he/she shall have the right
                                                for appeal to the State President who will take a final decision in consultation with
                                                State Committee/Council. The branch secretaries shall forward the membership
                                                application duly endorsed to the General Secretary who shall be the custodian of
                                                such applications.   
                                                </li>
                                            </ul>
                                        </ListGroupItemText>
                                    </ListGroupItem>

                                    {/* Law 7 */}
                                    <ListGroupItem>
                                        <ListGroupItemHeading>
                                            Official Years :
                                        </ListGroupItemHeading>
                                        <ListGroupItemText>
                                            <ul>
                                                <li>
                                                The official year of the Association shall be the Calendar year.
                                                </li>
                                            </ul>
                                        </ListGroupItemText>
                                    </ListGroupItem>

                                    {/* Law 8 */}
                                    <ListGroupItem className="law-container">
                                        <ListGroupItemHeading>
                                            Cessation of membership :
                                        </ListGroupItemHeading>
                                        <ListGroupItemText className="top-text">
                                           <p>The membership of a particular member shall be terminated by any of the
                                            following reasons:-</p> 
                                        </ListGroupItemText>
                                        <ListGroupItemText>
                                            <ul>
                                                <li>
                                                By non-payment of annual subscription before 30th June of every year.
                                                Such persons shall be readmitted only after realizing all the arrears and
                                                only with the permission of the Branch Executive Committee.
                                                </li>
                                                <li>
                                                By removal from the Association by the State Committee for gross
                                                misconduct and or anti Association activities on the part of the
                                                member.
                                                </li>
                                                <li>
                                                By removal from membership by the State Committee on holding
                                                membership in any other categorical service organizations of the State.
                                                </li>
                                                <li>
                                                By resigning in writing.
                                                </li>
                                                <li>
                                                By retirement from service.
                                                </li>
                                            </ul>
                                        </ListGroupItemText>
                                    </ListGroupItem>

                                    {/* Law 9 */}
                                    <ListGroupItem>
                                        <ListGroupItemHeading>
                                            Membership Fee and Annual Subscription :
                                        </ListGroupItemHeading>
                                        <ListGroupItemText>
                                            <ul>
                                                <li>
                                                The admission fee shall be Rs.100/- and annual subscription shall be
                                                Rs.1500/- inclusive of annual subscription towards AIFAT to which the
                                                Association is affiliated. Members shall be entitled to get regular publication if
                                                any and association diary free of cost. Annual subscription of Rs.1500/- shall be
                                                paid before 31st March on every year. A grace period of 3 months i.e. up to 30th
                                                June is allowed for the payment of annual subscription of Rs.1500/- along with a
                                                fine of Rs.10/- per month. A member failing to remit annual subscription on or
                                                before 30th June shall cease to be a member from 1st July onwards.
                                                </li>
                                            </ul>
                                        </ListGroupItemText>
                                    </ListGroupItem>

                                    {/* Law 10 */}
                                    <ListGroupItem>
                                        <ListGroupItemHeading>
                                            Patron :
                                        </ListGroupItemHeading>
                                        <ListGroupItemText>
                                            <ul>
                                                <li>
                                                The Director of Agriculture will be invited to the patron of the Association
                                                so long as he is an officer in the Agricultural Department with a basic degree in
                                                Agriculture. If the Director of Agriculture is not having Bsc Agriculture as
                                                qualification,the senior most Additional Director of Agriculture with membership
                                                will be the patron of the Association.
                                                </li>
                                            </ul>
                                        </ListGroupItemText>
                                    </ListGroupItem>

                                    {/* Law 11 */}
                                    <ListGroupItem className="law-container">
                                        <ListGroupItemHeading>
                                            Management and Administration :
                                        </ListGroupItemHeading>
                                        <ol className="upper-alpha">
                                            <ListGroupItemHeading>
                                                <li className="head">
                                                    i) District Committee :
                                                </li>
                                            </ListGroupItemHeading>      
                                            <ListGroupItemText className="top-text">
                                                <p>The administration of the branches will be carried out by a district committee
                                                consisting of a Branch President, Vice President, Secretary, Joint Secretary a
                                                Treasurer, Sub Editor, Kannimannu, Assistant Secretaries, 2 State Council
                                                Members and one web manager.</p>
                                                <p>The District Committee may sanction money for specific purpose from the
                                                fund available with the branched. The District Committee shall use the receipt
                                                books supplied by the State Treasurer and shall not print and use receipt books of
                                                its own.
                                                </p>
                                            </ListGroupItemText> 

                                            <ul>
                                                <li>
                                                Assistant Secretaries shall be one each for every block area and one for Vikas Bhavan (Directorate).   
                                                </li>
                                                <li>
                                                The branch general body consisting of the members of the branch shall
                                                meet once in two months. There shall be no quorum. Extra ordinary
                                                meetings can be convened at short notice.
                                                </li>
                                                <li>
                                                In the event of failure of the branch secretary to hold the branch general body meetings or to carry out the routine activities of the
                                                branch, the members of the branch, not less than 7 in number by a joint
                                                letter request the Joint Secretary to convene the general body of the
                                                branch and the joint secretary shall convene the meeting issuing 10
                                                days advance notice to the members of the branch. Such special
                                                general body meetings shall have all the powers of the branch general
                                                body convened in the ordinary course.
                                                </li>
                                            </ul>

                                            <ListGroupItemHeading>
                                                <li className="head">
                                                    State Council :
                                                </li>
                                            </ListGroupItemHeading>  
                                            <ul>
                                                <li>
                                                The State Council shall be the policy making body of the Association. It
                                                shall consists of branch committee members, state office bearers, members of the
                                                Association holding office in the All India Federation, if any, and the outgoing
                                                State President, General Secretary and State Treasurer. The State Council shall
                                                meet once in 3 months ordinarily on 7 days advance notice. It shall also have no
                                                specific quorum. Extra ordinary state council meeting if found necessary can be
                                                convened by the General secretary on the advice of State committee, State
                                                president on short notice or even by announcement through press/sms/e-mail
                                                </li>
                                            </ul>

                                            <ListGroupItemHeading className="mt-2">
                                                Functions :
                                            </ListGroupItemHeading>
                                            <ul>
                                                <li>
                                                The State Council shall frame the policy and programmes of the
                                                association based on the decisions of the annual general body. It shall
                                                advice the State Committee on important matters.
                                                </li>
                                                <li>
                                                The State Council shall consider and approve the annual reports,
                                                audited accounts, annual budget and other reports to be presented in
                                                the Annual General Body for adoption.
                                                </li>
                                                <li>
                                                The State Council shall consider all resolutions received from the
                                                branches or members before they are presented in the annual general
                                                body.
                                                </li>
                                            </ul>    

                                            <ListGroupItemHeading>
                                                <li className="head">
                                                    All resolutions either passed or rejected by the branch committee or
                                                    branch general body shall be presented in annual general body only
                                                    through the State Council. State Committee :
                                                </li>
                                            </ListGroupItemHeading>   

                                            <ListGroupItemText className="top-text">
                                                <p>The State Committee shall be responsible for the management of the affairs
                                                    of the Association in accordance with the policy decision of the Stat Council. The
                                                    State Committee shall consist of the branch secretary, state office bearers and
                                                    members of the Association holding office in the All India Federation, if any.
                                                </p>
                                                <p>
                                                The State Committee shall meet once in a month. There shall be no quorum
                                                for the meeting. There shall be three days notice.
                                                </p>
                                            </ListGroupItemText>

                                            <ListGroupItemHeading className="mt-2">
                                                Functions :
                                            </ListGroupItemHeading>

                                            <ul>
                                                <li>It shall be responsible for the executive management of the Association.</li>
                                                <li>Between 2 meetings of the State Council, the State Committee shall exercise the power of the State Council except those specified under
                                                11 – B (2) above. A report on such action shall be made to the council
                                                on its next meeting.</li>
                                                <li>The State Committee shall be collectively responsible to the general
                                                body of the Association.</li>
                                                <li>The State Committee shall receive individual representation regarding
                                                grievances of individual members through the respective branch
                                                committees and take suitable action to redress the grievances based on
                                                merits of each case.</li>
                                            </ul>

                                        </ol>
                                    </ListGroupItem>

                                    {/* Law 12 */}
                                    <ListGroupItem className="law-container">
                                        <ListGroupItemHeading>
                                            Cessation of Membership in the Branch Committee, State Committee/ State Council :
                                        </ListGroupItemHeading>
                                        <ListGroupItemText className="top-text">
                                            <p>
                                            An office bearer or member of any committee of State Council shall cease to hold office or to be a member.
                                            </p>
                                        </ListGroupItemText> 
                                        <ul>
                                            <li>
                                            On transfer from one District to another in the case of branch Committee/State Council.
                                            </li>
                                            <li>
                                            On failure to attend three meetings of the Committee or council consecutively
                                            </li>
                                            <li>
                                            In the event of suspension, removal or resignation from membership
                                            </li>
                                        </ul>
                                    </ListGroupItem>

                                    {/* Law 13 */}
                                    <ListGroupItem>
                                        <ListGroupItemHeading>
                                            Filling up of Vacancies :
                                        </ListGroupItemHeading>
                                        <ListGroupItemText>
                                            <ul>
                                                <li>
                                                The vacancies of branch office bearers except president in the District
                                                Committee shall be filled up by nomination by Branch President in consultation
                                                with Branch Committee subject to post approval by the branch general body.  
                                                </li>
                                                <li>
                                                Vice President shall carry out the duties of President till the president is elected by the general body.
                                                </li>
                                            </ul>
                                        </ListGroupItemText>
                                    </ListGroupItem>

                                    {/* Law 14 */}
                                    <ListGroupItem>
                                        <ListGroupItemHeading>
                                            Tenure :
                                        </ListGroupItemHeading>
                                        <ListGroupItemText>
                                            <ul>
                                                <li>
                                                Office bearers and members of State Committee/Council shall hold
                                                office until the next annual elections for a period of 18 months subject
                                                to a maximum period of 24 months.
                                                </li>
                                                <li>
                                                The State Council shall stand dissolved immediately after the election
                                                of office bearers in the annual general body. However the office
                                                bearers of State Committee shall hold office until their successors
                                                assume change.  
                                                </li>
                                            </ul>
                                        </ListGroupItemText>
                                        
                                    </ListGroupItem>

                                    {/* Law 15 */}
                                    <ListGroupItem className="law-container">
                                        <ListGroupItemHeading>
                                            Duties and Functions :
                                        </ListGroupItemHeading>
                                        <ol className="upper-alpha">
                                            <ListGroupItemHeading>
                                                <li className="head">
                                                    President :
                                                </li>
                                            </ListGroupItemHeading>  
                                            <ul>
                                                <li>
                                                The President is the executive head of the Association and shall have
                                                general control over its affairs. He will be elected by the Annual
                                                General Body.
                                                </li>
                                                <li>
                                                He shall preside over the State Committee, State Council and Annual General Body meetings.
                                                </li>
                                                <li>
                                                In case of emergency requiring immediate action he may take
                                                appropriate decisions in consultation with the General Secretary and
                                                act accordingly without getting convened the State Council/
                                                Committee and report his action to the State Council/ Committee
                                                within 15 days or at its next sitting whichever is earlier.
                                                </li>
                                                <li>The President shall be competent to sanction a sum up to Rs.50000/-
                                                from the funds of the Association for meeting any emergency
                                                expenditure of the Association. Any amount above Rs.50000/- can
                                                only be sanctioned by the State Committee.</li>
                                                <li>The President may whenever necessary visit various centres/branches
                                                of the Association at the expenses of the Association.</li>
                                                <li>He shall do such other things as may be authorized by the State Council and State Committee from time to time.</li>
                                                <li>He shall have powers to nominate two Vice Presidents, three Joint
                                                Secretaries, Associate Treasurer, Editor Kannimannu and Associate
                                                Editor in consultation with other State Office Bearers and on the
                                                advice of the State Council.</li>
                                                <li>He shall have powers to nominate National Councilors from among
                                                the State Council member of represent the Association in the All India
                                                Federation.</li>
                                                <li>He shall have the powers to suspend any nominated office bearers of
                                                the state due to misconduct, anti association activities, suspension,
                                                removal or resignation from the membership of the Association, or
                                                failure to attend the state council consecutively for 3 times without any
                                                valid reasons. This should be done after calling for explanation within
                                                a week and that the explanation is not found acceptable. Such
                                                suspension shall be got ratified in the next immediate state council.</li>
                                            </ul>

                                            <ListGroupItemHeading>
                                                <li className="head">
                                                    Vice President :
                                                </li>
                                            </ListGroupItemHeading> 
                                            <ul>
                                                <li>There will be two Vice Presidents nominated by the President on the advice
                                                    of the State Council. One vice president will be a women member. The first Vice
                                                    President shall be in charge of charity, welfare and social activities of the
                                                    Association. The Vice President shall exercise all powers of the President in his absence and he will preside over the meetings of the State Committee and State
                                                    Council in the absence of the President. The Vice President shall also perform
                                                    such other functions as may be authorized by the State Committee or State
                                                    Council from time to time.
                                                </li>
                                                <li>The Vice President shall prepare and submit reports and accounts of FWT in
                                                the State Council and AGB. The Women vice president will be in charge of
                                                women cell.</li>
                                            </ul> 

                                            <ListGroupItemHeading>
                                                <li className="head">
                                                    General Secretary :
                                                </li>
                                            </ListGroupItemHeading> 
                                            <ul>
                                                <li>The General Secretary shall be responsible for the executive
                                                    management of the Association and will attend to its day to day work.
                                                    He will be elected by the Annual General Body of the Association.
                                                </li>
                                                <li>He shall prepare at the close of the year an annual report on the
                                                working of the Association and get it approved by the State Council
                                                before presentation in the Annual General Body.</li>
                                                <li>
                                                In the absence of the President he can take appropriate decisions and
                                                Act accordingly in an emergency in consultation with the State
                                                Committee members at hand and report his action to the State
                                                Committee within 15 days or its next sitting whichever is earlier.
                                                </li>
                                                <li>He may, whenever necessary, visit the branches etc., at the expense of the Association.</li>
                                                <li>He may keep an imprest money of Rs.10000/- at a time to meet routine expenses and will maintain a subsidiary cash book.</li>
                                                <li>He shall be responsible for the proper upkeep of the office of the Association and maintenance of Association records.</li>
                                                <li>He shall do such other things as may be authorized by the State Committee/Council</li>
                                            </ul>

                                            <ListGroupItemHeading>
                                                <li className="head">
                                                    Joint Secretary :
                                                </li>
                                            </ListGroupItemHeading> 
                                            <ul>
                                                <li>There will be three Joint Secretaries nominated by the state president in consultation with other state office bearers and on the advice of the state council.
                                                </li>
                                                <li>The Head Quarters Joint Secretary will exercise the powers of the General
                                                Secretary in his absence or if authorized by the state committee/council. He will
                                                present the bylaw Amendment to the AGB. Others two Joint Secretaries will in
                                                charge of two zones.</li>
                                            </ul>

                                            <ListGroupItemHeading>
                                                <li className="head">
                                                    State Treasurer :
                                                </li>
                                            </ListGroupItemHeading> 
                                            <ul>
                                                <li>
                                                The State Treasurer of the Association shall be responsible for all cash
                                                transactions. He will be elected by the annual general body
                                                </li>
                                                <li>There shall be an Associate Treasurer nominated by the State President
                                                in consultation with the State Office Bearers and on the advice of the
                                                State Council. He shall perform all the functions of the State Treasurer
                                                in his absence or if authorized by the State Council.</li>
                                                <li>He shall received all money due to the Association and disburse all
                                                money payable from the Association funds.</li>
                                                <li>He shall make reports to the State Council Committee on the financial
                                                position of the Association form time to time</li>
                                                <li>He shall prepare and present the audited annual accounts and the
                                                budget estimates in the State Council.</li>
                                                <li>He shall maintain such other accounts as may be required by the State Committee/Council.</li>
                                                <li>He shall be competent to sanction an amount up to Rs.10000/- from
                                                the funds of the association for meeting any emergency expenditure
                                                for the Association.</li>
                                                <li>He shall receive all money due to the Association and disburse all
                                                money payable from the Association funds by operating a joint
                                                account with State President in a bank authorized by the State Council
                                                and all transactions shall be made through the bank.</li>
                                            </ul>

                                            <ListGroupItemHeading>
                                                <li className="head">
                                                    Editor, Kannimannu :
                                                </li>
                                            </ListGroupItemHeading> 
                                            <ul>
                                                <li>
                                                “Kannimannu” is an official publication of association and it shall be
                                                published /e published by the General Secretary of the Association of
                                                Agricultural Officers’ Kerala and shall be circulated/ uploaded in the
                                                website. The Editor shall be nominated by the State President on the
                                                advice of the State Council and shall be a member in the State council
                                                and State Committee. He shall be responsible for the timely
                                                publication of Kannimannu. He can also make direct correspondence
                                                with the Sub Editors and District Committees on matters pertaining to
                                                Kannimannu.                                            </li>
                                                <li>There shall be an Associate Editor to assist the editor. He shall be
                                                nominated by the State President in consultation with State Office
                                                bearers and on the advice of the State Council.</li>
                                            </ul>

                                            <ListGroupItemHeading>
                                                <li className="head">
                                                    Web Administrator :
                                                </li>
                                            </ListGroupItemHeading> 
                                            <ul>
                                                <li>
                                                He will be nominated by the State President in consultation with other state
                                                office bearers on the advice of State Council. He will be in charge of the activities
                                                like maintenance and updation of the web portal of the association.</li>
                                            </ul>
                                        </ol>                            
                                    </ListGroupItem>
                                    
                                    {/* Law 16 */}
                                    <ListGroupItem className="law-container">
                                        <ListGroupItemHeading>
                                            Branch Details :
                                        </ListGroupItemHeading>
                                        <ol className="upper-alpha">
                                            <ListGroupItemHeading>
                                                <li className="head">
                                                  Branch President :
                                                </li>
                                            </ListGroupItemHeading> 
                                            <ul>
                                                <li>The President shall have general control over the affairs of the Association in the jurisdiction of the branch.</li>
                                                <li>He shall preside over the District branch general body and District Committee meetings.</li>
                                                <li>He shall be competent to sanction a sum up to Rs.5000/- from the
                                                funds of the Association for meeting emergency expenditure, if any,
                                                for the Association.</li>
                                            </ul>

                                            <ListGroupItemHeading>
                                                <li className="head">
                                                  Branch Vice President :
                                                </li>
                                            </ListGroupItemHeading> 
                                            <ul>
                                                <li>
                                                    Branch Vice President shall exercise all powers of the branch president in his absence.
                                                </li>
                                                <li>He shall be responsible for the management of activities of Family Welfare Trust.</li>
                                                <li>He shall represent the branch in the State Council</li>
                                            </ul>

                                            <ListGroupItemHeading>
                                                <li className="head">
                                                  Branch Secretary :
                                                </li>
                                            </ListGroupItemHeading> 
                                            <ul>
                                                <li>The branch secretary shall be responsible for the executive management of the branch.</li>
                                                <li>He shall do such other matters as may be authorized by the branch general body and District Committee</li>
                                                <li>He may keep a permanent advance of Rs.2000/- with him for meeting the day to day expense of the Association.</li>
                                                <li>He shall represent the branch in the State Committee and State Council</li>
                                            </ul>

                                            <ListGroupItemHeading>
                                                <li className="head">
                                                  Branch Joint Secretary :
                                                </li>
                                            </ListGroupItemHeading> 
                                            <ul>
                                                <li>Branch Joint Secretary shall exercise all powers of the branch Secretary in his absence</li>
                                                <li>He shall represent the branch in the State Council</li>
                                            </ul>

                                            <ListGroupItemHeading>
                                                <li className="head">
                                                    The Branch Treasurer :
                                                </li>
                                            </ListGroupItemHeading> 
                                            <ul>
                                                <li>The branch Treasurer shall be responsible for all cash transactions of the branch</li>
                                                <li>He shall receive all money due to the branch and disburse all money
                                                    payable from the branch funds on proper receipts including that of
                                                    “Kannimannu” accounts. He shall issue a single receipt for the money
                                                    collected from Sub-Editor each time.</li>
                                                <li>He shall maintain the receipts and expenditure accounts of the branch</li>
                                                <li>He shall produce the accounts before the branch general body or district committee whenever required.</li>
                                                <li>He shall be responsible for the remittance of the cash collected in the bank account of the Association</li>
                                                <li>He shall represent the branch in the State Council.</li>
                                            </ul>

                                            <ListGroupItemHeading>
                                                <li className="head">
                                                    Sub Editor :
                                                </li>
                                            </ListGroupItemHeading> 
                                            <ul>
                                                <li>
                                                The Sub Editor shall be elected by the branch general body and shall be
                                                responsible for all activities connected with the circulation of
                                                Kannimannu, the organ of the Association     </li>
                                                <li>He shall make direct correspondence with the Editor, Kannimannu on its publication and circulation.</li>
                                                <li>He shall represent the branch in the State Council.</li>

                                                <ListGroupItemHeading>
                                                    Note :-
                                                </ListGroupItemHeading> 
                                                <ListGroupItemText>
                                                    <ul>
                                                        <li>
                                                        All the above Office bearers of the branch shall travel in the jurisdiction
                                                        of the branch for Association purpose, the TA being draw from the
                                                        branch.
                                                        </li>
                                                        <li>The branch office bearers shall not make pres statement in the name of
                                                        the Association without sanction from the General Secretary.</li>
                                                    </ul>
                                                </ListGroupItemText>
                                            </ul>

                                            <ListGroupItemHeading>
                                                <li className="head">
                                                    Assistant Secretaries :
                                                </li>
                                            </ListGroupItemHeading> 
                                            <ul>
                                                <li>
                                                There shall be one elected Assistant Secretary for every block area and
                                                part there of subject to a maximum of two and one at the Director of
                                                Agriculture who will be a member of branch committee and state
                                                Council. He shall be responsible for the organizational activities in the
                                                respective areas. The branch committee shall authorize the Assistant
                                                Secretary to collect amounts and issue receipts as per the guidance of
                                                the branch Treasurer.</li>
                                                <li>The Assistant Secretaries of the specified jurisdiction and Director of
                                                Agriculture may convene meeting of the members of the respective
                                                areas for organizational activities subject to the directions of the branch
                                                secretary/committee.</li>
                                            </ul>

                                            <ListGroupItemHeading>
                                                <li className="head">
                                                    Web Manager :
                                                </li>
                                            </ListGroupItemHeading> 
                                            <ul>
                                                <li>
                                                He will be in charge of the activities of the web in the branch level</li>
                                            </ul>
                                        </ol>                                       
                                    </ListGroupItem>

                                    {/* Law 17 */}
                                    <ListGroupItem className="law-container">
                                        <ListGroupItemHeading>
                                            Funds and its Utilization :
                                        </ListGroupItemHeading>
                                        <ListGroupItemText>
                                            <ul>
                                                <li>
                                                Each branch shall remit Rs.1000/- of the collections towards annual
                                                subscription to the state treasurer including dairy cost. Balance of
                                                Rs.500/- may be retained at the district to meet the branch level
                                                including TA to member of the branch attending state committee, state
                                                council and other meeting shall be paid by the branch committee..                                        
                                                </li>
                                                <li>
                                                Special funds for specific purpose, if found necessary, shall be raised by the Association from among the members.                                      
                                                </li>
                                                <li>
                                                The branches can also raise special funds from among the members with
                                                the prior approval of the State Committee.
                                                </li>
                                                <li>
                                                Collections made in the name of the Association shall be deposited with
                                                a bank on the decision of the District Committee/State Committee as the
                                                case may be.                                        
                                                </li>
                                                <li>
                                                Amounts received on behalf of the Association by the branch Treasurer
                                                shall be remitted to the bank account in 7 days time, Bank accounts of
                                                the branch will be operated by the Treasurer of the branch                                        
                                                </li>
                                                <li>
                                                Amount required for expenses shall be drawn from the banks and all
                                                cash transaction shall be in the form of cheques.                                      
                                                </li>
                                                <li>
                                                The branch Secretary shall incur expenditure beyond Rs.2000/- only with the approval of the branch President                                   
                                                </li>
                                                <li>
                                                a) The accounts of the branches shall be audited once in a year by the State Treasurer.                               
                                                </li>
                                                <li>
                                                b) The accounts of the branches shall be audited by 2 internal auditors
                                                nominated by the branch committee before presenting the accounts
                                                in the annual general body of the branches.
                                                </li>
                                                <li>
                                                The General Secretary shall incur expenditure beyond Rs.2500/- only with the approval of the State President.                            
                                                </li>
                                                <li>
                                                a) The accounts of the Association shall be audited by a Chartered Accountant once in a year or 18 months at the maximum.
                                                </li>
                                                <li>
                                                b) A three member internal audit committee nominated by the state
                                                council will conduct internal audit of accounts of the association
                                                once in six months and before the audit of the C.A one of the internal
                                                auditor shall preferably be a former State Treasurer/Associate
                                                Treasurer.
                                                </li>
                                                <li>
                                                The Association shall be responsible for the assets and liabilities of the
                                                Kannimannu Publications and it shall be the duty of the editor
                                                Kannimannu to run the publication on a commercial scale without
                                                incurring loss to the General Revenue of the Association. The profits
                                                that accrue from the ‘Kannimannu’ and other publications will form part
                                                of the General Revenue of the Association.
                                                </li>
                                                <li>
                                                Xiii Special funds not specified above and funds received on account of
                                                the Group Insurance Scheme shall be received and operated by the
                                                General Secretary. Funds required for remittance of premium and
                                                similar remittances or payments shall be credited to the account of the
                                                General Secretary by the State Treasurer as and when authorized by the
                                                State Committee.
                                                </li>                                                
                                            </ul>
                                        </ListGroupItemText>
                                    </ListGroupItem>

                                    {/* Law 18 */}
                                    <ListGroupItem>
                                        <ListGroupItemHeading>
                                            Assets & Liabilities :
                                        </ListGroupItemHeading>
                                        <ListGroupItemText>
                                            <ul>
                                                <li>
                                                All immovable properties should be acquired in the joining title of the
                                                Trustees of the Association. This amendment will have retrospective
                                                effect from 01-07-1975.
                                                </li>
                                                <li>
                                                Sale of immovable properties should have the prior approval of the State
                                                General Body of the Association where in the proposal should be carried
                                                over by 2/3rd majority of the valid members of Association. If by
                                                chance 2/3rd majority of the valid members are not present in the State
                                                General Body, the proposal should be approved by 2/3rd majority of the
                                                valid member of eight branches of the Association within a period of 60
                                                days from the date of the General Body which was convened for this
                                                purpose.                                     
                                                </li>
                                                <li>
                                                The permanent funds and securities of the Association shall be deposited
                                                in any of the National Bank, Co-operative Banks, Government
                                                Treasuries or Public Sector financial enterprises in the Joint account of
                                                the State President and State Treasurer. All withdrawls of money from
                                                joint deposits should be made only after obtaining approval of the State
                                                Committee. Of course in any emergency, funds may be withdrawn for
                                                implementing approved programmes of the Association based on the
                                                written request from the General Secretary. All such requests should be
                                                got ratified by the State Committee within 30 days of such withdrawal.
                                                </li>
                                            </ul>
                                        </ListGroupItemText>
                                    </ListGroupItem>

                                    {/* Law 19 */}
                                    <ListGroupItem>
                                        <ListGroupItemHeading>
                                            Rule 19 T.A :
                                        </ListGroupItemHeading>
                                        <ListGroupItemText>
                                            <ul>
                                                <li>
                                                The T.A of the State office bearer viz. State President, Vice President,
                                                General Secretary, Joint Secretary, State Treasurer, Associate Treasurer,
                                                Editor, Associate Editor, outgoing State President, General Secretary,
                                                State Treasurer and AIFAT representatives/National Councilors of
                                                AIFAT shall alone be borne by the State Committee and T.A of other
                                                members of the State Committee elected by the Branch General body
                                                shall be borne by the respective branch committee.
                                                </li>
                                                <li>
                                                The T.A of all branch office bearers shall be met by the District Committee.                                    
                                                </li>
                                                <li>
                                                    T.A & D.A payable for journeys shall be as follows:-
                                                    <ul>
                                                        <li>
                                                        Actual to and fro express bus/train AC fare and second AC fare and Rs.300/- per day as DA
                                                        </li>
                                                        <li>
                                                        Rs.300/- per day as DA for halts
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                T.A and D.A of National Councilors for attending AIFAT meeting shall
                                                be paid by the Association at the following rates. T.A 1st class to and fro
                                                Railway fare/Plane fare D.A Rs.350/- per day for stay outside the State
                                                and Rs.300/- per day for stay within the State.                                        
                                                </li>
                                            </ul>
                                        </ListGroupItemText>
                                    </ListGroupItem>

                                    {/* Law 20 */}
                                    <ListGroupItem>
                                        <ListGroupItemHeading>
                                            Welfare Fund :
                                        </ListGroupItemHeading>
                                        <ListGroupItemText>
                                            <ul>
                                                <li>
                                                A Welfare fund for the benefit of members shall be raised from among the
                                                members as per a viable scheme and operated by Vice President of the
                                                Association. Funds raised towards the welfare scheme shall be deposited in a joint
                                                account of the State Treasurer and Vice President. All accounts relating to this
                                                funds shall be maintained by the Vice President who will render detailed accounts
                                                to the State Treasurer for incorporation in the annual accounts of the Association.
                                                </li>
                                            </ul>
                                        </ListGroupItemText>
                                    </ListGroupItem>

                                    {/* Law 21 */}
                                   <ListGroupItem>
                                        <ListGroupItemHeading>
                                            Trustees :
                                        </ListGroupItemHeading>
                                        <ListGroupItemText>
                                            <ul>
                                                <li>
                                                The President, General Secretary and State Treasurer, shall be the Trustee of the Association.
                                                </li>
                                            </ul>
                                        </ListGroupItemText>
                                    </ListGroupItem>

                                    {/* Law 22 */}
                                    <ListGroupItem>
                                        <ListGroupItemHeading>
                                            Legal Proceedings :
                                        </ListGroupItemHeading>
                                        <ListGroupItemText>
                                            <ul>
                                                <li>
                                                The association shall sue and be sued in the name of the General Secretary.
                                                The State President on advice of the State Committee may appoint a legal adviser
                                                to render advice on legal matters.                                       
                                                </li>
                                            </ul>
                                        </ListGroupItemText>
                                    </ListGroupItem>

                                    {/* Law 23 */}
                                    <ListGroupItem>
                                        <ListGroupItemHeading>
                                            Amendments of Bye-Laws :
                                        </ListGroupItemHeading>
                                        <ListGroupItemText>
                                            <ul>
                                                <li>
                                                Amendments, deletions, or additions to the bye-laws of the Association shall
                                                be made only by a vote of two third majority in the State General Body and shall
                                                be published with in the third meeting of the State Council after the Annual
                                                General Body meeting.                                        
                                                </li>
                                            </ul>
                                        </ListGroupItemText>
                                    </ListGroupItem>

                                    {/* Law 24 */}
                                    <ListGroupItem>
                                        <ListGroupItemHeading>
                                            The powers for interpreting these bye-laws vest with the State Committee:
                                        </ListGroupItemHeading>
                                    </ListGroupItem>

                                    {/* Law 25 */}
                                    <ListGroupItem className="law-container">
                                        <ListGroupItemHeading>
                                            Meetings :
                                        </ListGroupItemHeading>
                                        <ol className="upper-alpha">
                                            <ListGroupItemHeading>
                                                <li className="head">
                                                    State General Body :
                                                </li>
                                            </ListGroupItemHeading>
                                            <ul>
                                                <li>
                                                    The date and venue of the General Body Meeting shall be fixed by the State
                                                    Council and shall be communicated to the members 15 days in advance along with
                                                    the agenda. There shall be no quorum for the general body meeting.
                                                </li>
                                            </ul>

                                            <ListGroupItemHeading>
                                                <li className="head">
                                                    Special General Body Meeting :
                                                </li>
                                            </ListGroupItemHeading>
                                            <ul>
                                                <li>
                                                If 6 or more branches jointly request the President in writing to convene
                                                special general body meetings, the President in consultation with the State
                                                Committee shall arrange to hold such special general body meeting at a place
                                                suggested in the joint request. Such special general body shall have all the powers
                                                of the annual general body.
                                                </li>
                                            </ul>
                                        </ol>
                                    </ListGroupItem>

                                    {/* Law 26 */}
                                    <ListGroupItem>
                                        <ListGroupItemHeading>
                                            National Council of the All India Federation :
                                        </ListGroupItemHeading>
                                        <ListGroupItemText>
                                            <ul>
                                                <li>
                                                All Central Office bearers shall be the national Councilors in the Governing
                                                body of All India Federation of Agricultural Technologists. In addition the State
                                                President shall nominated one councilor for every 200 members of the
                                                Association on the advice of the state council.                                     
                                                </li>
                                            </ul>
                                        </ListGroupItemText>
                                    </ListGroupItem>

                                    {/* Law 27 */}
                                    <ListGroupItem className="law-container">
                                        <ListGroupItemHeading>
                                            Election of State Office, Bearers  :
                                        </ListGroupItemHeading>
                                        <ol className="upper-alpha">
                                            <ListGroupItemHeading>
                                                <li className="head">
                                                    Rules and procedure :
                                                </li>
                                            </ListGroupItemHeading>
                                            <ul>
                                                <li>
                                                The President, General Secretary and State Treasurer shall be elected by the General Body
                                                </li>
                                                <li>
                                                Eligibility : Any member of the Association who had hold any
                                                position in either branch committee/state committee is entitled to
                                                seek election of any of the elected posts. Those members who are
                                                due to retire within 18 months from the date of election are not
                                                eligible to contest for the elected post. A member shall not be eligible
                                                to file nomination for more than one elected post. 
                                                </li>
                                                <li>
                                                Electoral roll : Electoral roll shall be prepared by the Returning
                                                Officer with the help of branch secretaries well before the election
                                                notification. If any branch/branches fails/fail to furnish the list of
                                                members to the Returning Officer within the stipulated time, the
                                                Returning Officer shall collect the details of members of such
                                                branches with the help of State Committee. The electoral roll of Life
                                                Members shall be furnished by the General Secretary. Electoral roll
                                                shall be published along with the election notification and objections
                                                in writing, if any, settled 15 days in advance of the date of election
                                                so that supplementary list of voters, if any, could be published ten
                                                days in advance of date of election.    
                                                </li>
                                                <li>
                                                A member enrolled in the branch shall have voting power only
                                                if he/she has availed membership before march 31st of the calender
                                                year or at least by June 30th with a fine of Rs.10/-. It is the
                                                responsibility of the Branch committee members to ensure that the
                                                central share is remitted to the state treasurer on or before August
                                                31st   
                                                </li>
                                            </ul>

                                            <ol className="upper-alpha">
                                                <ListGroupItemHeading>
                                                    <li className="head">
                                                        1) Returning Officer :
                                                    </li>
                                                </ListGroupItemHeading>
                                                <ul>
                                                    <li>
                                                    The President on the advice of the State Committee shall appoint a
                                                    suitable person (from among the members) as Returning Officer 90
                                                    days in advance of the General body meeting. He shall not be a
                                                    person seeking election to any of the elected post of the State General
                                                    Body of the Association.
                                                    </li>
                                                    <li>
                                                    Returning Officer shall be fully responsible for the preparation of
                                                    electoral rolls, notification of election, receipt on nominations,
                                                    scrutiny, publication of list of candidates and conduct of elections by
                                                    secret ballot on the stipulated date of annual general body meeting.
                                                    </li>
                                                    <li>
                                                    The President shall nominate members on the advice of the State Committee or the State Council to assist the returning officer.                                          
                                                    </li>
                                                </ul>

                                                <ListGroupItemHeading>
                                                    <li className="head">
                                                        2) Notifications :
                                                    </li>
                                                </ListGroupItemHeading>
                                                <ul>
                                                    <li>a) Notification of election and circular inviting nominations to the various posts
                                                shall be issued by the Returning Officer at least 45 days in advance of the
                                                date of election.</li>
                                                <li>
                                                b) Notification and circular shall be issued to all Branch Secretaries and State Councilors.
                                                </li>
                                                <li>
                                                c) Notification shall be in Forms No. A
                                                </li>
                                                </ul>

                                                <ListGroupItemHeading>
                                                    <li className="head">
                                                        3) Nominations :
                                                    </li>
                                                </ListGroupItemHeading>
                                                <ul>
                                                    <li>
                                                    a) Nominations received shall be scrutinized, defective nominations rejected
                                                and list of candidates published at least 15 days prior to the date of election.
                                                Last date of withdrawal of candidates from contest shall be fixed by the
                                                Returning Officer while issuing notification of election and circulars inviting
                                                nomination to the various posts, and final list of contesting candidate
                                                published either through press or notices and also intimated to the Branch
                                                Secretaries by Registered post at least 10 days in advance of the election.
                                                Nomination received shall be scrutinized and defective nomination rejected
                                                by the Returning Officer within the period stipulated by him for the purpose.  
                                                    </li>
                                                </ul>

                                                <ListGroupItemHeading>
                                                    <li className="head">
                                                        4) Election :
                                                    </li>
                                                </ListGroupItemHeading>
                                                <ul>
                                                    <li>
                                                    In the event of contest for one or all posts, election by secret ballot
                                                    shall be conducted in the General Body meeting at an appropriate
                                                    time fixed by the Returning Officer.
                                                    </li>
                                                    <li>
                                                    Separate ballot papers for each post shall be printed and supplied to the voters duly signed by the Returning Officer.
                                                    </li>
                                                    <li>
                                                    Election can be done in an online platform also.                                            
                                                    </li>
                                                </ul>

                                                <ListGroupItemHeading>
                                                    <li className="head">
                                                        5) Declaration of Results :
                                                    </li>
                                                </ListGroupItemHeading>
                                                <ul>
                                                    <li>
                                                    Valid votes shall be counted and candidates securing majority votes declared elected.
                                                    </li>
                                                    <li>
                                                    The Returning officer shall have posers to decide the validity of votes without assigning reasons
                                                    </li>
                                                    <li>
                                                    In the event of tie Returning Officer shall declare the result on the basis of coin tossing.
                                                    </li>
                                                    <li>
                                                    List of elected office bearers shall be published on form No. D within 3 days after election by the Returning Officer.
                                                    </li>
                                                </ul>
                                            </ol>
                                        </ol>
                                    </ListGroupItem>                                    
                                </ListGroup>
                            </Col>
                        </div>
                    </Container>
                </div>
            </section>

</>;
};

export default BiloLIst;