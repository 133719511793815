import { ErrorMessage, Formik } from "formik";
import { Col, Form, Input, Label, Row, Button } from "reactstrap";
import { AddBlogInitialValues, BlogValidationSchema } from "./FormValidation";
import { StaffSubmitHandler } from "./BlogAddMethods";
import TextError from "../../../../Components/Errors/TextErrors";
import CustomButton from "../../../../Components/Button/CustomButton";
interface AddProps {
  setIsOpen: Function;
  modalEditData?: any;
  refetch: Function;
  id: any;
}
const AddBlogComment = ({
  setIsOpen,
  refetch,
  id,
}: AddProps) => {
  return (
    <Formik
      validationSchema={BlogValidationSchema}
      initialValues={AddBlogInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        StaffSubmitHandler(values, actions, setIsOpen, refetch, id);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col lg={12}>
                  <Label>Blog Comment</Label>
                  <Input
                    type="textarea"
                    onChange={handleChange}
                    value={values?.blog_comment}
                    name="blog_comment"
                    placeholder="Type here"
                  />
                  <ErrorMessage name="blog_comment" component={TextError} />
                </Col>

                <Col lg={12}>
                  <div className="blog-button-wrap">
                    <Button outline color="danger" className="sm">Cancel</Button>
                    <CustomButton
                      isLoading={isSubmitting}
                      label="Submit"
                      color="primary"
                      className="sm submit-btn"
                    />    
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddBlogComment;
